<script>
export default {
  name: 'CardTotal',
  data() {
    return {};
  },
};
</script>

<template>
  <div class="margin-auto">
    <VCard rounded="xl" min-height="170">
      <VRow class="pa-8">

        <VCol cols="12" md="4">
          <VCard color="purple" variant="outlined">
            <v-card color="purple" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">
                    <v-avatar color="purple" size="50" rounded="sm" class="mx-4">
                      <VIcon>mdi-login</VIcon>
                    </v-avatar>
                    <span>100K+</span>
                  </div>
                  <div class="text-captions mx-10s text-grey-darken-2 text-center">
                    <h3 class="font-weight-regular">
                      Total Investor
                    </h3>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </VCard>
        </VCol>

        <VCol cols="12" md="4">
          <VCard color="indigo" variant="outlined">
            <v-card color="indigo" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">
                    <v-avatar color="indigo" size="50" rounded="sm" class="mx-4">
                      <VIcon>mdi-currency-usd</VIcon>
                    </v-avatar>
                    100K
                  </div>
                  <div class="text-captions mx-10 text-grey-darken-2 text-center">
                    <h3 class="font-weight-regular">
                      Total Withdrawal
                    </h3>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </VCard>
        </VCol>

        <VCol cols="12" md="4">
          <VCard color="blue" variant="outlined">
            <v-card color="blue" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1"></div>
                  <div class="text-h6 mb-1">
                    <v-avatar color="blue" size="50" rounded="sm" class="mx-4">
                      <VIcon>mdi-cash-refund</VIcon>
                    </v-avatar>
                    100K+
                  </div>
                  <div class="text-captions mx-10 text-grey-darken-2 text-center">
                    <h3 class="font-weight-regular">
                      Total Transaction
                    </h3>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </VCard>
        </VCol>
      </VRow>
    </VCard>
  </div>
</template>

<style scoped>

</style>