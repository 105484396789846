<template>
  <div>
    <h3 class="text-blue">Fund Transfers</h3>
    <br />
    <v-row>
      <v-col md="9" cols="12">
        <div style="display: flex">
          <span class="ma-2"> Show</span>
          <div style="width: 90px">
            <v-select
              rounded="xl"
              :items="[10, 20, 50, 100, 200, 'all']"
              v-model="value"
              density="compact"
              variant="outlined"
            ></v-select>
          </div>
          <span class="ma-2"> Entry</span>
        </div>
      </v-col>
      <v-col md="3" cols="12">
        <div style="display: flex">
          <span class="ma-2"> Search</span>
          <v-text-field
            name="name"
            density="compact"
            variant="outlined"
            rounded="xl"
            placeholder="Search"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <table width="100%" cellspacing="0" cellpadding="0">
      <tr class="bg-blue">
        <td class="td">Transaction</td>
        <td class="td text-center">Status</td>
        <td class="td text-center">Amount</td>
      </tr>

      <tr
        style="background: #fff"
        v-for="(level, index) in transactions"
        v-bind:key="index"
      >
        <td class="td">
          <div class="d-flex justify-lg-space-around">
            <v-avatar color="info">
              <p class="text-uppercase">{{ level.name[0] }}</p>
            </v-avatar>
            <div class="mx-2">
              {{ level.name }}
              <p style="font-size: 10px">
                {{ level.date }}
              </p>
            </div>
          </div>
        </td>
        <td class="td text-center">
          <p v-if="level.status === 'unsuccessful'" class="text-red">
            {{ level.status }}
          </p>
          <p v-if="level.status === 'Successful'" class="text-green">
            {{ level.status }}
          </p>
        </td>
        <td class="td text-center">
          <p v-if="level.status === 'unsuccessful'" class="text-red">
            - {{ level.amount }} {{ level.currency }}
          </p>
          <p v-if="level.status === 'Successful'" class="text-green">
            {{ level.amount }} {{ level.currency }}
          </p>
        </td>
      </tr>
    </table>

    <br />

    <div class="text-center">
      <v-pagination
        size="small"
        rounded="circle"
        v-model="page"
        :length="15"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "FundTransfers",
  data() {
    return {
      page: 0,
      value: 10,
      transactions: [
        {
          id: 1,
          name: "Customer A",
          status: "Successful",
          amount: 1000,
          currency: "USD",
          date: "2024-12-12 12:10:10",
        },
        {
          id: 1,
          name: "Customer A",
          status: "unsuccessful",
          amount: 2000,
          currency: "USD",
          date: "2024-12-12 12:10:10",
        },
        {
          id: 1,
          name: "Customer A",
          status: "Successful",
          amount: 1000,
          currency: "USD",
          date: "2024-12-12 12:10:10",
        },
        {
          id: 1,
          name: "Customer A",
          status: "Successful",
          amount: 1000,
          currency: "USD",
          date: "2024-12-12 12:10:10",
        },
        {
          id: 1,
          name: "Customer A",
          status: "unsuccessful",
          amount: 2000,
          currency: "USD",
          date: "2024-12-12 12:10:10",
        },
        {
          id: 1,
          name: "Customer A",
          status: "Successful",
          amount: 1000,
          currency: "USD",
          date: "2024-12-12 12:10:10",
        },
      ],
    };
  },
  methods: {
    formatPrice(price = 0) {
      return price.toLocaleString();
    },
  },
};
</script>
<style scoped>
.td {
  padding: 10px;
}
</style>
