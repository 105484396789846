<script>
import { Path_Feedback_Image, URL_Feedback_Admin } from '@/utils/apiUrl';

export default {
  name: 'MemberFeedback',
  data() {
    return {
      path: Path_Feedback_Image,
      members: [],
    };
  },
  methods: {
    async fetchData() {
      this.axios
        .get(
          URL_Feedback_Admin,
        )
        .then((response) => {
          if (response.data) {
            this.members.push(response.data);
          }
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <div>
    <!-- {{path }} -->
    <!-- <pre>
    {{ members[0] }}
   </pre> -->
    <h2 class="text-primary text-center text-uppercase">
      Member Feedback
    </h2>
    <br />
    <h3 class="text-center font-weight-regular">
      Our member are happy and Satisfied to our services.
    </h3>
    <br />

    <VRow v-if="members.length > 0">
      <template v-for="row in members[0]" v-bind:key="row.id">
        <VCol cols="12" md="3">
          <v-card class="mx-auto" max-width="450" rounded="xl">
            <v-card-text class="pa-5">
              <h4 class="font-weight-regular" v-html="row.desc"></h4>
            </v-card-text>
            <v-card-actions>
              <v-list-item class="w-100">
                <VRow>
                  <VCol cols="4">
                    <v-avatar class="elevation-1" color="grey-lighten-3" rounded="100" size="90">
                      <v-avatar rounded="100" size="80">
                        <v-img
                          onerror="this.src='https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg'"
                          :src="path + row.photoUser" cover></v-img>
                      </v-avatar>
                    </v-avatar>
                  </VCol>
                  <VCol cols="8">
                    <v-list-item-title class="text-uppercase text-primary font-weight-bold">
                      {{ row.title }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-orange">@{{ row.nameUser }}</v-list-item-subtitle>
                    <br />
                    <v-list-item-media class="text-uppercase text-grey font-weight-bold">
                      {{ row.job }}
                    </v-list-item-media>
                  </VCol>
                </VRow>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </VCol>
      </template>
    </VRow>

    <br />
    <br />
    <br />
  </div>
</template>

<style scoped></style>