<template>
  <div class="overflow-y-auto stepper-container">
    <v-stepper v-model="e1">
      <template v-slot:default="{ prev, next }">
        <v-stepper-header>
          <template v-for="n in steps" :key="`${n}-step`">
            <v-stepper-item
              v-if="n === 1"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="primary"
              title="Personal Info"
            ></v-stepper-item>
            <v-stepper-item
              v-if="n === 2"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="primary"
              title="Identification Image"
            ></v-stepper-item>
            <v-stepper-item
              v-if="n === 3"
              :complete="e1 > n"
              :step="`Step {{ n }}`"
              :value="n"
              color="success"
              title="Final"
            ></v-stepper-item>

            <v-divider v-if="n !== steps" :key="n"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-window>
          <v-stepper-window-item
            v-for="n in steps"
            :key="`${n}-content`"
            :value="n"
          >
            <v-form ref="form" enctype="multipart/form-data">
              <div class="py-2" v-if="n === 1">
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                    <v-card variant="variant">
                      <v-img
                        :src="
                          previewImage === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : previewImage
                        "
                        aspect-ratio="1"
                        cover
                        @click="triggerFileInput"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                          >
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>

                    <v-file-input
                      v-model.trim="profileImg"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Avatar"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      @change="uploadImage"
                      hide-input
                      class="mt-2"
                      ref="fileInput"
                      id="filePreviousImage"
                      style="display: none"
                    ></v-file-input>
                    <div class="text-center text-body-2">
                      <v-btn class="ma-2 sm" color="secondary" @click="updateProfile">
                        <v-icon icon="mdi-content-save" start></v-icon>
                        Save Image
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                    <v-row>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <input type="hidden" v-model="hiddenId" />
                        <input type="hidden" v-model="hdProfileImage" />
                        <input type="hidden" v-model="hdfrontImage" />
                        <input type="hidden" v-model="hdbackImage" />
                        <v-text-field
                          v-model.trim="firstName"
                          :rules="[
                            () => !!firstName || 'This field is required',
                          ]"
                          label="First Name"
                          placeholder="First Name"
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          required
                          id="txbfirstName"
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="lastName"
                          :rules="[
                            () => !!lastName || 'This field is required',
                          ]"
                          label="Last Name"
                          placeholder="Last Name"
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="fullName"
                          :rules="[
                            () => !!fullName || 'This field is required',
                          ]"
                          label="Full Name"
                          placeholder="Full Name"
                          variant="outlined"
                          prepend-inner-icon="mdi-account"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-autocomplete
                          ref="gender"
                          v-model="gender"
                          :items="sex"
                          :rules="[() => !!gender || 'This field is required']"
                          label="-- Choose gender --"
                          placeholder="-- Choose gender --"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-filter-variant"
                          required
                          autocomplete="off"
                          persistent-placeholder
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="phone"
                          :rules="[() => !!phone || 'This field is required']"
                          label="Phone"
                          placeholder="Phone"
                          variant="outlined"
                          prepend-inner-icon="mdi-phone"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim.lazy="dob"
                          :rules="[() => !!dob || 'This field is required']"
                          label="Date of birth"
                          placeholder="Date of birth"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          type="Date"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="email"
                          :rules="[() => !!email || 'This field is required']"
                          label="Email"
                          placeholder="Email"
                          variant="outlined"
                          prepend-inner-icon="mdi-email"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="city"
                          :rules="[() => !!city || 'This field is required']"
                          label="City"
                          placeholder="City"
                          variant="outlined"
                          prepend-inner-icon="mdi-city"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-select
                          v-model="countryId"
                          :items="items"
                          :item-title="'name'"
                          :item-value="'id'"
                          :rules="[(v) => !!v || 'Country is required']"
                          label="Country"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          disabled
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="idCardName"
                          label="ID Card Name"
                          placeholder="ID Card name"
                          variant="outlined"
                          prepend-inner-icon="mdi-card-account-details-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.trim="idCardNumber"
                          label="ID Card Number"
                          placeholder="ID Card Number"
                          variant="outlined"
                          prepend-inner-icon="mdi-card-account-details-outline"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-checkbox
                          v-model="active"
                          :rules="[(v) => !!v || '    ']"
                          label="Active?"
                          color="success"
                          v-bind:false-value="0"
                          v-bind:true-value="1"
                          required
                          type="checkbox"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                        <v-checkbox
                          v-model="status"
                          :rules="[(v) => !!v || '    ']"
                          label="Approved?"
                          color="success"
                          v-bind:false-value="0"
                          v-bind:true-value="1"
                          required
                          type="checkbox"
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          clear-icon="mdi-close-circle"
                          label="Address"
                          rows="3"
                          v-model="address"
                          clearable
                          variant="outlined"
                          density="compact"
                          required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div class="py-2" v-if="n === 2">
                <v-row>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-card variant="dense">
                      <v-img
                        :src="
                          frontImage === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : frontImage
                        "
                        aspect-ratio="1"
                        class="align-end"
                        contain
                        height="400"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                          >
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row> </template
                      ></v-img>
                    </v-card>
                    <div class="text-center">
                      <span class="my-2 text-button text-h6"
                        >Front ID Card Image</span
                      >
                    </div>
                    <v-file-input
                      v-model.trim="profileImg"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Avatar"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      @change="uploadFrontImage"
                      hide-input
                      class="mt-2"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-card variant="density">
                      <v-img
                        :src="
                          backImage === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : backImage
                        "
                        aspect-ratio="1"
                        class="align-end"
                        contain
                        height="400"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                          >
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                    <div class="text-center">
                      <span class="my-2 text-button text-h6"
                        >Back ID Card Image</span
                      >
                    </div>

                    <v-file-input
                      v-model.trim="profileImg"
                      accept="image/png, image/jpeg, image/bmp"
                      label="Avatar"
                      placeholder="Pick an avatar"
                      prepend-icon="mdi-camera"
                      @change="uploadBackImage"
                      hide-input
                      class="mt-2"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </div>
              <div class="py-2" v-if="n === 3" v-ripple.center>
                <div class="pa-4 text-center">
                  <v-img
                    class="mb-4"
                    height="128"
                    src="https://cdn-icons-png.freepik.com/512/5610/5610944.png"
                    contain
                  ></v-img>
                  <h3
                    class="text-h6 font-weight-light mb-2"
                    style="color: rgb(24, 103, 192); text-align: center"
                  >
                    {{ msgFinal }}
                  </h3>
                  <span class="text-caption text-grey"
                    >Thanks for signing up!</span
                  >
                </div>
              </div>
            </v-form>
          </v-stepper-window-item>
        </v-stepper-window>

        <v-stepper-actions
          :disabled="disabled"
          @click:next="customNext(next)"
          @click:prev="customPrev(prev)"
        ></v-stepper-actions>
      </template>
    </v-stepper>
  </div>
</template>
<script>
import {
  Path_Staff_Image,
  URL_COUNTRY,
  URL_STAFF,
  URL_UPDATE_PROFILE,
  URL_UPLOAD_STAFF_IMAGE,
} from "@/utils/apiUrl";
import { originalCode } from "@/utils/formatNumber";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      path: Path_Staff_Image,
      loading: false,
      timeout: null,
      userName: "",

      e1: 1,
      steps: 3,
      items: [],
      sex: ["Male", "Female"],

      firstName: null,
      lastName: null,
      fullName: null,
      gender: null,
      previewImage: null,
      profileImg: null,
      frontImage: null,
      backImage: null,
      phone: null,
      email: null,
      countryId: null,
      checkbox: 1,
      dob: null,
      city: null,
      address: null,
      idCardName: null,
      idCardNumber: null,
      password: "",
      confirmPassword: "",
      imageUrl: null,
      active: null,
      msgFinal: null,
      status: null,
      staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

      editedIndex: -1,
      hiddenId: 0,
      hdProfileImage: null,
      hdfrontImage: null,
      hdbackImage: null,
    };
  },

  computed: {
    disabled() {
      return this.e1 === 1
        ? "prev"
        : this.e1 === this.steps
        ? "next"
        : undefined;
    },
  },
  created() {
    this.getProfileStaff();
    this.populateAllCountries();
  },

  methods: {
    async customNext(next) {
      this.e1++;

      if (this.e1 === 3) {
        this.updateProfile();
      }

      next();
    },

    customPrev(prev) {
      this.e1--;
      //alert(this.e1);
      prev();
    },

    async getProfileStaff() {
      await axios
        .get(URL_STAFF + this.staffLoginId, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.lastName = res.data.lastName;
          this.firstName = res.data.firstName;
          this.fullName = res.data.fullName;
          this.phone = res.data.phone;
          this.dob = moment(res.data.dob).format("YYYY-MM-DD");
          this.email = res.data.email;
          this.city = res.data.city;
          this.idCardNumber = res.data.idCardNumber;
          this.idCardName = res.data.idCardName;
          this.active = res.data.active;
          this.status = res.data.status === "Approved" ? 1 : 0;
          this.countryId = res.data.countryId;
          this.gender = res.data.gender;
          this.address = res.data.address;
          this.previewImage =
            res.data.imgProfile === null
              ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              : this.path + res.data.imgProfile;
          this.frontImage =
            res.data.frontImage === null
              ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              : this.path + res.data.frontImage;
          this.backImage =
            res.data.backImage === null
              ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              : this.path + res.data.backImage;

          this.hdProfileImage = res.data.imgProfile;
          this.hdfrontImage = res.data.frontImage;
          this.hdbackImage = res.data.backImage;
        });
    },

    async populateAllCountries() {
      await axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((res) => {
        res.data.forEach((country) => {
          this.items.push({
            id: country.id,
            name: country.name,
          });
        });
      });
    },

    async updateProfile() {
      const data = {
        fullName: this.fullName,
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.phone,
        email: this.email,
        imgProfile: this.hdProfileImage,
        countryId: this.countryId,
        active: this.active,
        gender: this.gender,
        dob: this.dob,
        city: this.city,
        staffCountry: "",
        address: this.address,
        idCardName: this.idCardName,
        idCardNumber: parseInt(this.idCardNumber),
        frontImage: this.hdfrontImage,
        backImage: this.hdbackImage,
        status: "Done",
      };

      await axios
        .patch(URL_UPDATE_PROFILE + this.staffLoginId, data, {
          headers: TOKEN_HEADERS,
        })
        .then((res) => {
          this.msgFinal = res.data.message;
          // update image localStorage
          let imageName = data.imgProfile;
          localStorage.setItem("img", imageName);
        })
        .catch(
          (error) => {
            if (error.response.status === 400) {
              this.msgFinal = error.response.data.message; // This will log the error message
            } else if (error.request) {
              // The request was made but no response was received
              this.msgFinal = error.request;
            } else {
              // Something happened in setting up the request that triggered an Error
              this.msgFinal = error.message;
            }
          }
          //console.error("record updated was an error!", error)
        );
    },

    triggerFileInput() {
      //this.$refs.fileInput.click();
      document.getElementById("filePreviousImage").click();
    },

    uploadImage(e) {
      alert();
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(URL_UPLOAD_STAFF_IMAGE, data).then((response) => {
        this.hdProfileImage = response.data.filename;
        console.log("Image has been uploaded.");
      });
    },

    uploadFrontImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.frontImage = e.target.result;
        console.log(this.frontImage);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(URL_UPLOAD_STAFF_IMAGE, data).then((response) => {
        this.hdfrontImage = response.data.filename;
        console.log("Image has been uploaded.");
      });
    },

    uploadBackImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.backImage = e.target.result;
        console.log(this.backImage);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(URL_UPLOAD_STAFF_IMAGE, data).then((response) => {
        this.hdbackImage = response.data.filename;
        console.log("Image has been uploaded.");
      });
    },
  },
};
</script>
<style scoped>
.stepper-container {
  height: 80vh; /* Adjust as needed */
  overflow-y: auto;
}
</style>
