<template>
  <div class="bg-grey-lighten-4 my-bg margin-auto">
    <VRow>
      <VCol cols="12" md="8">
        <VCard
          width="50%"
          elevation="0"
          style="background-color: transparent"
        >
          <h3 class="text-white font-weight-regular">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos. consectetur adipiscing elit. Nunc vulputate
            libero et velit interdum, ac aliquet odio mattis. Class aptent
            taciti sociosqu ad l
          </h3>
          <br />
          <VBtn
            width="110"
            size="small"
            rounded="xl"
            class="text-none"
            color="white"
            variant="outlined"
          >
            Learn More
          </VBtn>
        </VCard>
      </VCol>

      <VCol cols="12" md="4">
        <VCard
          rounded="xl"
          color="black"
          style="opacity: 60%; color: white"
          class="pa-5"
        >
          <h3 class="text-white">Calculate Profit</h3>
          <br />
          <VSelect
            rounded="xl"
            :items="categories"
            variant="outlined"
            v-model="form.project_name"
            density="compact"
          ></VSelect>

          <VTextField
            suffix="shares"
            rounded="xl"
            density="compact"
            variant="outlined"
            v-model="form.shares"
            class="text-white"
            color="white"
          />

          <VTextField
            rounded="xl"
            density="compact"
            variant="outlined"
            v-model="form.amount"
            class="text-white"
            color="white"
            prefix="$"
          />

          <div class="my-1 d-flex justify-space-between">
            <VBtn
              width="30%"
              class="text-none"
              rounded="xl"
              variant="outlined"
            >Daily
            </VBtn
            >
            <VBtn
              width="30%"
              class="text-none"
              rounded="xl"
              variant="outlined"
            >Weekly
            </VBtn
            >
            <VBtn
              width="30%"
              class="text-none"
              rounded="xl"
              variant="outlined"
            >Monthly
            </VBtn
            >
          </div>

          <div class="my-4 d-flex justify-space-between">
            <VBtn
              width="45%"
              class="text-none"
              rounded="xl"
              variant="outlined"
            >Quarterly
            </VBtn
            >
            <VBtn
              width="45%"
              class="text-none"
              rounded="xl"
              variant="outlined"
            >Semi-Annually
            </VBtn
            >
          </div>

          <div class="my-4 d-flex justify-space-between">
            <VBtn
              width="45%"
              class="text-none"
              rounded="xl"
              variant="outlined"
            >Annually
            </VBtn
            >
          </div>

          <div class="my-5 d-flex justify-space-between">
            <VBtn
              width="45%"
              class="text-none"
              rounded="xl"
              variant="outlined"
            >Calculate
            </VBtn
            >
          </div>

          <v-row class="mx-0">
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-ts-xl" style="background: transparent">
                <h5 class="text-white">Total Percent</h5>
                <h2 class="text-end text-primary">1.00%</h2>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-te-xl" style="background: transparent">
                <h5 class="text-white">Profit</h5>
                <h2 class="text-end text-primary">6%</h2>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-bs-xl" style="background: transparent">
                <h5 class="text-white">Total Profit</h5>
                <h2 class="text-end text-primary">$1.500</h2>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-be-xl" style="background: transparent">
                <h5 class="text-white">Total Return</h5>
                <h2 class="text-end text-primary">$8.500</h2>
              </v-sheet>
            </v-col>
          </v-row>

          <br/>

        </VCard>
      </VCol>
    </VRow>
    <br/>
  </div>

</template>
<script>
export default {
  name: 'CalculateProfit',
  data() {
    return {
      categories: [
        'Select Project',
        'Civil work',
        'Consultant Service',
        'Goods',
        'Non-Consulting',
      ],
      form: {
        project_name: 'Select Project',
        shares: 10,
        amount: 1000,
        durations: 'Daily',
      },
    };
  },
};
</script>
<style>
.my-bg {
  background-image: url("../../../../assets/Rectangle 6.png");
  min-height: 550px;
  color: #fff;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
