<script>
import CardRelated from '@/views/website/projects/components/CardRelated.vue';

export default {
  name: 'RelatedNews',
  components: { CardRelated },
  data() {
    return {};
  },
};
</script>

<template>
  <h3>
    Related
  </h3>
  <VDivider />
  <template v-for="i in 3" v-bind:key="i">
    <CardRelated />
  </template>
  <VDivider />
</template>

<style scoped>

</style>