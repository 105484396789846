<script>
export default {
  name: 'CardRelated',
};
</script>

<template>
  <v-card rounded="xl" variant="outlined" class="my-5">
    <div class="d-flex">
      <v-avatar
        rounded="0"
        size="180"
      >
        <v-img src="https://upload.wikimedia.org/wikipedia/commons/e/e6/Dubai_Marina_Skyline.jpg"></v-img>
      </v-avatar>

      <div class="pa-1">
        <v-card-title class="text-uppercase text-orange">
          <h5>Country</h5>
        </v-card-title>

        <h3 class="px-4">
          Lorem ipsum dolor sit amet
          <div class="mt-5"></div>
          <VDivider />
          <div class="mt-3"></div>
          <router-link to="/projects" class="text-decoration-none">
            <h5 class="text-primary font-weight-bold text-uppercase">
              Read more
              <VIcon>mdi-arrow-right</VIcon>
            </h5>
          </router-link>
        </h3>
      </div>
    </div>
  </v-card>
</template>

<style scoped>

</style>