<template>
  <v-card color="white" height="" rounded="0">
    <v-row class="margin-auto">
      <v-col md="3" sm="12">
        <img src="../../../assets/logo.png" alt="" class="footer-logo" />
        <p>
          #123, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
        </p>

        <v-btn variant="text" icon="mdi-facebook" color="blue-darken-3" size="small"></v-btn>

        <v-btn variant="text" icon="mdi-linkedin" color="blue-darken-3" size="small"></v-btn>

        <v-btn variant="text" icon="mdi-twitter" color="blue-darken-3" size="small"></v-btn>

        <v-btn variant="text" icon="mdi-youtube" color="blue-darken-3" size="small"></v-btn>

        <v-btn variant="text" icon="mdi-whatsapp" color="blue-darken-3" size="small"></v-btn>
      </v-col>

      <v-col md="3" sm="12"> </v-col>

      <v-col md="3" sm="12">
        <div class="d-flex flex-column mb-6 bg-surface-variants">
          <v-sheet class="ma-1 pa-1">
            <router-link to="/" class="text-decoration-none text-blue-darken-3">
              Useful Links
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link to="/about-us" class="text-decoration-none text-black">
              About Us
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link
              to="/contact-us"
              class="text-decoration-none text-black"
            >
              Contact
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link to="/projects" class="text-decoration-none text-black">
              Projects
            </router-link>
          </v-sheet>
          <v-sheet class="ma-1 pa-1">
            <router-link to="/" class="text-decoration-none text-black">
              Terms & Policy
            </router-link>
          </v-sheet>
        </div>
      </v-col>

      <v-col md="3" sm="12">
        <div class="d-flex flex-column mb-6 bg-surface-variants">
          <v-sheet class="ma-1 pa-1">
            <router-link to="/" class="text-decoration-none text-blue-darken-3">
              Subscribe to our newsletter
            </router-link>
          </v-sheet>

          <v-text-field
            label="Enter Email"
            type="text"
            variant="underlined"
            placeholder="Enter Email"
          ></v-text-field>

          <div>
            <v-btn color="blue-darken-3" rounded="xl" size="large"
              >Subscribe</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>

    <hr />

    <v-row class="d-flex justify-lg-space-between ma-1">
      <v-col md="6" sm="12" class="">
        <img src="../../../assets/logo.png" alt="" class="footer-logo" />
      </v-col>

      <v-col md="6" sm="12" class="align-self-center">
        <p class="text-right">@copy right | MiGA Company 2022 powered by MIT</p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "FooterPage",
};
</script>

<style scoped>
.footer-logo {
  width: 240px;
}
</style>
