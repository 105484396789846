<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search asia pacific..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="asiaPacificCollection"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[
        { key: 'createdAt', order: 'desc' },
        { key: 'title', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  v-show="!visible"
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">
                    Add
                  </div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visibleMsg"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12">
                    <v-col cols="12">
                      <input type="hidden" v-model="hiddenId" />
                      <v-text-field
                        v-model.trim="title"
                        :rules="[() => !!title || 'This field is required']"
                        label="Title"
                        placeholder="Title"
                        variant="outlined"
                        prepend-inner-icon="mdi-subtitles-outline"
                        required
                        autocomplete="off"
                        density="compact"
                        persistent-placeholder
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-label class="font-weight-thin x-small"
                        >Description 1</v-label
                      >
                      <QuillEditor
                        toolbar="essential"
                        contentType="html"
                        style="height: 150px"
                        v-model:content="desc1"
                        theme="snow"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-label class="font-weight-thin x-small"
                        >Description 2</v-label
                      >
                      <QuillEditor
                        toolbar="essential"
                        contentType="html"
                        style="height: 150px"
                        v-model:content="desc2"
                        theme="snow"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-label class="font-weight-thin x-small"
                        >Description 3</v-label
                      >
                      <QuillEditor
                        toolbar="essential"
                        contentType="html"
                        style="height: 150px"
                        v-model:content="desc3"
                        theme="snow"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col sm="4" md="4" lg="4" xl="4">
                          <v-card
                            variant="variant"
                            class="mx-auto"
                            elevation="16"
                            subtitle="Image 1"
                          >
                            <v-img
                              :src="
                                previewImage1 === null
                                  ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                  : previewImage1
                              "
                              aspect-ratio="1"
                              color="surface-variant"
                              height="450"
                              contain
                              @click="triggerFileInput1"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  align="center"
                                  class="fill-height ma-0"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-card>

                          <v-file-input
                            v-model.trim="image1"
                            accept="image/png, image/jpeg, image/bmp"
                            label="Avatar"
                            placeholder="Pick an avatar"
                            prepend-icon="mdi-camera"
                            @change="uploadImage1"
                            hide-input
                            class="mt-2"
                            ref="fileInput1"
                            style="display: none"
                          ></v-file-input>
                        </v-col>
                        <v-col sm="4" md="4" lg="4" xl="4">
                          <v-card
                            variant="variant"
                            class="mx-auto"
                            elevation="16"
                            subtitle="Image 2"
                          >
                            <v-img
                              :src="
                                previewImage2 === null
                                  ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                  : previewImage2
                              "
                              aspect-ratio="1"
                              color="surface-variant"
                              height="450"
                              contain
                              @click="triggerFileInput2"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  align="center"
                                  class="fill-height ma-0"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-card>

                          <v-file-input
                            v-model.trim="image2"
                            accept="image/png, image/jpeg, image/bmp"
                            label="Avatar"
                            placeholder="Pick an avatar"
                            prepend-icon="mdi-camera"
                            @change="uploadImage2"
                            hide-input
                            class="mt-2"
                            ref="fileInput2"
                            style="display: none"
                          ></v-file-input>
                        </v-col>
                        <v-col sm="4" md="4" lg="4" xl="4">
                          <v-card
                            variant="variant"
                            class="mx-auto"
                            elevation="16"
                            subtitle="Image 3"
                          >
                            <v-img
                              :src="
                                previewImage3 === null
                                  ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                  : previewImage3
                              "
                              aspect-ratio="1"
                              color="surface-variant"
                              height="450"
                              contain
                              @click="triggerFileInput3"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  align="center"
                                  class="fill-height ma-0"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-card>

                          <v-file-input
                            v-model.trim="image3"
                            accept="image/png, image/jpeg, image/bmp"
                            label="Avatar"
                            placeholder="Pick an avatar"
                            prepend-icon="mdi-camera"
                            @change="uploadImage3"
                            hide-input
                            class="mt-2"
                            ref="fileInput3"
                            style="display: none"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-col>

                    <input type="hidden" v-model="hdimage1" />
                    <input type="hidden" v-model="hdimage2" />
                    <input type="hidden" v-model="hdimage3" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogImage"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox"
                >Image</v-toolbar-title
              >

              <v-btn icon="mdi-close" @click="closeDialgeImage"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-img
                  :lazy-src="viewImage"
                  :src="viewImage"
                  aspect-ratio="1"
                  class="bg-grey-lighten-2"
                  height="85vh"
                  contain
                  max-width="auto"
                >
                  <div class="fill-height bottom-gradient"></div>
                  <template v-slot:placeholder>
                    <v-row
                      align="center"
                      class="fill-height ma-0"
                      justify="center"
                    >
                      <v-progress-circular
                        color="grey-lighten-5"
                        indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="closeDialgeImage"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.image1`]="{ item }">
        <div v-if="item.image1" class="text-center">
          <v-avatar
            size="80"
            class="my-1"
            @click="showDialogueImage(item.image1)"
          >
            <v-img alt="Avatar" :src="path + item.image1" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.image2`]="{ item }">
        <div v-if="item.image2" class="text-center">
          <v-avatar
            size="80"
            class="my-1"
            @click="showDialogueImage(item.image2)"
          >
            <v-img alt="Avatar" :src="path + item.image2" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.image3`]="{ item }">
        <div v-if="item.image3" class="text-center">
          <v-avatar
            size="80"
            class="my-1"
            @click="showDialogueImage(item.image3)"
          >
            <v-img alt="Avatar" :src="path + item.image3" cover></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="80" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.desc1`]="{ item }">
        <div v-html="item.desc1"></div>
      </template>
      <template v-slot:[`item.desc2`]="{ item }">
        <div v-html="item.desc2"></div>
      </template>
      <template v-slot:[`item.desc3`]="{ item }">
        <div v-html="item.desc3"></div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
              <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_ASIA_PACIFIC_IMAGE,
  URL_ASIA_PACIFIC,
  URL_UPLOAD_ASIA_PACIFIC_IMAGE,
} from "@/utils/apiUrl";
import { IMAGE_TOKEN_HEADERS, TOKEN_HEADERS } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    path: Path_ASIA_PACIFIC_IMAGE,
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: false,
    visibleMsg: true,
    dialogImage: false,

    items: [],
    asiaPacificCollection: [],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,

    title: null,
    desc1: null,
    desc2: null,
    desc3: null,
    image1: null,
    image2: null,
    image3: null,
    previewImage1: null,
    previewImage2: null,
    previewImage3: null,
    viewImage: null,

    hdimage1: null,
    hdimage2: null,
    hdimage3: null,

    editedIndex: -1,
    hiddenId: 0,
    page: 1,
    itemsPerPage: 10,

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Image 1", key: "image1", align: "center" },
      { title: "Image 2", key: "image2", align: "center" },
      { title: "Image 3", key: "image3", align: "center" },
      { title: "Title", key: "title", align: "center" },
      { title: "Desc 1", key: "desc1", align: "center" },
      { title: "Desc 2", key: "desc2", align: "center" },
      { title: "Desc 3", key: "desc3", align: "center" },
      { title: "Create at", key: "createdAt", align: "center" },
      { title: "Created by", key: "staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Asia Pacific"
        : "Update Asia Pacific";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.asiaPacificCollection.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.populateasiaPacificCollection();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.title = null;
      this.desc1 = null;
      this.desc2 = null;
      this.desc3 = null;
      this.previewImage1 = null;
      this.previewImage2 = null;
      this.previewImage3 = null;
      this.visibleMsg = true;
    },

    async populateasiaPacificCollection() {
      await axios
        .get(URL_ASIA_PACIFIC, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.asiaPacificCollection = res.data;
          this.items.push(res.data);
          this.visible = res.data.length !== 0 ? true : false;
        });
    },

    editItem(item) {
      this.editedIndex = this.asiaPacificCollection.indexOf(item);
      this.hiddenId = item.id;
      this.editedItem = Object.assign({}, item);
      this.title = item.title;
      this.desc1 = item.desc1;
      this.desc2 = item.desc2;
      this.desc3 = item.desc3;

      this.previewImage1 =
        item.image1 === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.image1;
      this.hdimage1 = item.image1;

      this.previewImage2 =
        item.image2 === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.image2;
      this.hdimage2 = item.image2;

      this.previewImage3 =
        item.image3 === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.image3;
      this.hdimage3 = item.image3;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.asiaPacificCollection.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.asiaPacificCollection.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_ASIA_PACIFIC + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function () {
          this.snakeMessage("error", "Data has been deleted.");
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const data = {
          title: this.title,
          image1: this.hdimage1,
          image2: this.hdimage2,
          image3: this.hdimage3,
          desc1: this.desc1,
          desc2: this.desc2,
          desc3: this.desc3,
        };

        if (this.editedIndex > -1) {
          // edit
          const id = this.hiddenId;
          const url = URL_ASIA_PACIFIC + id;
          Object.assign(
            this.asiaPacificCollection[this.editedIndex],
            this.editedItem
          );

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visibleMsg = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );
        } // create new
        else {
          data.createdBy = this.staffLoginId;
          await axios
            .post(URL_ASIA_PACIFIC, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visibleMsg = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );
        }

        //this.clearContent();
        this.populateasiaPacificCollection();

        //this.dialog = false;
        //this.close();
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    triggerFileInput1() {
      this.$refs.fileInput1.click();
    },
    triggerFileInput2() {
      this.$refs.fileInput2.click();
    },
    triggerFileInput3() {
      this.$refs.fileInput3.click();
    },

    uploadImage1(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage1 = e.target.result;
        console.log(this.previewImage1);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios
        .post(URL_UPLOAD_ASIA_PACIFIC_IMAGE, data, {
          headers: IMAGE_TOKEN_HEADERS,
        })
        .then((response) => {
          this.hdimage1 = response.data.filename;
          console.log("Image has been uploaded.");
        });
    },
    uploadImage2(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage2 = e.target.result;
        console.log(this.previewImage2);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios
        .post(URL_UPLOAD_ASIA_PACIFIC_IMAGE, data, {
          headers: IMAGE_TOKEN_HEADERS,
        })
        .then((response) => {
          this.hdimage2 = response.data.filename;
          console.log("Image has been uploaded.");
        });
    },
    uploadImage3(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage3 = e.target.result;
        console.log(this.previewImage3);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios
        .post(URL_UPLOAD_ASIA_PACIFIC_IMAGE, data, {
          headers: IMAGE_TOKEN_HEADERS,
        })
        .then((response) => {
          this.hdimage3 = response.data.filename;
          console.log("Image has been uploaded.");
        });
    },

    showDialogueImage(item) {
      this.viewImage = this.path + item;
      this.dialogImage = true;
    },

    closeDialgeImage() {
      this.dialogImage = false;
    },
  },
};
</script>
