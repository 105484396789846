<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">

      <div class="margin-auto">
        <br />
        <div>
          <h2 class="text-center text-uppercase text-primary">Projects</h2>
          <br />
          <h3 class="font-weight-regular text-justify">
            {{projectTitle?.description }}
          </h3>
        </div>
        <br />
      </div>
<!--      <pre>{{exploreMores}}</pre>-->
      <VCard class="bg-primary" min-height="140">
        <div class="margin-auto">
          <VRow class="pa-10">
            <VCol cols="12" md="4">
              <v-autocomplete
                rounded="xl"
                placeholder="Project Status"
                density="comfortable"
                variant="solo"
                v-model="params.projectStatus"
                :items="projectStatus"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="2"></VCol>
            <VCol cols="12" md="3">
              <v-autocomplete
                rounded="xl"
                placeholder="Sector"
                density="comfortable"
                variant="solo"
                v-model="params.sector"
                :items="sectors"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="3">
              <VBtn
                rounded="xl"
                size="large"
                block
                variant="outlined"
                @click="onSearch()"
              >
                Search
              </VBtn>
            </VCol>
          </VRow>
        </div>
      </VCard>

      <CardNews :exploreMores="exploreMores" />

      <div class="d-flex justify-center">
        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
          First
        </VBtn>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
          Previous
        </VBtn>

        <v-pagination
          v-model="params.skip"
          :length="totalPages"
          rounded="circle"
          prev-icon=""
          next-icon=""
        ></v-pagination>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
          Next
        </VBtn>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
          Last
        </VBtn>
      </div>

      <br />
      <br />
    </div>
  </FrontendLayout>
</template>

<script>
import FrontendLayout from '../components/FrontendLayout';
import CardNews from '@/views/website/projects/components/CardNews.vue';
import { PROJECT } from '@/utils/customerApiUrl';
import { getParams } from '@/utils/function';

export default {
  name: 'ProjectPage',
  components: {
    CardNews,
    FrontendLayout,
  },
  data() {
    return {
      projectStatus: [
        'Active',
        'Sold out',
        'Proposed',
        'Crowdfunding',
      ],
      sectors: [
        'Agribusiness',
        'Banking',
        'Capital Markets',
        'Chemicals',
        'Construction',
        'Education',
      ],
      params: {
        projectStatus: '',
        sector: '',
        skip: 1,
        take: 10,
      },
      total: 0,
      totalPages: 1,
      exploreMores: [
        {
          id: 1,
          image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
          "description_project": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
          id: 2,
          image: 'https://inkstickmedia.com/wp-content/uploads/2021/11/jake-grella-Q0sAi6qdgUI-unsplash-scaled-e1636518588857.jpg',
          "description_project": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        {
          id: 3,
          image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
          "description_project": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
      ],
      projectTitle: null
    };
  },
  methods: {
    async fetchData() {
      this.exploreMores = [];
      let params = getParams(this.params);
      this.axios
        .get(PROJECT + params)
        .then((response) => {
          let { data, sectors, total , projectStatus, totalPages, projectTitle} = response.data;
          this.exploreMores = data;
          this.sectors = sectors;
          this.projectStatus = projectStatus;
          this.totalPages = totalPages;
          this.total = total;
          this.projectTitle = projectTitle;
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
    onSearch() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
