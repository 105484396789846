<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model.trim="start"
            label="Start Date"
            placeholder="Registered Date"
            variant="outlined"
            autocomplete="off"
            hide-details
            single-line
            type="Date"
            @change="filterItems"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model.trim="end"
            label="End Date"
            placeholder="Registered Date"
            variant="outlined"
            autocomplete="off"
            hide-details
            single-line
            type="Date"
            @change="filterItems"
            persistent-placeholder
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-select
            v-if="(isAllCountry === 1) | (isAdmin === 1)"
            v-model="countryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            @update:modelValue="filterItems"
          >
          </v-select>
          <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                    but else the data return as value normally -->
          <v-select
            v-else
            v-model="countryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            disabled
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model="search"
            label="Search customer login..."
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      class="dark"
      rounded="compact"
      divided
      fixed-header
      striped
      hover
      color="secondary"
      :search="search"
      :headers="headers"
      :items="CustomersLogin"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[
        { key: 'loginDate', order: 'desc' },
        { key: 'customer.name', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-sheet class="d-flex flex-row-reverse">
          <v-sheet class="ma-2 pa-2 text-center">
            <v-select
              v-model="itemsPerPage"
              :items="[10, 25, 50, 100, 1000]"
              hide-details
              density="compact"
              variant="outlined"
              @update:model-value="itemsPerPage = parseInt($event, 10)"
              style="width: 100px"
            ></v-select>
          </v-sheet>
          <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
        </v-sheet>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-4"></v-divider>
            <v-sheet border="lg opacity-12" class="text-body-2">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        height="40vh"
                        :src="
                          profile === null
                            ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                            : profile
                        "
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                          >
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="4">
                    <ul class="ps-4 mb-6" style="list-style-type: none">
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-account"></v-icon> First name:
                          {{ firstName }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-account"></v-icon> Last name:
                          {{ lastName }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-email"></v-icon> Email:
                          {{ email }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-clipboard-text-clock"></v-icon> DoB:
                          {{ dateOfBirth }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon
                            icon="mdi-card-account-details-outline"
                          ></v-icon>
                          Card name:
                          {{ nameCard }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-map-marker"></v-icon> Address:
                          {{ address }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-ip"></v-icon> IP:
                          {{ ip }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon
                            icon="mdi-map-marker-account-outline"
                          ></v-icon>
                          Longtitude:
                          {{ longitude }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon
                            icon="mdi-map-marker-account-outline"
                          ></v-icon>
                          Latitude:
                          {{ latitude }}
                        </p>
                      </li>

                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-code-braces-box"></v-icon> Country
                          code:
                          {{ countryCode }}
                        </p>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="12" md="4">
                    <ul class="ps-4 mb-6" style="list-style-type: none">
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-account"></v-icon> Name:
                          {{ name }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-gender-male"></v-icon> Sex:
                          {{ gender }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-phone-in-talk"></v-icon> Phone:
                          {{ phone }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-list-status"></v-icon> Status:
                          {{ status }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-list-status"></v-icon> Card No.:
                          {{ cardNumber }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-login"></v-icon> Date expired:
                          {{ expireDate }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-open-in-app"></v-icon> Browser:
                          {{ browser }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-city"></v-icon> City:
                          {{ city }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-flag"></v-icon> Country:
                          {{ country }}
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <v-icon icon="mdi-rename-box"></v-icon> Region code:
                          {{ regionName }}
                        </p>
                      </li>
                    </ul>
                  </v-col>
                  <v-col cols="12">
                    <GoogleMap
                      api-key="AIzaSyCu9J7pzagPked6IaykBLi4VZORGeDzvUw"
                      style="width: 100%; height: 400px"
                      :center="center"
                      :zoom="15"
                    >
                      <Marker :options="{ position: center }" />
                    </GoogleMap>
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.staff.fullName`]="{ item }">
        <v-avatar size="64" class="my-1" v-if="item.customer.profile !== null">
          <v-img :src="path + item.customer.profile" cover>
            <!--
              <div
                :class="
                  item.staff.online === 1 ? 'dot online-green' : 'dot offline-red'
                "
              ></div> -->
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey-lighten-5"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <v-avatar
          class="my-1"
          v-else
          image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          size="80"
        >
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular
                color="grey-lighten-5"
                indeterminate
              ></v-progress-circular>
            </v-row>
          </template>
        </v-avatar>

        <v-span class="ml-2">
          {{ item.customer.name !== null ? item.customer.name : "" }}
        </v-span>
      </template>
      <template v-slot:[`item.customer.status`]="{ item }">
        <v-chip
          size="small"
          v-if="item.customer.status === 'pending'"
          prepend-icon="mdi-account-clock-outline"
          color="info"
        >
          {{ item.customer.status }}
        </v-chip>
        <v-chip
          size="small"
          v-if="item.customer.status === 'done'"
          prepend-icon="mdi-account-check-outline"
          color="success"
        >
          {{ item.customer.status }}
        </v-chip>
        <v-chip
          size="small"
          v-if="item.customer.status === 'review'"
          prepend-icon="mdi-account-check-outline"
          color="warning"
        >
          {{ item.customer.status }}
        </v-chip>
        <v-chip
          size="small"
          v-if="item.customer.status === 'rejected'"
          prepend-icon="mdi-account-check-outline"
          color="error"
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.loginDate`]="{ item }">
        {{ new Date(item.loginDate).toLocaleString() }}
      </template>
      <template v-slot:[`item.customer.dateOfBirth`]="{ item }">
        {{ new Date(item.customer.dateOfBirth).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="success"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-eye" start></v-icon>
          Previous
        </v-btn>
        <!--
          <v-icon
            class="me-2"
            color="success"
            size="large"
            @click="editItem(item)"
             title="Edit"
          >
            mdi-eye
          </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Customer_Image,
  URL_COUNTRY,
  URL_CUSTOMERS_LOGIN,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { GoogleMap, Marker } from "vue3-google-map";
import { originalCode } from "@/utils/formatNumber";

export default {
  components: { GoogleMap, Marker },
  data: () => ({
    search: "",
    start: null,
    end: null,
    snack: false,
    snackColor: "",
    snackText: "",
    dialog: false,
    dialogDelete: false,

    items: [],
    CustomersLogin: [],
    countries: ["-- All --"],
    center: [],

    path: Path_Customer_Image,

    email: null,
    name: null,
    occupation: null,
    referal: null,
    status: null,
    phone: null,
    countryCode: null,
    country: null,
    ip: null,
    isp: null,
    longitude: null,
    latitude: null,
    countryLocation: null,
    regionName: null,
    gender: null,
    address: null,
    state: null,
    city: null,
    lastName: null,
    firstName: null,
    postalCode: null,
    profile: null,
    nationality: null,
    dateOfBirth: null,
    typeCard: null,
    frontCard: null,
    backCard: null,
    cardNumber: null,
    nameCard: null,
    expireDate: null,
    browser: null,
    org: null,
    countryId: null,

    isAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    staffCountryId: originalCode(parseInt(localStorage.getItem("countryId"))),

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", align: "center", key: "actions", sortable: false },
      {
        title: "",
        align: "center",
        sortable: true,
        key: "customer.profile",
      },
      {
        title: "Customer",
        align: "center",
        sortable: true,
        key: "customer.name",
      },
      {
        title: "Customer",
        align: "center",
        sortable: true,
        key: "customer.country",
      },
      {
        title: "Status",
        align: "center",
        key: "customer.status",
        sortable: true,
      },
      {
        title: "Email",
        align: "center",
        key: "customer.email",
        sortable: true,
      },
      {
        title: "Phone",
        align: "center",
        key: "customer.phone",
        sortable: true,
      },
      {
        title: "DoB",
        align: "center",
        key: "customer.dateOfBirth",
        sortable: true,
      },
      {
        title: "Address",
        align: "center",
        key: "customer.address",
        sortable: true,
      },
      { title: "Login at", align: "center", key: "loginDate", sortable: true },
    ],

    editedIndex: -1,
    hiddenId: 0,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "View Customer Login"
        : "Edit Permission";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.CustomersLogin.length / this.itemsPerPage);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.populateCustomersLogin();
    this.PopulateCountries();
  },
  methods: {
    async populateCustomersLogin() {
      await axios
        .get(URL_CUSTOMERS_LOGIN + this.countryId, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.CustomersLogin = res.data;
          this.items.push(res.data);
        });
    },

    async filterItems() {
      var codeCountry = this.getCountryCodeByCountryId(this.countryId.id);

      if (codeCountry === undefined) {
        this.populateCustomersLogin();
      } else {
        const linkUrl = URL_CUSTOMERS_LOGIN + codeCountry;
        await axios.get(linkUrl, { headers: TOKEN_HEADERS }).then((res) => {
          //this.customerConfirmations = res.data;
          const startDate = this.start
            ? new Date(this.start).toLocaleDateString()
            : null;
          const endDate = this.end
            ? new Date(this.end).toLocaleDateString()
            : null;

          const searchCountryCode = parseInt(codeCountry);

          this.CustomersLogin = res.data.filter((item) => {
            const itemDate = new Date(item.loginDate).toLocaleDateString();
            const itemCountryCode = parseInt(item.countryCode);

            const matchesDateRange =
              (!startDate || itemDate >= startDate) &&
              (!endDate || itemDate <= endDate);
            const matchesCountry =
              !searchCountryCode || itemCountryCode === searchCountryCode;

            //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
            //const matchesSearch = !search || item.name.toLowerCase().includes(search);

            return matchesDateRange && matchesCountry;
          });

          this.items.push(this.CustomersLogin);
        });
      }
    },

    getCountryCodeByCountryId(countryId) {
      var codeCountry = this.countries.filter((item) => {
        var code = 0;
        if (countryId !== undefined) {
          if (item.id === countryId) {
            code = item.code;
          }
        } else {
          code = this.staffCountryId;
        }

        return code;
      });

      return codeCountry[0].code;
    },

    editItem(item) {
      this.profile =
        item.customer.profile === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.customer.profile;

      this.email = item.customer.email;
      this.name = item.customer.name;
      this.occupation = item.customer.occupation;
      this.referal = item.customer.referal;
      this.status = item.customer.status;
      this.phone = item.customer.phone;
      this.countryCode = item.customer.countryCode;
      this.country = item.customer.country;
      this.ip = item.ip;
      this.longitude = item.longitude;
      this.latitude = item.latitude;
      this.countryLocation = item.customer.countryLocation;
      this.regionName = item.customer.regionName;
      this.gender = item.customer.gender;
      this.address = item.customer.address;
      this.state = item.customer.state;
      this.city = item.customer.city;
      this.lastName = item.customer.lastName;
      this.firstName = item.customer.firstName;
      this.postalCode = item.customer.postalCode;
      this.nationality = item.customer.nationality;
      this.dateOfBirth = item.customer.dateOfBirth;
      this.typeCard = item.customer.typeCard;
      this.cardNumber = item.customer.cardNumber;
      this.nameCard = item.customer.nameCard;
      this.expireDate = item.customer.expireDate;
      this.browser = item.customer.browser;
      this.org = item.customer.org;

      this.center = {
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longtitude),
      };

      this.frontCard =
        item.frontCard === null
          ? "https://static.thenounproject.com/png/4595376-200.png"
          : this.path + item.customer.frontCard;
      this.backCard =
        item.backCard === null
          ? "https://static.thenounproject.com/png/4595376-200.png"
          : this.path + item.customer.backCard;

      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    isAdminOrAllCountryPermission() {
      return (this.isAdmin || this.isAllCountry) === 1 ? true : false;
    },

    async PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            id: country.id,
            name: country.name,
            code: country.code,
          });
        });

        //this.countryId = this.staffCountryId;
        this.countryId = this.isAdminOrAllCountryPermission()
          ? "-- All --"
          : this.staffCountryId;
      });
    },
  },
};
</script>
<style scoped>
.dot {
  position: relative;
  top: 39px;
  right: -44px;
  width: 15px;
  height: 15px;

  border-radius: 50%;
}
.online-green {
  background-color: green;
}
.offline-red {
  background-color: red;
}
</style>
