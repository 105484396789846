<template>
  <v-row
    class="overflow-y-auto row-container"
    :style="$vuetify.display.mobile ? 'height: 130vh;' : undefined"
  >
    <v-col cols="12" md="3" lg="3" xl="3">
      <v-alert
        border="top"
        type="warning"
        icon="mdi-account-clock-outline"
        variant="outlined"
        prominent
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >pending</v-card-title
          >
          <v-card-text
            class="text-capitalize text-overline text-decoration-underline text-h6"
          >
            <span style="font-size: large">{{ totalPending }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>

    <v-col cols="12" md="3" lg="3" xl="3">
      <v-alert
        border="top"
        type="success"
        variant="outlined"
        icon="mdi-account-check"
        prominent
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >Done</v-card-title
          >
          <v-card-text
            class="text-capitalize text-overline text-decoration-underline"
          >
            <span style="font-size: large">{{ totalDone }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>

    <v-col cols="12" md="3" lg="3" xl="3">
      <v-alert
        border="top"
        type="success"
        color="primary"
        variant="outlined"
        prominent
        icon="mdi-account-eye"
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >Review</v-card-title
          >
          <v-card-text
            class="text-capitalize text-overline text-decoration-underline"
          >
            <span style="font-size: large">{{ totalReview }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>

    <v-col cols="12" md="3" lg="3" xl="3">
      <v-alert
        border="top"
        type="success"
        color="error"
        variant="outlined"
        prominent
        icon="mdi-account-cancel"
        class="d-flex justify-center"
      >
        <v-card width="300px" max-width="auto">
          <v-card-title
            class="text-uppercase font-weight-black"
            style="font-size: smaller"
            >Reject</v-card-title
          >
          <v-card-text
            class="text-capitalize text-overline text-decoration-underline"
          >
            <span style="font-size: large">{{ totalReject }}</span>
          </v-card-text>
        </v-card>
      </v-alert>
    </v-col>

    <v-col cols="12">
      <v-card flat>
        <template v-slot:text>
          <v-row>
            <v-col cols="12" md="3" lg="3" xl="3">
              <v-text-field
                v-model.trim="start"
                label="Start Date"
                placeholder="Registered Date"
                variant="outlined"
                autocomplete="off"
                hide-details
                single-line
                type="Date"
                @change="filterItems"
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3">
              <v-text-field
                v-model.trim="end"
                label="End Date"
                placeholder="Registered Date"
                variant="outlined"
                autocomplete="off"
                hide-details
                single-line
                type="Date"
                @change="filterItems"
                persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3">
              <v-select
                v-if="(isAllCountry === 1) | (isAdmin === 1)"
                v-model="countryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                autocomplete="off"
                persistent-placeholder
                class="style-chooser"
                @update:modelValue="filterItems"
              >
              </v-select>

              <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
              <v-select
                v-else
                v-model="countryId"
                :items="countries"
                :item-title="'name'"
                :item-value="'id'"
                variant="outlined"
                prepend-inner-icon="mdi-flag-outline"
                required
                autocomplete="off"
                persistent-placeholder
                placeholder="Choose customer..."
                disabled
                @update:modelValue="filterItems"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3">
              <v-text-field
                v-model="search"
                label="Search customer..."
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            location="top"
          >
            {{ snackText }}
          </v-snackbar>
        </template>

        <v-tabs
          v-model="tab"
          align-tabs="end"
          @click="filterItems"
          color="deep-purple-accent-4"
        >
          <v-tab :value="0">All</v-tab>
          <v-tab :value="1">Pending</v-tab>
          <v-tab :value="2">Done</v-tab>
          <v-tab :value="3">Review</v-tab>
          <v-tab :value="4">Reject</v-tab>
        </v-tabs>

        <v-tabs-window v-model="tab">
          <v-tabs-window-item v-for="n in 5" :key="n" :value="n">
            <v-container fluid>
              <v-row>
                <v-data-table
                  color="#b2d7ef"
                  rounded="compact"
                  divided
                  striped
                  hover
                  class="elevation-1"
                  fixed-header
                  :search="search"
                  :headers="headers"
                  :items="customerConfirmations"
                  :sort-by="[{ key: 'createdAt', order: 'desc' }]"
                >
                  <template v-slot:top>
                    <v-dialog
                      v-model="dialog"
                      persistent
                      fullscreen
                      transition="dialog-bottom-transition"
                    >
                      <v-card rounded="lg">
                        <v-card-title
                          class="d-flex justify-space-between align-center"
                        >
                          <div class="text-h6 ps-2">
                            <v-icon class="me-2"> mdi-face-agent </v-icon>
                            {{ formTitle }}
                          </div>

                          <v-btn
                            icon="mdi-close"
                            variant="text"
                            @click="close"
                          ></v-btn>
                        </v-card-title>

                        <v-divider class="mb-0"></v-divider>
                        <v-form
                          ref="form"
                          enctype="multipart/form-data"
                          lazy-validation
                        >
                          <v-col cols="12" md="12">
                            <v-card
                              class="mx-auto"
                              :subtitle="address"
                              :title="name"
                            >
                              <template v-slot:prepend>
                                <v-avatar class="my-1" size="50">
                                  <v-img :src="profile"></v-img>
                                </v-avatar>
                              </template>
                              <template v-slot:append>
                                <v-avatar
                                  v-if="status === 'review'"
                                  size="75"
                                  color="orange"
                                >
                                  {{ status }}
                                </v-avatar>
                                <v-avatar v-else size="75" color="primary">
                                  {{ status }}
                                </v-avatar>
                              </template>
                              <v-card-text>
                                <div class="px-4 mb-2">
                                  <v-row class="bg-surface-variant error" no-gutters>
                                    <v-col
                                      class="flex-grow-0 flex-shrink-0"
                                      cols="3"
                                      style="min-width: 100px; max-width: 100%"
                                    >
                                      <v-sheet class="flex-1-0 ma-2 pa-2">
                                        <span style="color: #e65100">
                                          <v-icon
                                            icon="mdi-account"
                                            start
                                          ></v-icon
                                          >Firstname:&nbsp;&nbsp;</span
                                        >
                                        {{ firstName }}
                                      </v-sheet>
                                    </v-col>

                                    <v-col
                                      class="flex-grow-1 flex-shrink-0"
                                      cols="3"
                                      style="min-width: 100px; max-width: 100%"
                                    >
                                      <v-sheet class="ma-2 pa-2">
                                        <span style="color: #e65100"
                                          ><v-icon
                                            icon="mdi-account"
                                            start
                                          ></v-icon
                                          >Lastname:&nbsp;&nbsp;</span
                                        >

                                        {{ lastName }}
                                      </v-sheet>
                                    </v-col>

                                    <v-col
                                      class="flex-grow-0 flex-shrink-1"
                                      cols="3"
                                      style="min-width: 100px"
                                    >
                                      <v-sheet class="ma-2 pa-2">
                                        <span style="color: #e65100"
                                          ><v-icon
                                            icon="mdi-gender-male-female-variant"
                                            start
                                          ></v-icon
                                          >Sex:&nbsp;</span
                                        >
                                        {{ gender }}
                                      </v-sheet>
                                    </v-col>

                                    <v-col
                                      class="flex-grow-0 flex-shrink-1"
                                      cols="3"
                                      style="min-width: 100px"
                                    >
                                      <v-sheet class="ma-2 pa-2">
                                        <span style="color: #e65100"
                                      ><v-icon
                                      icon="mdi-clipboard-text-clock"
                                      start
                                    ></v-icon>DoB:&nbsp;&nbsp;</span
                                    >
                                    
                                    {{ new Date(dateOfBirth).toLocaleString() }}

                                      </v-sheet>
                                    </v-col>

                                    <v-col
                                      class="flex-grow-0 flex-shrink-0"
                                      cols="3"
                                      style="min-width: 100px; max-width: 100%"
                                    >
                                      <v-sheet class="flex-1-0 ma-2 pa-2">
                                        <span style="color: #e65100"
                                      ><v-icon
                                      icon="mdi-phone-in-talk"
                                      start
                                    ></v-icon>Phone:&nbsp;&nbsp;</span
                                    >
                                    
                                    {{ phone }}

                                      </v-sheet>
                                    </v-col>

                                    <v-col
                                      class="flex-grow-1 flex-shrink-0"
                                      cols="3"
                                      style="min-width: 100px; max-width: 100%"
                                    >
                                      <v-sheet class="ma-2 pa-2">
                                        <span style="color: #e65100"
                                      ><v-icon
                                      icon="mdi-card-account-details-outline"
                                      start
                                    ></v-icon>Card No.:&nbsp;&nbsp;</span
                                    >
                                    
                                    {{ cardNumber }}

                                      </v-sheet>
                                    </v-col>

                                    <v-col
                                      class="flex-grow-0 flex-shrink-1"
                                      cols="3"
                                      style="min-width: 100px"
                                    >
                                      <v-sheet class="ma-2 pa-2">
                                        <span style="color: #e65100"
                                      ><v-icon
                                      icon="mdi-card-bulleted-settings-outline"
                                      start
                                    ></v-icon>Card Name:&nbsp;&nbsp;</span
                                    >
                                    
                                    {{ nameCard }}

                                      </v-sheet>
                                    </v-col>

                                    <v-col
                                      class="flex-grow-0 flex-shrink-1"
                                      cols="3"
                                      style="min-width: 100px"
                                    >
                                      <v-sheet class="ma-2 pa-2">
                                        <span style="color: #e65100"
                                      ><v-icon
                                      icon="mdi-flag-outline"
                                      start
                                    ></v-icon>Nationality:&nbsp;&nbsp;</span
                                    >
                                    
                                    {{ nationality }}

                                      </v-sheet>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div class="px-4 mb-2 text-center">
                                  <v-row>
                                    <v-col cols="6">
                                      <v-card
                                        variant="variant"
                                        class="mx-auto"
                                        elevation="16"
                                        subtitle="Front card image"
                                      >
                                        <v-img
                                          :lazy-src="frontCard"
                                          :src="frontCard"
                                          aspect-ratio="1"
                                          contain
                                          height="350"
                                          max-width="auto"
                                        >
                                          <template v-slot:placeholder>
                                            <v-row
                                              align="center"
                                              class="fill-height ma-0"
                                              justify="center"
                                            >
                                              <v-progress-circular
                                                color="grey-lighten-5"
                                                indeterminate
                                              ></v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-card>
                                    </v-col>
                                    <v-col cols="6">
                                      <v-card
                                        variant="variant"
                                        class="mx-auto"
                                        elevation="16"
                                        subtitle="Back card image"
                                      >
                                        <v-img
                                          :lazy-src="backCard"
                                          :src="backCard"
                                          aspect-ratio="1"
                                          height="350"
                                          contain
                                          max-width="auto"
                                        >
                                          <template v-slot:placeholder>
                                            <v-row
                                              align="center"
                                              class="fill-height ma-0"
                                              justify="center"
                                            >
                                              <v-progress-circular
                                                color="grey-lighten-5"
                                                indeterminate
                                              ></v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-card-text>
                              <input type="hidden" v-model="hiddenId" />
                            </v-card>
                          </v-col>
                          <v-divider class="mt-1"></v-divider>
                        </v-form>

                        <v-card-actions class="my-2 d-flex justify-end">
                          <v-spacer></v-spacer>
                          <v-btn
                            class="text-none"
                            color="error"
                            rounded="xl"
                            text="Reject Now"
                            @click="reject"
                            variant="flat"
                          ></v-btn>

                          <v-btn
                            class="text-none"
                            color="primary"
                            rounded="xl"
                            :text="buttonTitle"
                            variant="flat"
                            @click="save"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog
                      v-model="dialogImage"
                      persistent
                      fullscreen
                      transition="dialog-bottom-transition"
                    >
                      <v-card>
                        <v-toolbar>
                          <v-toolbar-title prepend-icon="mdi-form-textbox"
                            >Image Card</v-toolbar-title
                          >

                          <v-btn
                            icon="mdi-close"
                            @click="closeDialgeImage"
                          ></v-btn>
                        </v-toolbar>
                        <v-divider class="mb-0"></v-divider>
                        <v-divider class="mb-0"></v-divider>
                        <v-row>
                          <v-col cols="12">
                            <v-img
                              :lazy-src="frontCard"
                              :src="frontCard"
                              aspect-ratio="1"
                              class="bg-grey-lighten-2"
                              height="85vh"
                              contain
                              max-width="auto"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  align="center"
                                  class="fill-height ma-0"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-col>
                        </v-row>
                        <v-card-actions class="my-2 d-flex justify-end">
                          <v-spacer></v-spacer>
                          <v-btn
                            class="text-none"
                            rounded="xl"
                            text="Cancel"
                            @click="closeDialgeImage"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5"
                          >Are you sure you want to delete this
                          item?</v-card-title
                        >
                        <input type="hidden" v-model="hiddenId" />
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue-darken-1"
                            variant="text"
                            @click="closeDelete"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue-darken-1"
                            variant="text"
                            @click="deleteItemConfirm"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <!-- detect when error  -->
                  <template v-slot:no-data>
                    <v-alert
                      v-if="items.length > 0"
                      :value="true"
                      color="error"
                      icon="warning"
                      class="text-left"
                    >
                      No data found.
                    </v-alert>
                  </template>
                  <!-- end detect when error  -->
                  <!-- Custom column table -->
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                      size="small"
                      v-if="item.status === 'pending'"
                      prepend-icon="mdi-account-clock-outline"
                      color="info"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      size="small"
                      v-if="item.status === 'done'"
                      prepend-icon="mdi-account-check-outline"
                      color="success"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      size="small"
                      v-if="item.status === 'review'"
                      prepend-icon="mdi-account-check-outline"
                      color="warning"
                    >
                      {{ item.status }}
                    </v-chip>
                    <v-chip
                      size="small"
                      v-if="item.status === 'rejected'"
                      prepend-icon="mdi-account-check-outline"
                      color="error"
                    >
                      {{ item.status }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.online`]="{ item }">
                    <v-chip
                      size="small"
                      v-if="item.online === 1"
                      color="success"
                    >
                      <v-icon icon="mdi-access-point"></v-icon>
                    </v-chip>
                    <v-chip size="small" v-if="item.online === 0" color="error">
                      <v-icon icon="mdi-access-point-off"></v-icon>
                    </v-chip>
                  </template>
                  <template v-slot:[`item.profile`]="{ item }">
                    <v-avatar size="64" class="my-1" v-if="item.profile">
                      <v-img :src="path + item.profile" cover></v-img>
                    </v-avatar>
                    <v-avatar
                      class="my-1"
                      v-else
                      image="https://static.thenounproject.com/png/4595376-200.png"
                      size="80"
                    >
                    </v-avatar>

                    <v-span class="ml-2">
                      {{ item.name }}
                    </v-span>
                  </template>
                  <template v-slot:[`item.frontCard`]="{ item }">
                    <v-avatar
                      size="64"
                      class="my-1"
                      v-if="item.frontCard"
                      @click="showDialogueImage(item.frontCard)"
                    >
                      <v-img :src="path + item.frontCard" cover></v-img>
                    </v-avatar>
                    <v-avatar
                      class="my-1"
                      v-else
                      image="https://static.thenounproject.com/png/4595376-200.png"
                      size="80"
                    >
                    </v-avatar>
                  </template>
                  <template v-slot:[`item.backCard`]="{ item }">
                    <v-avatar
                      size="64"
                      class="my-1"
                      v-if="item.backCard"
                      @click="showDialogueImage(item.backCard)"
                    >
                      <v-img :src="path + item.backCard" cover></v-img>
                    </v-avatar>
                    <v-avatar
                      class="my-1"
                      v-else
                      image="https://static.thenounproject.com/png/4595376-200.png"
                      size="80"
                    >
                    </v-avatar>
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ new Date(item.createdAt).toLocaleString() }}
                  </template>
                  <template v-slot:[`item.emailVerifyAt`]="{ item }">
                    {{ new Date(item.emailVerifyAt).toLocaleString() }}
                  </template>
                  <template v-slot:[`item.confirmAt`]="{ item }">
                    {{ new Date(item.confirmAt).toLocaleString() }}
                  </template>
                  <template v-slot:[`item.dateBlockIp`]="{ item }">
                    {{ new Date(item.dateBlockIp).toLocaleString() }}
                  </template>
                  <template v-slot:[`item.dateBlockMacAddress`]="{ item }">
                    {{ new Date(item.dateBlockMacAddress).toLocaleString() }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      v-if="item.status === 'review'"
                      class="ma-2"
                      size="x-small"
                      color="primary"
                      @click="editItem(item)"
                    >
                      <v-icon icon="mdi-eye" start></v-icon>
                      Confirm
                    </v-btn>
                    <v-btn
                      v-else-if="item.status === 'done'"
                      class="ma-2"
                      size="x-small"
                      color="success"
                      disabled
                    >
                      <v-icon icon="mdi-eye-off-outline" start></v-icon>
                      Done
                    </v-btn>
                    <v-btn
                      v-else
                      class="ma-2"
                      size="x-small"
                      color="error"
                      disabled
                    >
                      <v-icon icon="mdi-eye-off-outline" start></v-icon>
                      {{ item.status }}
                    </v-btn>
                    <!--
                    <v-icon
                      v-if="item.status === 'done'"
                      class="me-2"
                      size="large"
                      color="error"
                    >
                      mdi-eye-off-outline
                    </v-icon>
                    
                    <v-icon
                      v-else
                      class="me-2"
                      size="large"
                      color="success"
                      title="Confirm"
                      
                      @click="editItem(item)"
                    >
                      mdi-eye
                    </v-icon> -->
                  </template>
                  <!-- End custom column table -->
                </v-data-table>
              </v-row>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import {
  Path_Customer_Image,
  URL_COUNTRY,
  URL_CUSTOMER_CONFIRMATION,
  URL_UPDATE_CUSTOMER_CONFIRMATION,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";

export default {
  data: () => ({
    path: Path_Customer_Image,
    tab: null,
    snack: false,
    dialog: false,
    dialogImage: false,
    dialogDelete: false,
    visible: false,

    items: [],
    countries: ["-- All --"],
    customerConfirmations: [],

    search: null,
    start: null,
    end: null,
    status: "All",
    snackColor: null,
    snackText: null,
    profile: null,
    name: null,
    address: null,
    phone: null,
    frontCard: null,
    backCard: null,
    cardNumber: null,
    nameCard: null,
    gender: null,
    nationality: null,
    dateOfBirth: null,
    lastName: null,
    firstName: null,
    typeCard: null,
    email: null,

    countryId: 0,
    totalPending: 0,
    totalDone: 0,
    totalReview: 0,
    totalReject: 0,

    editedIndex: -1,
    hiddenId: 0,

    isAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    staffLoginCountryCode: originalCode(parseInt(localStorage.getItem("code"))),
    staffLoginCountryId: originalCode(
      parseInt(localStorage.getItem("countryId"))
    ),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Front Image", key: "frontCard", align: "center" },
      { title: "Back Image", key: "backCard", align: "center" },
      { title: "Account", key: "profile", align: "center" },
      { title: "Country", key: "country", align: "center" },
      { title: "Nationality", key: "nationality", align: "center" },
      { title: "ID Card", key: "cardNumber", align: "center" },
      { title: "ID Name", key: "nameCard", align: "center" },
      { title: "Status", key: "status", align: "center" },
      { title: "online?", key: "online", align: "center" },
      { title: "First Name", key: "firstName", align: "center" },
      { title: "Last Name", key: "lastName", align: "center" },
      { title: "Sex", key: "gender", align: "center" },
      { title: "Email", key: "email", align: "center" },
      { title: "Phone", key: "phone", align: "center" },
      { title: "Register Date", key: "createdAt", align: "center" },
      { title: "Country Code", key: "countryCode", align: "center" },
      { title: "Verify Code", key: "verifyCode", align: "center" },
      { title: "Referal Link", key: "referalLink", align: "center" },
      { title: "My Referal", key: "myReferal", align: "center" },
      { title: "Count Referal", key: "countReferal", align: "center" },
      { title: "Service Agreement", key: "serviceAgreement", align: "center" },
      { title: "Privacy Policy", key: "privacyPolicy", align: "center" },
      { title: "Email Verify At", key: "emailVerifyAt", align: "center" },
      { title: "Confirm At", key: "confirmAt", align: "center" },
      { title: "Condition Claim", key: "conditionClaim", align: "center" },
      { title: "Occupation", key: "occupation", align: "center" },
      { title: "Number Level", key: "numberLevel", align: "center" },
      { title: "Referal", key: "referal", align: "center" },
      { title: "Type Card", key: "typeCard", align: "center" },
      { title: "Expire Date", key: "expireDate", align: "center" },
      { title: "IP", key: "ip", align: "center" },
      { title: "ISP", key: "isp", align: "center" },
      { title: "Longtitude", key: "longtitude", align: "center" },
      { title: "Latitude", key: "latitude", align: "center" },
      { title: "Country Location", key: "countryLocation", align: "center" },
      { title: "Region Name", key: "regionName", align: "center" },
      { title: "State", key: "state", align: "center" },
      { title: "City", key: "city", align: "center" },
      { title: "Postal Code", key: "postalCode", align: "center" },
      { title: "Count Ip Block", key: "countIpBlock", align: "center" },
      { title: "Date Block Ip", key: "dateBlockIp", align: "center" },
      { title: "Mac Address", key: "macAddress", align: "center" },
      { title: "Count Mac Address", key: "countMacAddress", align: "center" },
      {
        title: "Date Block Mac Address",
        key: "dateBlockMacAddress",
        align: "center",
      },
      { title: "Google Id", key: "googleId", align: "center" },
      { title: "Identity", key: "identity", align: "center" },
      { title: "Country Of Birth", key: "countryOfBirth", align: "center" },
      { title: "City Of Birth", key: "cityOfBirth", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Customer Confirmation"
        : "Customer Register Information";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "OK";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogImage(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateCountries();
    this.PopulatecustomerConfirmations();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
    },

    isAdminOrAllCountryPermission() {
      return (this.isAdmin || this.isAllCountry) === 1 ? true : false;
    },

    PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            name: country.name,
            id: country.id,
            code: country.code,
          });
        });

        this.countryId = this.isAdminOrAllCountryPermission()
          ? "-- All --"
          : this.staffLoginCountryId;
      });
    },

    async filterItems() {
      switch (this.tab) {
        case 0:
          this.status = "All";
          break;
        case 1:
          this.status = "pending";
          break;
        case 2:
          this.status = "done";
          break;
        case 3:
          this.status = "review";
          break;
        default:
          this.status = "rejected";
          break;
      }
      
      var codeCountry = this.getCountryCodeByCountryId(this.countryId);

      if (codeCountry === undefined) {
        this.PopulatecustomerConfirmations();
      } else {
        const linkUrl =
          URL_CUSTOMER_CONFIRMATION +
          `?status=${this.status}&countryCode=${codeCountry}`;
        await axios
          .get(linkUrl, {
            headers: TOKEN_HEADERS,
          })
          .then((res) => {
            //this.customerConfirmations = res.data;
            const searchCountryCode = parseInt(codeCountry);

            const startDate = this.start
              ? new Date(this.start).toLocaleDateString()
              : null;
            const endDate = this.end
              ? new Date(this.end).toLocaleDateString()
              : null;

            this.customerConfirmations = res.data.filter((item) => {
              const itemDate = new Date(item.createdAt).toLocaleDateString();
              const itemCountryCode = parseInt(item.countryCode);
              const matchesDateRange =
                (!startDate || itemDate >= startDate) &&
                (!endDate || itemDate <= endDate);
              const matchesCountry =
                !searchCountryCode || itemCountryCode === searchCountryCode;
              //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
              //const matchesSearch = !search || item.name.toLowerCase().includes(search);

              return matchesDateRange && matchesCountry;
            });

            this.items.push(this.customerConfirmations);
            this.getStatistic(this.customerConfirmations);
          });
      }
    },

    getCountryCodeByCountryId(countryId) {
      var codeCountry = this.countries.filter((item) => {
        var code = 0;
        if (countryId !== "-- All --") {
          if (item.id === countryId) {
            code = item.code;
          }
        } else {
          code = this.staffLoginCountryCode;
        }

        return code;
      });

      return codeCountry[0].code;
    },

    async PopulatecustomerConfirmations() {
      var countryCode = this.isAdminOrAllCountryPermission()
        ? null
        : this.staffLoginCountryCode;

      const linkUrl =
        URL_CUSTOMER_CONFIRMATION +
        `?status=${this.status}&countryCode=${countryCode}`;
      await axios
        .get(linkUrl, {
          headers: TOKEN_HEADERS,
        })
        .then((res) => {
          this.customerConfirmations = res.data.filter((item) => {
            const itemCountryCode = parseInt(item.countryCode);

            const matchesCountry =
              !parseInt(countryCode) ||
              itemCountryCode === parseInt(countryCode);
            //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
            //const matchesSearch = !search || item.name.toLowerCase().includes(search);

            return matchesCountry;
          });

          this.items.push(this.customerConfirmations);
          this.getStatistic(this.customerConfirmations);
        });
    },

    getStatistic(items) {
      const pending = items.filter((item) => item.status == "pending").length;
      const done = items.filter((item) => item.status == "done").length;
      const review = items.filter((item) => item.status == "review").length;
      const reject = items.filter((item) => item.status == "rejected").length;

      this.totalPending = formatNumber(pending);
      this.totalDone = formatNumber(done);
      this.totalReject = formatNumber(reject);
      this.totalReview = formatNumber(review);
    },

    showDialogueImage(item) {
      this.frontCard = this.path + item;
      this.dialogImage = true;
    },
    editItem(item) {
      this.editedIndex = this.customerConfirmations.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.name = item.name;
      this.phone = item.phone;
      this.status = item.status;
      this.gender = item.gender === null ? "N/A" : item.gender;
      this.nationality = item.nationality === null ? "N/A" : item.nationality;
      this.dateOfBirth = item.dateOfBirth === null ? "N/A" : item.dateOfBirth;
      this.nameCard = item.nameCard === null ? "N/A" : item.nameCard;
      this.cardNumber =
        item.cardNumber === null ? "xxx xx xx xx" : item.cardNumber;

      this.lastName = item.lastName === null ? "N/A" : item.lastName;
      this.firstName = item.firstName === null ? "N/A" : item.firstName;
      this.address = item.address === null ? "N/A" : item.address;
      this.typeCard = item.typeCard === null ? "N/A" : item.typeCard;
      this.email = item.email === null ? "N/A" : item.email;

      this.profile =
        item.profile === null
          ? "https://png.pngtree.com/png-clipart/20200224/original/pngtree-avatar-icon-profile-icon-member-login-vector-isolated-png-image_5247852.jpg"
          : this.path + item.profile;
      this.frontCard =
        item.frontCard === null
          ? "https://static.thenounproject.com/png/4595376-200.png"
          : this.path + item.frontCard;
      this.backCard =
        item.backCard === null
          ? "https://static.thenounproject.com/png/4595376-200.png"
          : this.path + item.backCard;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.customerConfirmations.indexOf(item);
      this.hiddenId = item.id;

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.customerConfirmations.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_CUSTOMER_CONFIRMATION + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDialgeImage() {
      this.dialogImage = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async reject() {
      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_UPDATE_CUSTOMER_CONFIRMATION + id;

        await axios
          .delete(url, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
          })
          .catch(
            (error) => {
              if (error.response.data.statusCode === 400) {
                this.snakeMessage("error", error.response.data.message);
              } else if (error.request) {
                // The request was made but no response was received
                this.snakeMessage("error", error.request);
                // Something happened in setting up the request that triggered an Error
                this.snakeMessage("error", error.message);
              }
            }
            //console.error("record updated was an error!", error)
          );

        this.close();
      }

      //this.$emit('notifications');
      this.countryId = 0;
      this.clearContent();
      this.PopulatecustomerConfirmations();

      this.dialog = false;
    },

    async save() {
      const data = {
        status: "done",
        confirmAt: new Date(),
        userId: parseInt(originalCode(parseInt(localStorage.getItem("id")))),
      };

      if (this.editedIndex > -1) {
        // edit
        const id = this.hiddenId;
        const url = URL_UPDATE_CUSTOMER_CONFIRMATION + id;

        await axios
          .patch(url, data, { headers: TOKEN_HEADERS })
          .then((response) => {
            this.snakeMessage("success", response.data.message);
          })
          .catch(
            (error) => {
              if (error.response.data.statusCode === 400) {
                this.snakeMessage("error", error.response.data.message);
              } else if (error.request) {
                // The request was made but no response was received
                this.snakeMessage("error", error.request);
                // Something happened in setting up the request that triggered an Error
                this.snakeMessage("error", error.message);
              }
            }
            //console.error("record updated was an error!", error)
          );

        this.close();
      }

      //this.$emit('notifications');
      this.refreshPage();
      this.countryId = 0;
      this.clearContent();
      this.PopulatecustomerConfirmations();

      this.dialog = false;
    },

    refreshPage() {
      window.location.reload();
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    /**
    async populateCustomerConfirmationByTab() {
      switch (this.tab) {
        case 0:
          this.status = "All";
          break;
        case 1:
          this.status = "Pending";
          break;
        case 2:
          this.status = "Done";
          break;
        case 3:
          this.status = "Review";
          break;
        default:
          this.status = "Reject";
          break;
      }
      await axios
        .get(URL_CUSTOMER_CONFIRMATION + this.status, {
          headers: TOKEN_HEADERS,
        })
        .then((res) => {
          //this.customerConfirmations = res.data;
          const startDate = this.start
            ? new Date(this.start).toLocaleDateString()
            : null;
          const endDate = this.end
            ? new Date(this.end).toLocaleDateString()
            : null;

          this.customerConfirmations = res.data.filter((item) => {
            const itemDate = new Date(item.createdAt).toLocaleDateString();
            const matchesDateRange =
              (!startDate || itemDate >= startDate) &&
              (!endDate || itemDate <= endDate);
            //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
            //const matchesSearch = !search || item.name.toLowerCase().includes(search);

            return matchesDateRange;
          });
          this.items.push(this.customerConfirmations);
        });
    },
     */
  },
};
</script>
<style scoped>
@import "vue-select/dist/vue-select.css";

.row-container {
  overflow-y: auto;
}

style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid #d1d5db;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 55px;
  font-size: medium;
  color: #394066;
  max-height: 200px;
  overflow-y: auto;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
