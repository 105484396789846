<script>
import {
  Path_Small_Investment_Image,
  URL_SMALL_INVESTMENT,
} from "@/utils/apiUrl";
import FrontendLayout from "@/views/website/components/FrontendLayout.vue";
import axios from "axios";

export default {
  name: "SmallInvestmentProgramPage",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
      path: Path_Small_Investment_Image,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_SMALL_INVESTMENT)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <v-img min-height="720" width="100%" cover :src="path + data.image">
      <h2 class="text-uppercase text-center text-white">
        {{ data.title }}
      </h2>
    </v-img>

    <div class="bg-grey-lighten-4">
      <br /><br /><br />
      <div class="margin-auto" v-html="data.desc"></div>
      <br /><br /><br />
      <!--
      <div class="margin-auto">
        <p class="text-content text-justify">
          MIGA’s Small Investment Program (SIP) is designed to facilitate
          investments into small and medium-size enterprises involved in the
          finance, agribusiness, manufacturing, and services sectors. The
          program offers a streamlined approval process, allowing investors to
          get MIGA coverage in place quickly.
        </p>

        <p class="text-content text-justify">
          For an investment to be eligible for MIGA coverage, the project
          enterprise must fulfill two of the following three criteria:
        </p>

        <ul class="pa-6 text-content">
          <li>no more than 300 employees</li>
          <li>total assets not more than $15 million</li>
          <li>total annual sales not more than $15 million</li>
        </ul>

        <p class="text-content text-justify">
          Although the total size of the investment may be larger than $10
          million, the application for investment guarantee must be $10 million
          or less. SIP covers up to 90 percent of the investment for equity and
          up to 95 percent for debt. SIP guarantees have a term of up to 10
          years (three years minimum), with the possibility of an extension, at
          the end of the original term, of up to five years at MIGA's
          discretion. Investments must also meet the basic eligibility
          requirements under MIGA’s regular guarantee program. MIGA’s
          environmental and social performance standards also apply to projects
          under the SIP. Category A projects may not be covered under the SIP.
          MIGA’s regular application procedures apply to the SIP program. The
          approval process should not take longer than eight weeks if the
          information requested in the definitive application is fully completed
          and MIGA is supplied with all relevant project documentation. Once the
          guarantee proposal has been approved, the investor will receive a
          contract of guarantee, and on receipt, will have a period of six weeks
          to sign the contract. If the investor decides not to go ahead within
          this period, MIGA will terminate the guarantee process. To avoid
          delays in the application process, business and financial plans should
          be in place and financial projections available prior to filing the
          definitive application.
        </p>

        <br /><br /><br />
      </div> -->
    </div>
  </FrontendLayout>
</template>

<style scoped></style>
