<template>
  <div>
    <h4 class="text-grey">View History by Selecting Date</h4>
    <v-divider></v-divider>
    <br />
    <v-row>
      <v-col md="4" cols="12">
        <v-row>
          <v-col cols="3" md="2">
            <p style="padding-top: 5px">Show</p>
          </v-col>

          <v-col cols="6" md="4">
            <v-select
              rounded="xl"
              :items="[10, 20, 50, 100, 200, 'all']"
              v-model="value"
              density="compact"
              variant="solo"
              menu-icon=""
            ></v-select>
          </v-col>
          <v-col cols="3" md="2">
            <p style="padding-top: 5px">Entry</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4" cols="12">
        <v-autocomplete
          rounded="xl"
          placeholder="All Referrals"
          density="compact"
          variant="solo"
          :items="['All', 'pending', 'reject', 'done']"
        >
        </v-autocomplete>
      </v-col>

      <v-col md="4" cols="12">
        <v-text-field
          class="elevation-0"
          rounded="xl"
          placeholder="YYYY/MM/DD - YYYY-MM-DD"
          density="compact"
          variant="solo"
        >
        </v-text-field>

        <!--          <v-row justify="space-around">-->
        <!--          <v-date-picker show-adjacent-months></v-date-picker>-->
        <!--        </v-row>-->
      </v-col>
    </v-row>

    <br />

    <v-table density="compact" hover>
      <thead>
        <tr class="bg-primary">
          <th class="text-left">Date</th>
          <th class="text-center">Time</th>
          <th class="text-center">Referral</th>
          <th class="text-center">Phone</th>
          <th class="text-center">Referral Level</th>
          <th class="text-center">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(level, index) in transactions"
          v-bind:key="index"
          :style="'background:' + (index % 2 == 1 ? '#E3F2FD' : 'white')"
        >
          <td class="td">
            {{ formatDate(level.date) }}
          </td>
          <td class="td text-center">
            {{ formatDate(level.date, "HH:mm:ss") }} UTC
          </td>
          <td class="td text-center">
            {{ level.referral }}
          </td>
          <td class="td text-center">
            {{ level.phone }}
          </td>
          <td class="td text-center">
            {{ level.level }}
          </td>
          <td class="td text-center">
            {{ level.status }}
          </td>
        </tr>
      </tbody>
    </v-table>

    <div class="text-center">
      <v-pagination
        size="small"
        rounded="circle"
        v-model="page"
        :length="15"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import { formatPrice } from "../../../../../utils/function";
import moment from "moment";

export default {
  name: "ListReferrals",
  data() {
    return {
      formatPrice: formatPrice,
      page: 0,
      value: 10,
      transactions: [
        {
          id: 1,
          amount: 1000,
          currency: "$",
          date: "2024-12-12 12:10:10",
          level: "Level 1",
          referral: "10121212",
          phone: "09827233",
          status: "Pending",
        },
        {
          id: 2,
          amount: 100,
          currency: "$",
          date: "2024-04-12 12:10:10",
          level: "Level 2",
          referral: "10121212",
          phone: "09827233",
          status: "Pending",
        },
        {
          id: 3,
          amount: 100,
          currency: "$",
          date: "2024-12-12 12:10:10",
          level: "Star 2",
          referral: "10121212",
          phone: "09827233",
          status: "Pending",
        },
        {
          id: 3,
          amount: 100,
          currency: "$",
          date: "2024-12-12 12:10:10",
          level: "Star 2",
          referral: "10121212",
          phone: "09827233",
          status: "Pending",
        },
      ],
    };
  },
  methods: {
    formatDate(date, format = "YYYY-MM-DD") {
      return moment(date).format(format);
    },
  },
};
</script>
<style scoped>
th,
td {
  white-space: nowrap;
}
</style>
