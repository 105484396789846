import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/dashboard/Dashboard";
import User from "@/views/users/User";
import Setting from "@/views/setting/Setting";
import HomePage from "@/views/website/home/HomePage";
import LoginPage from "@/views/website/auth/LoginPage";
import ContactUs from "@/views/website/contact-us/ContactUs";
import AboutUs from "@/views/website/about-us/AboutUs";
import ProjectPage from "@/views/website/projects/ProjectPage";
import OutProcessPage from "@/views/website/our-process/OutProcessPage";
import SignInPage from "@/views/website/auth/SignInPage";
import RequestBox from "@/views/request-box/RequestBox";
import MediaPage from "@/views/medai/MediaPage";
import ProfilePage from "@/views/website/profile/ProfilePage";
import FinanceContractPage from "@/views/finances/FinanceContract";
import CustomerServicePage from "@/views/customer-service/CustomerService";
import PageNotFound from "@/views/website-error/PageNotFound";
import SomethingWentWrongPage from "@/views/website-error/SomethingWentWrongPage";
import StaffLoginForm from "@/views/auth-staff/StaffLoginForm";
import { useAuthStore } from "@/store/staffAuth";
import StaffPermission from "@/views/users/StaffPermission";
import ViewStaffLogin from "@/views/users/ViewStaffLogin";
import SectorPage from "@/views/website/profile/SectorPage";
import ExchangeDepositPage from "@/views/finances/ExchangeDeposit";
import ExchangeWithdrawPage from "@/views/finances/ExchangeWithdraw";
import CountryPage from "@/views/setting/CountryPage";
import FeePage from "@/views/finances/FeePage";
import TotalReportPage from "@/views/finances/TotalReportPage";
import SocialMediaPage from "@/views/setting/SocialMediaPage";
import LoginForm from "@/views/website/auth/LoginForm";
import UpdateProfilePage from "@/views/users/UpdateProfile";
import ChangePasswordPage from "@/views/auth-staff/ChangePassword";
import BlockWithdrawPage from "@/views/finances/BlockWithdrawPage";
import ForgetPasswordPage from "@/views/website/auth/ForgetPasswordPage";
import BankTypePage from "@/views/bank/BankTypePage";
import BankAccountDepositPage from "@/views/bank/BankAccountDepositPage";
import FeeAccountBallancePage from "@/views/finances/FeeAccountBallancePage";
import CustomerPage from "@/views/customer-service/CustomerPage";
import CustomerLoginPage from "@/views/customer-service/CustomerLoginPage";
import CompensationPage from "@/views/compansation/CompensationPage";
import StarRankReferalPage from "@/views/finances/StarRankReferalPage";
import AddMoneyClaimLevelPage from "@/views/finances/AddMoneyClaimLevelPage";
import CompanyPage from "@/views/setting/CompanyPage";
import ProjectTitlePage from "@/views/project-title/ProjectTitlePage";
import AboutUsPage from "@/views/about-us/AboutUsPage";
import ThreeLevelClaimPage from "@/views/three-level-claim/ThreeLevelClaimPage";
import ProjectBackendPage from "@/views/project/ProjectPage";
import ProjectDetailsPage from '@/views/website/projects/ProjectDetailsPage.vue';
import AsiaPacificPage from "@/views/asia-pacific/AsiaPacificPage";
import ProjectEventPage from "@/views/project-event/ProjectEventPage";
import ContactUsPage from "@/views/contact-us/ContactUsPage";
import CurrencyPage from "@/views/currency/CurrencyPage";
import OurImpactPage from '@/views/website/our-process/OurImpactPage.vue';
import AsiaPacificWebPage from '@/views/website/our-process/AsiaPacificWebPage.vue';
import SmallInvestmentProgramPage from '@/views/website/our-process/SmallInvestmentProgramPage.vue';
import CurrencyWebPage from '@/views/website/our-process/CurrencyWebPage.vue';
import UkrainePage from '@/views/website/ukraine/UkrainePage.vue';
import PoliticalRiskInsurancePage from '@/views/website/about-us/PoliticalRiskInsurancePage.vue';
import AnnualReportPage from '@/views/website/about-us/AnnualReportPage.vue';
import CovidPage from '@/views/website/covid/CovidPage.vue';
import CovidDetailsPage from '@/views/website/covid/CovidDetailsPage.vue';
import CovidBackEndPage from "@/views/covid19/CovidPage";
import OurImpactBackendPage from "@/views/our-impact/OurImpactPage";
import SmallInvestmentPage from "@/views/small-investment/SmallInvestmentPage";
import TermPrivacyPage from "@/views/term-privacy/TermPrivacyPage";
import PoliticalRiskInsuranceBackendPage from "@/views/political-risk-insurance/PoliticalRiskInsurancePage";
import EventsPage from '@/views/website/about-us/EventsPage.vue';
import EventsDetailsPage from '@/views/website/about-us/EventsDetailsPage.vue';
import ClosingSoonPage from '@/views/website/projects/ClosingSoonPage.vue';
import CrowdfundingPage from '@/views/website/projects/CrowdfundingPage.vue';
import FeaturedProjectsPage from '@/views/website/projects/FeaturedProjectsPage.vue';
import NewestProjectPage from '@/views/website/projects/NewestProjectPage.vue';
import FeedbackAdminPage from "@/views/feedback-admin/FeedbackAdminPage";

const routes = [
  // user
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: { requiredAuth: false },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
    meta: { requiredAuth: false },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    meta: { requiredAuth: false },
  },
  {
    path: "/projects",
    name: "ProjectPage",
    component: ProjectPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/project-details",
    name: "ProjectDetailsPage",
    component: ProjectDetailsPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/project-title",
    name: "ProjectTitlePage",
    component: ProjectTitlePage,
    meta: { requiredAuth: false },
  },
  {
    path: "/project-backend",
    name: "ProjectBackendPage",
    component: ProjectBackendPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/asia-pacific",
    name: "AsiaPacificPage",
    component: AsiaPacificPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/project-event",
    name: "ProjectEventPage",
    component: ProjectEventPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/our-process",
    name: "OutProcessPage",
    component: OutProcessPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: { requiredAuth: true },
  },

  {
    path: "/sign-in",
    name: "LoginForm",
    component: LoginForm,
    meta: { requiredAuth: false },
  },

  {
    path: "/forget-password",
    name: "ForgetPasswordPage",
    component: ForgetPasswordPage,
    meta: { requiredAuth: false },
  },

  // admin
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiredAuth: true }, //true: mean that if token is not null it will stay on that page, but else it will redirect to login page
  },
  {
    path: "/staff",
    name: "User",
    component: User,
    meta: { requiredAuth: true },
  },
  {
    path: "/request-box",
    name: "RequestBox",
    component: RequestBox,
    meta: { requiredAuth: false },
  },

  {
    path: "/media",
    name: "MediaPage",
    component: MediaPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    meta: { requiredAuth: true },
  },
  {
    path: "/staff-login",
    name: "StaffLoginPage",
    component: StaffLoginForm,
    meta: { requiredAuth: false },
  },
  {
    path: "/staff-permission",
    name: "StaffPermission",
    component: StaffPermission,
    meta: { requiredAuth: true },
  },
  {
    path: "/view-staff-login",
    name: "ViewStaffLogin",
    component: ViewStaffLogin,
    meta: { requiredAuth: true },
  },
  {
    path: "/country",
    name: "CountryPage",
    component: CountryPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/social-media",
    name: "SocialMediaPage",
    component: SocialMediaPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/company",
    name: "CompanyPage",
    component: CompanyPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/backend-about-us",
    name: "AboutUsPage",
    component: AboutUsPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/backend-contact-us",
    name: "ContactUsPage",
    component: ContactUsPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/currency",
    name: "CurrencyPage",
    component: CurrencyPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/backend-covid",
    name: "CovidBackEndPage",
    component: CovidBackEndPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/impact",
    name: "OurImpactBackendPage",
    component: OurImpactBackendPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/small-investment",
    name: "SmallInvestmentPage",
    component: SmallInvestmentPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/term-privacy",
    name: "TermPrivacyPage",
    component: TermPrivacyPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/political-risk-insurance",
    name: "PoliticalRiskInsuranceBackendPage",
    component: PoliticalRiskInsuranceBackendPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/feedback",
    name: "FeedbackAdminPage",
    component: FeedbackAdminPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/sign-up",
    name: "LoginPage",
    component: LoginPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/sign-up-with-phone",
    name: "SignInPage",
    component: SignInPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/finance-contract",
    name: "FinanceContractPage",
    component: FinanceContractPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/block-withdraw",
    name: "BlockWithdrawPage",
    component: BlockWithdrawPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/exchange-deposit",
    name: "ExchangeDeposit",
    component: ExchangeDepositPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/exchange-withdraw",
    name: "ExchangeWithdrawPage",
    component: ExchangeWithdrawPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/fee",
    name: "FeePage",
    component: FeePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/total-report",
    name: "TotalReportPage",
    component: TotalReportPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/star-rank-referal",
    name: "StarRankReferalPage",
    component: StarRankReferalPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/money-claim-level",
    name: "AddMoneyClaimLevelPage",
    component: AddMoneyClaimLevelPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/three-level-claim",
    name: "ThreeLevelClaimPage",
    component: ThreeLevelClaimPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/customer-confirmation",
    name: "CustomerServicePage",
    component: CustomerServicePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/customer",
    name: "CustomerPage",
    component: CustomerPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/customer-login",
    name: "CustomerLoginPage",
    component: CustomerLoginPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: PageNotFound,
    meta: { requiredAuth: false },
  },
  {
    path: "/something-went-wrong",
    name: "SomethingWentWrongPage",
    component: SomethingWentWrongPage,
    meta: { requiredAuth: false },
  },
  {
    path: "/sector",
    name: "Sector",
    component: SectorPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/compensation",
    name: "CompensationPage",
    component: CompensationPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/staff/update/profile",
    name: "UpdateProfilePage",
    component: UpdateProfilePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/change-password",
    name: "ChangePasswordPage",
    component: ChangePasswordPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/bank-type",
    name: "BankTypePage",
    component: BankTypePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/bank-account-deposit",
    name: "BankAccountDeposit",
    component: BankAccountDepositPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/fee-account-ballance",
    name: "FeeAccountBallancePage",
    component: FeeAccountBallancePage,
    meta: { requiredAuth: true },
  },

  // client routers
  {
    path: "/our-impact",
    name: "OurImpactPage",
    component: OurImpactPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/asia-pacific-web",
    name: "AsiaPacificWebPage",
    component: AsiaPacificWebPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/small-investment-program",
    name: "SmallInvestmentProgramPage",
    component: SmallInvestmentProgramPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/currency-web",
    name: "CurrencyWebPage",
    component: CurrencyWebPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/ukraine",
    name: "UkrainePage",
    component: UkrainePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/political-risk-insurances",
    name: "PoliticalRiskInsurancePage",
    component: PoliticalRiskInsurancePage,
    meta: { requiredAuth: true },
  },
  {
    path: "/annual-report",
    name: "AnnualReportPage",
    component: AnnualReportPage,
    meta: { requiredAuth: true },
  },
  {
    path: "/covid",
    name: "CovidPage",
    component: CovidPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/covid-details",
    name: "CovidDetailsPage",
    component: CovidDetailsPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/events",
    name: "EventsPage",
    component: EventsPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/events-details",
    name: "EventsDetailsPage",
    component: EventsDetailsPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/closing-soon",
    name: "ClosingSoonPage",
    component: ClosingSoonPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/crowd-funding",
    name: "CrowdfundingPage",
    component: CrowdfundingPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/feature-projects",
    name: "FeaturedProjectsPage",
    component: FeaturedProjectsPage,
    meta: { requiredAuth: true },
  },

  {
    path: "/newest-project",
    name: "NewestProjectPage",
    component: NewestProjectPage,
    meta: { requiredAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.path !== "/staff-login" && to.meta.requiredAuth && !authStore.token) {
    next("/staff-login");
  } else if (
    from.path === "/staff-login" &&
    to.meta.requiredAuth &&
    authStore.token
  ) {
    next();
  } else {
    next();
  }
});

export default router;
