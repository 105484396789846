<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        :location="$vuetify.display.mobile ? 'left' : undefined"
        permanent
        color="#061437"
        :rail="rail"
      >
        <v-toolbar-title class="mt-5"> </v-toolbar-title>

        <v-list density="compact" nav dense>
          <div v-for="item in items" :key="item.title">
            <v-list-item
              v-if="!item.subLinks"
              :to="item.url"
              :active-class="color"
              avatar
              class="v-list-item"
              :prepend-icon="item.icon"
              :title="item.title"
            >
            </v-list-item>

            <v-list-group
              v-else
              :key="item.subLinks"
              :prepend-icon="item.icon"
              :value="item.title"
              no-action
            >
              <template v-slot:activator="{ props }">
                <v-div v-if="isAdmin">
                  <v-list-item
                    :active-class="color"
                    v-bind="props"
                    avatar
                    class="v-list-item"
                    :prepend-icon="item.icon"
                    :title="item.title"
                  >
                  </v-list-item>
                </v-div>
                <v-div v-else>
                  <v-list-item
                    :active-class="color"
                    v-bind="props"
                    avatar
                    class="v-list-item"
                    :prepend-icon="item.icon"
                    :title="item.title"
                    v-if="project === item.title"
                  >
                  </v-list-item>
                  <v-list-item
                    :active-class="color"
                    v-bind="props"
                    avatar
                    class="v-list-item"
                    :prepend-icon="item.icon"
                    :title="item.title"
                    v-if="settings === item.title"
                  >
                  </v-list-item>
                  <v-list-item
                    :active-class="color"
                    v-bind="props"
                    avatar
                    class="v-list-item"
                    :prepend-icon="item.icon"
                    :title="item.title"
                    v-if="finance === item.title"
                  >
                  </v-list-item>
                  <v-list-item
                    :active-class="color"
                    v-bind="props"
                    avatar
                    class="v-list-item"
                    :prepend-icon="item.icon"
                    :title="item.title"
                    v-if="customerService === item.title"
                  >
                  </v-list-item>
                  <v-list-item
                    :active-class="color"
                    v-bind="props"
                    avatar
                    class="v-list-item"
                    :prepend-icon="item.icon"
                    :title="item.title"
                    v-if="report === item.title"
                  >
                  </v-list-item>
                </v-div>
              </template>

              <v-list-item
                v-for="sublink in item.subLinks"
                :key="sublink.title"
                :to="sublink.url"
                :title="sublink.title"
              >
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-app-bar color="white" class="elevation-0">
          <v-app-bar-nav-icon
            variant="text"
            @click.stop="rail = !rail"
          ></v-app-bar-nav-icon>

          <v-toolbar-title> </v-toolbar-title>

          <v-spacer></v-spacer>

          <template v-if="$vuetify.display.mobileBreakpoint">
            <!-- <v-btn icon="mdi-fullscreen" variant="text" size="50"></v-btn> -->

            <div class="text-center">
              <v-btn
                class="text-none"
                stacked
                v-show="admin === 1"
              >
                <v-badge color="error" :content="totalNotification">
                  <v-menu v-model="notification">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props">mdi-bell-outline</v-icon>
                    </template>
                    <v-list v-if="totalNotification > 0">
                      <v-list-item
                        v-for="(item, index) in itemsNotification"
                        :key="index"
                        :value="index"
                      >
                        <v-list>
                          <v-list-item
                            :prepend-avatar="path + profileImage"
                            :subtitle="
                              item.status === 'pending'
                                ? 'Has been registered'
                                : item.status === 'review'
                                ? 'Has been uploaded (KTC)'
                                : item.status === 'rejected'
                                ? 'Has been rejected'
                                : 'Has been approved'
                            "
                            :title="item.name"
                            @click="linkParam('Pending')"
                          >
                            <div
                              class="text-button primary font-weight-thin my-1 pr-4"
                              style="font-size: x-small !important"
                            >
                              {{ new Date(item.createdAt).toLocaleString() }}
                            </div>
                            <template v-slot:append>
                              <v-btn
                                :class="fav ? 'text-none text-error' : ''"
                                min-width="5"
                                variant="plain"
                                stacked
                                :icon="
                                  item.status === 'rejected'
                                    ? 'mdi-close-circle-outline'
                                    : item.status === 'done'
                                    ? 'mdi-close-circle-outline'
                                    : 'mdi-close-circle-outline'
                                "
                                
                                @click="clearNotificationOneByOne(item)"
                              ></v-btn>
                              <!-- 
                              <v-btn
                                :class="fav ? 'text-grey' : ''"
                                :icon="
                                  item.status === 'Pending'
                                    ? 'mdi-account-clock-outline'
                                    : 'mdi-account-eye'
                                "
                                variant="text"
                              ></v-btn> -->
                            </template>
                          </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                      </v-list-item>
                      <div class="pa-4 text-end">
                        <v-btn
                          class="text-none"
                          color="error"
                          size="small"
                          min-width="92"
                          variant="outlined"
                          rounded
                          @click="clearAllNotification"
                        >
                          Clear
                        </v-btn>
                      </div>
                    </v-list>
                  </v-menu>
                </v-badge>
              </v-btn>
            </div>
            <v-btn
              class="text-capitalize pa-1"
              variant="text"
              @click="linkUrl('/staff/update/profile')"
            >
              <v-avatar size="30" class="mx-3">
                <v-img
                  :alt="staffName"
                  :src="
                    profileImage === null
                      ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                      : path + profileImage
                  "
                >
                  <template v-slot:placeholder>
                    <v-row
                      align="center"
                      class="fill-height ma-0"
                      justify="center"
                    >
                      <v-progress-circular
                        color="grey-lighten-5"
                        indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                :class="
                  $vuetify.display.mobile
                    ? 'text-caption font-weight-light'
                    : undefined
                "
              >
                {{ staffName }}
              </span>
            </v-btn>

            <div class="text-center">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                location="end"
              >
                <template v-slot:activator="{ props }">
                  <v-btn color="indigo" v-bind="props" icon="mdi-dots-vertical">
                  </v-btn>
                </template>

                <v-card min-width="300">
                  <v-list>
                    <v-list-item
                      :prepend-avatar="
                        profileImage === null
                          ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                          : path + profileImage
                      "
                      :title="staffName"
                      @click="linkUrl('/staff/update/profile')"
                    >
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item>
                      <v-switch
                        v-model="message"
                        color="purple"
                        label="Change Password"
                        hide-details
                        @click="linkUrl('/change-password')"
                      ></v-switch>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      class="text-capitalize"
                      color="primary"
                      variant="text"
                      @click="linkTo('/staff-login')"
                      block
                    >
                      Log out
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </template>
        </v-app-bar>

        <div class="mx-4">
          <slot></slot>
        </div>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script>
import { useAuthStore } from "@/store/staffAuth";
import axios from "axios";
import {
  Path_Staff_Image,
  URL_CLEAR_NOTIFICATION,
  URL_CUSTOMER_TOTAL,
  URL_STAFF_LOGIN_PERMISSION,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { originalCode, routedAliasCode } from "@/utils/formatNumber";

const navigationAdmin = [
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    url: "/dashboard",
  },
  {
    title: "Projects",
    icon: "mdi-chart-box-outline",
    //url: "/request-box",
    url: "",
    subLinks: [
      {
        title: "Sector",
        icon: "mdi-menu-right-outline",
        url: "/sector",
      },
      {
        title: "Compensation",
        icon: "mdi-menu-right-outline",
        url: "/compensation",
      },
      {
        title: "Project",
        icon: "mdi-menu-right-outline",
        url: "/project-backend",
      },
    ],
  },
  {
    title: "Finances",
    icon: "mdi-finance",
    //url: "/finance",
    url: "",
    subLinks: [
      {
        title: "Contracts",
        icon: "mdi-menu-right-outline",
        url: "/finance-contract",
      },
      {
        title: "Exchange Deposit",
        icon: "mdi-menu-right-outline",
        url: "/exchange-deposit",
      },
      {
        title: "Exchange Withdraw",
        icon: "mdi-menu-right-outline",
        url: "/exchange-withdraw",
      },
      {
        title: "Block Withdraw",
        icon: "mdi-menu-right-outline",
        url: "/block-withdraw",
      },
      {
        title: "Fee",
        icon: "mdi-menu-right-outline",
        url: "/fee",
      },
      {
        title: "Total Report",
        icon: "mdi-menu-right-outline",
        url: "/total-report",
      },
      {
        title: "Bank Type",
        icon: "mdi-menu-right-outline",
        url: "/bank-type",
      },
      {
        title: "Bank Account Deposit",
        icon: "mdi-menu-right-outline",
        url: "/bank-account-deposit",
      },
      {
        title: "Fee Account Ballance",
        icon: "mdi-menu-right-outline",
        url: "/fee-account-ballance",
      },
      {
        title: "Star Rank Referal",
        icon: "mdi-menu-right-outline",
        url: "/star-rank-referal",
      },
      {
        title: "Add Money Claim Level",
        icon: "mdi-menu-right-outline",
        url: "/money-claim-level",
      },
      {
        title: "Add Three  Level Claim",
        icon: "mdi-menu-right-outline",
        url: "/three-level-claim",
      },
    ],
  },
  {
    title: "Customer Services",
    icon: "mdi-face-agent",
    //url: "/customer-service",
    url: "",
    subLinks: [
      {
        title: "Customer Confirmation",
        icon: "mdi-menu-right-outline",
        url: "/customer-confirmation",
      },
      {
        title: "Customer",
        icon: "mdi-menu-right-outline",
        url: "/customer",
      },
      {
        title: "Customer Login",
        icon: "mdi-menu-right-outline",
        url: "/customer-login",
      },
    ],
  },
  {
    title: "Reports",
    icon: "mdi-chart-line",
    //url: "/customer-service",
    url: "",
    subLinks: []
  },
  {
    title: "Settings",
    icon: "mdi-cog",
    //url: '/request-box'
    url: "",
    subLinks: [
      {
        title: "Staffs",
        icon: "mdi-menu-right-outline",
        url: "/staff",
      },
      {
        title: "Staff Permission",
        icon: "mdi-menu-right-outline",
        url: "/staff-permission",
      },
      {
        title: "View Staff Login",
        icon: "mdi-menu-right-outline",
        url: "/view-staff-login",
      },
      {
        title: "Country",
        icon: "mdi-menu-right-outline",
        url: "/country",
      },
      {
        title: "Social Media",
        icon: "mdi-menu-right-outline",
        url: "/social-media",
      },
      {
        title: "Company",
        icon: "mdi-menu-right-outline",
        url: "/company",
      },
      {
        title: "About Us",
        icon: "mdi-menu-right-outline",
        url: "/backend-about-us",
      },
      {
        title: "Contact Us",
        icon: "mdi-menu-right-outline",
        url: "/backend-contact-us",
      },
      {
        title: "Currency",
        icon: "mdi-menu-right-outline",
        url: "/currency",
      },
      {
        title: "Project Title",
        icon: "mdi-menu-right-outline",
        url: "/project-title",
      },
      {
        title: "Asia Pacific",
        icon: "mdi-menu-right-outline",
        url: "/asia-pacific",
      },
      {
        title: "Project Event",
        icon: "mdi-menu-right-outline",
        url: "/project-event",
      },
      {
        title: "Covid",
        icon: "mdi-menu-right-outline",
        url: "/backend-covid",
      },
      {
        title: "Our Impact",
        icon: "mdi-menu-right-outline",
        url: "/impact",
      },
      {
        title: "Small Investment",
        icon: "mdi-menu-right-outline",
        url: "/small-investment",
      },
      {
        title: "Term Privacy",
        icon: "mdi-menu-right-outline",
        url: "/term-privacy",
      },
      {
        title: "Political Risk Insurance",
        icon: "mdi-menu-right-outline",
        url: "/political-risk-insurance",
      },
      {
        title: "Feedback Admin",
        icon: "mdi-menu-right-outline",
        url: "/feedback",
      },
    ],
  },
];

export default {
  name: "MainLayout",
  data: () => ({
    drawer: true,
    group: null,
    items: [],
    rail: false,
    fav: true,
    menu: false,
    notification: false,
    message: false,
    hints: true,
    totalNotification: 0,
    itemsNotification: [],

    staffId: parseInt(localStorage.getItem("id")),
    isStaffLoginAllCountry: originalCode(
      parseInt(localStorage.getItem("allCountry"))
    ),
    isStaffLoginAdmin: originalCode(parseInt(localStorage.getItem("admin"))),

    admin: null,
    customerService: null,
    project: null,
    report: null,
    settings: null,
    finance: null,
    isAdmin: false,
    isCustomerService: null,
    path: Path_Staff_Image,
    profileImage: localStorage.getItem("img"),
  }),
  computed: {
    staffName() {
      const authStore = useAuthStore();
      return authStore.staffName;
    },
  },
  created() {
    this.GetPermissionStaffLogin();
    this.notifications();
  },
  methods: {
    linkTo(path) {
      const authStore = useAuthStore();
      authStore.logout(originalCode(authStore.id));
      this.$router.push(path);
    },
    linkUrl(path) {
      this.$router.push(path);
    },
    linkParam(status) {
      this.$router.push({
        name: "CustomerServicePage",
        params: { status: status },
      });
    },
    async notifications() {
      await axios
        .get(URL_CUSTOMER_TOTAL, {
          headers: TOKEN_HEADERS,
        })
        .then((res) => {
          this.itemsNotification = res.data;
          this.totalNotification = res.data.length;
        });
    },
    async GetPermissionStaffLogin() {
      await axios
        .get(URL_STAFF_LOGIN_PERMISSION + this.staffId, {
          headers: TOKEN_HEADERS,
        })
        .then((response) => {
          if (response.data != null) {
            this.admin = response.data.admin;
            this.isCustomerService = response.data.customerService;
            
            this.project = response.data.project == 1 ? "Projects" : "";
            this.report = response.data.report == 1 ? "Reports" : "";
            this.settings = response.data.setting == 1 ? "Settings" : "";
            this.finance = response.data.finance == 1 ? "Finances" : "";
            this.customerService =
              response.data.customerService == 1 ? "Customer Services" : "";
            this.isAdmin = response.data.admin == 1 ? true : false;
            this.items = navigationAdmin;

            var allCountry = response.data.allCountry;
            this.checkPermissionStaffLoginUpdateToAdminOrAllCountry(
              this.admin,
              allCountry
            );
          } else {
            this.$router.push({ path: "/staff/update/profile" });
          }
        });
    },

    checkPermissionStaffLoginUpdateToAdminOrAllCountry(isAdmin, isAllCountry) {
      if (
        isAdmin !== this.isStaffLoginAdmin ||
        isAllCountry !== this.isStaffLoginAllCountry
      ) {
        localStorage.setItem("allCountry", parseInt(isAllCountry) + routedAliasCode());
        localStorage.setItem("admin", parseInt(isAdmin) + routedAliasCode());
      }
    },

    async clearNotificationOneByOne(notification) {
      const id = parseInt(notification.id);
      await axios
        .patch(URL_CLEAR_NOTIFICATION + id + "/OneByOne", {
          headers: TOKEN_HEADERS,
        })
        .then(() => {
          this.notifications();
        })
        .catch(
          (error) => {
            if (error.response.data.statusCode === 400) {
              console.log(error.response.data.message);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              // Something happened in setting up the request that triggered an Error
              console.log(error.message);
            }
          }
          //console.error("record updated was an error!", error)
        );
    },
    async clearAllNotification() {
      await axios
        .patch(URL_CLEAR_NOTIFICATION + 0 + "/all", { headers: TOKEN_HEADERS })
        .then(() => {
          this.notifications();
        })
        .catch(
          (error) => {
            if (error.response.data.statusCode === 400) {
              console.log(error.response.data.message);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
              // Something happened in setting up the request that triggered an Error
              console.log(error.message);
            }
          }
          //console.error("record updated was an error!", error)
        );
    },
  },

  watch: {
    group() {
      this.rail = false;
    },
  },
};
</script>

<style scoped>
.v-list-item-title {
  white-space: wrap !important;
}
</style>
