<script>
import MainLayout from "../../components/MainLayout";
import Contract from "@/components/finance-contract/ContractComponent";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FinanceContractPage",
  components: { MainLayout, Contract },
};
</script>

<template>
  <MainLayout>
    <Contract></Contract>
  </MainLayout>
</template>
