<script>

export default {
  name: 'ValueOfContacts',
  data() {
    return {};
  },
};
</script>

<template>
  <VCard
    min-height="600"
    width="100%"
    style="background: linear-gradient(0deg, #1867c0 50%, white 50%)"
    class="d-flex justify-center"
  >
    <VCard rounded="xl" style="margin: 60px 0" min-height="300" color="grey-lighten-5">
      <br />
      <VRow class="ma-2">
        <VCol cols="12" md="7">
          <h3 class="text-center">Value of Contracts by Procurement Category</h3>
          <br />
          <div class="text-center">
            <v-btn elevation="0" color="blue" icon="mdi-calendars" size="300">
              <v-btn
                elevation="0"
                color="white"
                icon="mdi-calendars"
                size="150"
              ></v-btn>
            </v-btn>
          </div>
        </VCol>

        <VCol cols="12" md="5">
          <VCard color="white" class="pa-5" elevation="0" rounded="xl">
            <h2>Contact</h2>
            <p>
              Investment Project Financing Portfolio
              <VIcon>mdi-help-circle-outline</VIcon>
            </p>
            <br />
            <p class="text-end">
              Number of Contact
            </p>
            <div class="text-end">
              <v-chip label color="grey">
                <h3 class="text-blue"> 123,434</h3>
              </v-chip>
            </div>
            <br />
            <p class="text-end">
              Value of Contact
            </p>
            <div class="text-end">
              <v-chip label color="grey">
                <h3 class="text-blue"> $ 123,434</h3>
              </v-chip>
            </div>
          </VCard>

          <br/>

          <div class="d-flex align-content-start">
            <v-avatar color="white" size="18" class="mt-1">
              <v-avatar color="indigo-lighten-5" size="14">
              </v-avatar>
            </v-avatar>
            <h3 class="mx-2 text-grey-darken-1">Goods</h3>
          </div>

          <div class="d-flex align-content-start">
            <v-avatar color="white" size="18" class="mt-1">
              <v-avatar color="blue-lighten-4" size="14">
              </v-avatar>
            </v-avatar>
            <h3 class="mx-2 text-grey-darken-1">Non-Consulting Service</h3>
          </div>

          <div class="d-flex align-content-start">
            <v-avatar color="white" size="18" class="mt-1">
              <v-avatar color="blue" size="14">
              </v-avatar>
            </v-avatar>
            <h3 class="mx-2 text-grey-darken-1">Consultant Service</h3>
          </div>

          <div class="d-flex align-content-start">
            <v-avatar color="white" size="18" class="mt-1">
              <v-avatar color="indigo" size="14">
              </v-avatar>
            </v-avatar>
            <h3 class="mx-2 text-grey-darken-1">Civil Works</h3>
          </div>

        </VCol>
      </VRow>
    </VCard>

  </VCard>
</template>

<style scoped>

</style>