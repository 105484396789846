<template>
  <v-tabs v-model="tab" color="blue" grow bg-color="white">
    <v-tab text="My Referrals" class="text-none text-start"></v-tab>
    <v-tab text="My Rewards" class="text-none"></v-tab>
  </v-tabs>
  <br />
  <v-tabs-window v-model="tab">
    <v-tabs-window-item value="My Referrals">
      <MyReferralsTab />
    </v-tabs-window-item>

    <v-tabs-window-item value="My Rewards">
      <MyRewardsTab />
    </v-tabs-window-item>
  </v-tabs-window>
</template>

<script>
import MyRewardsTab from "./rewards/MyRewardsTab";
import MyReferralsTab from "./referrals/MyReferralsTab";

let referralsRewardsKey = "referrals_rewards_tab";

export default {
  name: "ReferralPage",
  components: { MyReferralsTab, MyRewardsTab },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    let defaultTab = localStorage.getItem(referralsRewardsKey);
    this.tab = defaultTab ?? 1;
  },
  watch: {
    tab(val) {
      this.tab = val;
      localStorage.setItem(referralsRewardsKey, val);
    },
  },
};
</script>

<style scoped></style>
