import {createApp} from "vue";
import App from "./App.vue";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import {createVuetify} from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import router from "./router";
import store from "./store/customerStore";
import VueCookies from 'vue-cookies'
import GoogleSignInPlugin from "vue3-google-signin"
import {createPinia} from "pinia";
import axiosInstance from "@/servicesAxios/axiosInstance";
import {createI18n} from "vue-i18n"
import {messages} from "@/assets/message";

const vuetify = createVuetify({
    components,
    directives,
});

const app = createApp(App);

const pania = createPinia();

let lang = localStorage.getItem("lang");
if (lang == null) {
    localStorage.setItem('lang', 'en');
    lang = 'en';
}

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: "ja",
    messages: messages,
    formatFallbackMessages: true,
});

app.use(i18n);
app.use(pania);
app.use(GoogleSignInPlugin, {
    clientId: '527026362317-bp98mgnrvcf8v22offp6fivbmhj5omv9.apps.googleusercontent.com',
    scope: ['profile', 'email'],
});
app.config.globalProperties.axios = axiosInstance;
app.use(VueCookies, {expires: '8h'});
app.use(vuetify);
app.use(store);
app.use(router);
// production
// app.use(VueReCaptcha, { siteKey: "6LccbiwqAAAAAOIku8k_AASk_csbrGdaJRBuYwj0" });
// testing
// app.use(VueReCaptcha, { siteKey: "6LcKFBcaAAAAABi8U9SedTJ4yEeoSzdJI9cKXxrG" });
app.mount("#app");
