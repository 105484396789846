<script>
import { Path_ASIA_PACIFIC_IMAGE, URL_ASIA_PACIFIC } from "@/utils/apiUrl";
import FrontendLayout from "@/views/website/components/FrontendLayout.vue";
import axios from "axios";

export default {
  name: "AsiaPacificWebPage",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
      path: Path_ASIA_PACIFIC_IMAGE,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_ASIA_PACIFIC)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <v-img
      min-height="720"
      width="100%"
      cover
      :src="path + data.image1"
    >
      <h2 class="text-uppercase text-center text-white">Asia Pacific</h2>
    </v-img>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ data.title }}
      </h2>

      <div class="margin-auto">
        <p class="text-content text-justify" v-html="data.desc1"></p>

        <br /><br /><br />

        <v-card min-height="400" rounded="0" elevation="0">
          <v-img
            :src="path + data.image2"
          ></v-img>
        </v-card>
        <br />
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <br />

        <p class="text-content text-justify" v-html="data.desc2"></p>

        <br /><br /><br />

        <v-card min-height="400" rounded="0" elevation="0">
          <v-img
            :src="path + data.image3"
          ></v-img>
        </v-card>
        <br />
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <br />

        <p class="text-content text-justify" v-html="data.desc3"></p>
        <br /><br /><br />
        <!--
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/YudHcBIxlYw"
        >
        </iframe>

        <br /><br /><br />

        <p class="text-content text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          vulputate libero et velit interdum, ac aliquet odio mattis. Class
          aptent taciti sociosqu ad litora torquent per conubia nostra, per
          inceptos himenaeos. consectetur adipiscing elit. Nunc vulputate libero
          et velit interdum, ac aliquet odio mattis. Class aptent taciti
          sociosqu.
        </p>
        <br /><br /><br />

        <v-card style="background: transparent" elevation="0">
          <v-card-actions>
            <v-list-item class="">
              <template v-slot:prepend>
                <v-avatar
                  size="200"
                  color="grey-darken-3"
                  image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvWwZEjtTYLbl42S2HTzdZuChoRDjao-dSmA&s"
                ></v-avatar>
              </template>

              <v-list-item-title class="text-h4">Elon Musk</v-list-item-title>

              <v-list-item-subtitle
                >Software Engineer Asia Pacific</v-list-item-subtitle
              >
            </v-list-item>
          </v-card-actions>
        </v-card>

        <br /><br />

        <p class="text-content text-justify">
          Kevin Lu is MIGA’s Director for the Asia-Pacific Region. In this
          capacity, Kevin serves as the senior representative of MIGA in the
          region, manages relationships with key regional clients and partners,
          oversees regional business development activities, and runs MIGA’s
          regional presence in Hong Kong, Singapore, Beijing, and Tokyo. Mr. Lu
          is a member of MIGA’s Senior Management Team. Prior to his appointment
          in Asia, Kevin was MIGA’s CFO and Director of the Finance and Risk
          Management Group where he was responsible for MIGA's financial
          reporting, risk analytics, investments, budget, and
          reinsurance/syndication functions. He has been an advisor to sovereign
          wealth funds clients of the World Bank. In his tenure at the Bank
          Group, Kevin has been responsible for investment portfolios totaling
          $16 billion, and participated in complex multilateral funding packages
          in excess of $25 billion for the World Bank's concessional finance
          business. Kevin served on various Bank Group committees and working
          groups, including Emergency Management Team, Business Continuity
          Management Committee, Diversity and Inclusion Advisory Group, US
          Minority Working Group, etc Kevin was previously associated with
          Credit Suisse First Boston and Global Risk Advisors LLC, both in New
          York. He was also on the adjunct faculty of American University’s
          Kogod School of Business. He is a frequent speaker at international
          conferences on topics such as the role of emerging markets economies
          in the post-crisis world, sovereign wealth funds, Chinese outbound
          investments, and international financial architecture. He is a
          co-editor and an author of Investing with Confidence: Understanding
          Political Risk Management in the 21st Century (Washington DC, 2009).
          In 2010, Kevin was honored as a Young Global Leader by the World
          Economic Forum in Davos, where he co-chairs the Task Force on
          Investments between Emerging Markets. The Task Force focuses on
          opportunities and issues related to cross-border investment activities
          in developing countries, such as China-Africa investments.
        </p>

        <br /><br /> -->
      </div>
    </div>
  </FrontendLayout>
</template>

<style scoped></style>
