import moment from "moment";
import nationality from "../assets/nationality.json";
import job from "../assets/job.json";
import phone from "../assets/phone.json";

export const getLocalStorage = (key) => {
  let local = localStorage.getItem(key);
  if (local === 0) {
    return JSON.parse(local);
  }
  return JSON.parse(local) || [];
};

export const setLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const formatPrice = (price = 0) => {
  if(price){
    return parseFloat(price).toLocaleString();
  }
  return 0;
};

export const formatDate = (date, format = "DD-MM-YYYY") => {
  return moment(date).format(format);
};

export const validatePassword = [
  (v) =>
    (v &&
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        v
      )) ||
    "Minimum 8 characters, One capital latter, Special charater, Number",
  (v) => (v && v.length <= 50) || "Password must be less than 50 characters",
];

export const validateDate = (name = "Required") => {
  return [
    (v) => !!v || name + " is required",
    (v) =>
      /^\d{2}-\d{2}-\d{4}$/.test(v) || "DD-MM-YYYY Expression must be valid",
  ];
};

export const fieldRequired = (name = "Required") => {
  return [(v) => !!v || name + " is required"];
};

export const nationalityOption = () => {
  return nationality;
};

export const phoneOption = () => {
  return phone;
};

export const jobOption = () => {
  return job;
};

export const genderOption = () => {
  return ["Male", "Female", "Other"];
};

export const countryOption = () => {
  const countries = [
    {
      id: "1",
      country: "Cambodia",
    },
    {
      id: "2",
      country: "Thailand",
    },
    {
      id: "3",
      country: "Myanmar",
    },
    {
      id: "4",
      country: "Lao",
    },
    {
      id: "5",
      country: "Singapore",
    },
    {
      id: "6",
      country: "Malaysia",
    },
    {
      id: "7",
      country: "Indonesia",
    },
    {
      id: "8",
      country: "Brunei",
    },
    {
      id: "9",
      country: "Philippine",
    },
    {
      id: "10",
      country: "Vietnam",
    },
  ];

  return countries;
};

export const monthOption = () => {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return month;
};

export const getParams = (params) => {
  let str = Object.keys(params)
    .map(function (key) {
      return key + "=" + params[key];
    })
    .join("&");
  return "?" + str;
};