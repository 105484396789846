<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-select
            v-if="(isStaffLoginAllCountry === 1) | (isStaffLoginAdmin === 1)"
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            autocomplete="off"
            persistent-placeholder
            placeholder="Choose customer..."
            class="style-chooser"
            @update:modelValue="filterItems"
          >
          </v-select>

          <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
          <v-select
            v-else
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            autocomplete="off"
            persistent-placeholder
            placeholder="Choose customer..."
            class="style-chooser"
            disabled
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            label="Search exchange withdraw..."
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="exchangeWithdraws"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'createdAt', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="10" md="10" lg="10" xl="10">
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-select
                          v-if="(isAllCountry === 1) | (isAdmin === 1)"
                          v-model="country_id"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          label="Country"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          @update:modelValue="selectedChangeItems"
                          :disabled="disabled == 0"
                        >
                        </v-select>
                        <!--NOTE: when we use @update.movelValue or :on-change the data return to json 
                                    but else the data return as value normally -->
                        <v-select
                          v-else
                          v-model="country_id"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          :rules="[
                            () => !!country_id || 'This field is required',
                          ]"
                          label="Country"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          @update:modelValue="selectedChangeItems"
                          disabled
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          v-if="(isAllCountry === 1) | (isAdmin === 1)"
                          v-show="textFieldNameCurrency"
                          v-model.trim="currency"
                          label="Name Currency"
                          placeholder="Name Currency"
                          variant="outlined"
                          prepend-inner-icon="mdi-currency-usd"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-else
                          v-show="textFieldNameCurrency"
                          v-model.trim="currency"
                          label="Name Currency"
                          placeholder="Name Currency"
                          variant="outlined"
                          prepend-inner-icon="mdi-currency-usd"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          disabled
                        ></v-text-field>
                        <v-select
                          v-show="comboBankName"
                          v-model="bankNameCryptoCurrency"
                          :items="bankNameCollection"
                          :item-title="'bankName'"
                          :item-value="'id'"
                          label="Bank Name"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-bank-circle-outline"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          placeholder="Choose bank name..."
                          return-object
                          @update:modelValue="
                            showHideMessageErrorWhenBankNameSelectOrNotSelected
                          "
                          :disabled="disabled == 0"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <input type="hidden" v-model="hiddenId" />
                        <input type="hidden" v-model="hdCurrencyImage" />
                        <v-text-field
                          v-model.number.trim="rate"
                          :rules="[() => !!rate || 'This field is required']"
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                          label="Rank"
                          placeholder="Rank"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.number.trim="start_rank"
                          :rules="[
                            () => !!start_rank || 'This field is required',
                          ]"
                          label="Start rank"
                          placeholder="Start"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.number.trim="to_rank"
                          :rules="[() => !!to_rank || 'This field is required']"
                          label="To rank"
                          placeholder="To"
                          variant="outlined"
                          prepend-inner-icon="mdi-cash"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          type="number"
                          :step="0.1"
                          v-model="value"
                          :formatter="formatNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <QuillEditor
                          toolbar="essential"
                          contentType="html"
                          style="height: 150px"
                          v-model:content="desc"
                          theme="snow"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-card variant="variant" class="mx-auto" elevation="16">
                      <v-img
                        :src="
                          previewImage === null
                            ? 'https://bpcdn.co/images/2017/03/09071328/global-currency-symbols.jpeg'
                            : previewImage
                        "
                        height="360"
                        max-width="auto"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                          >
                            <v-progress-circular
                              color="grey-lighten-5"
                              indeterminate
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.Country.logoCurrency`]="{ item }">
        <div v-if="item.Country.logoCurrency" class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img alt="Avatar" :src="path + item.Country.logoCurrency" cover>
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.Country.name`]="{ item }">
        <v-icon icon="mdi-flag"></v-icon>
        {{ item.Country.name }}
      </template>
      <template v-slot:[`item.rate`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.rate) }}
      </template>
      <template v-slot:[`item.start_rank`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.start_rank) }}
      </template>
      <template v-slot:[`item.to_rank`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.to_rank) }}
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        <div
        v-html="
            item.desc !== null
              ? item.desc.length > 50
                ? item.desc.substring(0, 50) + '...'
                : item.desc
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
        <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  URL_COUNTRY,
  URL_Exchange_Withdraw,
  URL_STAFF_COUNTRY_PERMISSION,
  URL_UPLOAD_EXCHANGE_WITHDRAW_LOGO_CURRENCY,
  URL_BANK_NAME_BY_TYPE_METHOD_PAYMENT,
  Path_COUNTRY_Currency_Image,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    path: Path_COUNTRY_Currency_Image,
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    comboBankName: false,
    textFieldNameCurrency: true,
    isAdmin: 0,
    isAllCountry: 0,
    countryIdFromDb: 0,

    items: [],
    exchangeWithdraws: [],
    bankNameCollection: [],
    countries: [{ id: 0, name: "-- All --" }],
    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    //nameCurrency: null,
    previewImage: null,
    currency: null,
    bankNameCryptoCurrency: null,

    page: 1,
    itemsPerPage: 10,

    isStaffLoginAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isStaffLoginAllCountry: originalCode(
      parseInt(localStorage.getItem("allCountry"))
    ),
    staffLoginCountryId: originalCode(
      parseInt(localStorage.getItem("countryId"))
    ),
    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    rate: 0,
    start_rank: 0,
    to_rank: 0,
    country_id: 0,
    desc: null,
    disabled: null,
    searchCountryId: null,

    editedIndex: -1,
    hiddenId: 0,
    hdCurrencyImage: null,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Logo", key: "Country.logoCurrency", align: "center" },
      { title: "Country", key: "Country.name" },
      { title: "Name Currency", key: "name_currency", align: "center" },
      { title: "Rate", key: "rate", align: "center" },
      { title: "Start Rank", key: "start_rank", align: "center" },
      { title: "To Rank", key: "to_rank", align: "center" },
      { title: "Description", key: "desc", align: "center" },
      { title: "Created at", key: "created_at", align: "center" },
      { title: "created by", key: "Staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Exchange Withdraw"
        : "Update Exchange Withdraw";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.exchangeWithdraws.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateCountries();
    this.filterItems();
    this.getStaffCountryAndPermission();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      //this.nameCurrency = null;
      //this.previewImage = "https://bpcdn.co/images/2017/03/09071328/global-currency-symbols.jpeg";
      this.rate = 0;
      this.start_rank = 0;
      this.to_rank = 0;
      this.desc = null;
      this.visible = true;
      //this.country_id = null;
      this.disabled = 1;
    },

    isAdminOrAllCountryPermission() {
      return (this.isStaffLoginAdmin || this.isStaffLoginAllCountry) === 1
        ? true
        : false;
    },

    async PopulateExchangeWithdrawsByCountry(countryId) {
      const url = this.isAdminOrAllCountryPermission()
        ? URL_Exchange_Withdraw + "all/" + this.staffLoginId
        : URL_Exchange_Withdraw + "not-all/" + this.staffLoginId;

      await axios.get(url, { headers: TOKEN_HEADERS }).then((res) => {
        this.exchangeWithdraws = res.data.filter((item) => {
          const itemCountryId = item.country_id;
          const matches = !countryId || itemCountryId === countryId; //searchCountryId == countryId;
          return matches;
        });

        this.items.push(this.exchangeWithdraws);
      });
    },

    async filterItems() {
      var countryId = null;
      if (this.searchCountryId !== 0) {
        countryId =
          this.searchCountryId === undefined ? null : this.searchCountryId;
      } else {
        if (this.searchCountryId === null) {
          countryId = this.isAdminOrAllCountryPermission()
            ? null
            : this.staffLoginCountryId;
        } else {
          countryId = null;
        }
      }

      this.PopulateExchangeWithdrawsByCountry(countryId);
    },

    editItem(item) {
      this.editedIndex = this.exchangeWithdraws.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.disabled = 0;
      this.currency = item.name_currency;
      this.rate = item.rate;
      this.country_id = item.country_id;
      this.start_rank = item.start_rank;
      this.to_rank = item.to_rank;
      this.desc = item.desc;
      this.previewImage =
        item.Country.logoCurrency === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + item.Country.logoCurrency;
      this.hdCurrencyImage = item.Country.logoCurrency;

      const countryCollection = this.filterCountryById(item.country_id);
      if (countryCollection[0].name === "Crypto Currency") {
        this.textFieldNameCurrency = false;
        this.comboBankName = true;
        this.PopulateBankNameByMethodPayment(countryCollection[0].name);
        this.bankNameCryptoCurrency = item.name_currency;
      } else {
        this.textFieldNameCurrency = true;
        this.comboBankName = false;
        this.bankNameCollection = [];
        this.bankNameCryptoCurrency = null;
      }

      this.dialog = true;
    },

    filterCountryById(id) {
      return this.countries.filter((country) => country.id === id);
    },

    deleteItem(item) {
      this.editedIndex = this.exchangeWithdraws.indexOf(item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.exchangeWithdraws.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_Exchange_Withdraw + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.comboBankName == true) {
          if (this.bankNameCryptoCurrency === null) {
            this.visible = false;
            this.msgError = "Please choose bank name";
            return;
          }
        }

        const staffLoginId = originalCode(parseInt(localStorage.getItem("id")));
        const data = {
          country_id: parseInt(this.country_id),
          name_currency:
            this.currency !== null
              ? this.currency
              : this.bankNameCryptoCurrency.bankName,
          logo: this.hdCurrencyImage,
          rate: parseFloat(this.rate),
          start_rank: parseFloat(this.start_rank),
          to_rank: parseFloat(this.to_rank),
          created_by: staffLoginId,
          desc: this.desc,
        };

        if (this.editedIndex > -1) {
          // edit
          const id = this.hiddenId;
          const url = URL_Exchange_Withdraw + id;

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );

          this.close();
        } // create new
        else {
          await axios
            .post(URL_Exchange_Withdraw, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );
        }

        //this.clearContent();
        //this.PopulateExchangeWithdraws();
        var countryId = this.isAdminOrAllCountryPermission()
          ? null
          : this.staffLoginCountryId;
        this.PopulateExchangeWithdrawsByCountry(countryId);

        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    async PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            id: country.id,
            name: country.name,
            currency: country.currency,
            logoCurrency: country.logoCurrency,
          });
        });

        this.searchCountryId = this.isAdminOrAllCountryPermission()
          ? 0
          : this.staffLoginCountryId;
      });
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    async getStaffCountryAndPermission() {
      const staffId = originalCode(parseInt(localStorage.getItem("id")));
      axios
        .get(URL_STAFF_COUNTRY_PERMISSION + staffId, { headers: TOKEN_HEADERS })
        .then((response) => {
          this.isAdmin = response.data.permission.admin;
          this.isAllCountry = response.data.permission.allCountry;
          this.countryIdFromDb = response.data.countryId;
          this.country_id = this.countryIdFromDb;
          if (response.data.country !== null) {
            this.currency = response.data.country.currency;
            this.previewImage =
              response.data.country.logoCurrency === null
                ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
                : this.path + response.data.country.logoCurrency;
          }
        });
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios
        .post(URL_UPLOAD_EXCHANGE_WITHDRAW_LOGO_CURRENCY, data)
        .then((response) => {
          this.hdCurrencyImage = response.data.filename;
          console.log("Image has been uploaded.");
        });
    },

    selectedChangeItems(component) {
      // it get the data as json
      this.currency =
        component.name === "Crypto Currency" ? "" : component.currency;
      this.previewImage =
        component.logoCurrency === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.path + component.logoCurrency;
      this.country_id = this.country_id.id;

      if (component.name === "Crypto Currency") {
        this.comboBankName = true;
        this.textFieldNameCurrency = false;
        this.currency = null;
        this.PopulateBankNameByMethodPayment(component.name);
      } else {
        this.comboBankName = false;
        this.textFieldNameCurrency = true;
        (this.bankNameCryptoCurrency = null), (this.msgError = null);
        this.visible = true;
        this.currency = component.currency;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },

    async PopulateBankNameByMethodPayment(methodPayment) {
      await axios
        .get(URL_BANK_NAME_BY_TYPE_METHOD_PAYMENT + methodPayment, {
          headers: TOKEN_HEADERS,
        })
        .then((response) => {
          this.bankNameCollection = [];
          response.data.forEach((item) => {
            this.bankNameCollection.push({
              id: item.id,
              bankName: item.bankName,
            });
          });
        });
    },

    showHideMessageErrorWhenBankNameSelectOrNotSelected(component) {
      if (component.bankName !== null) {
        this.visible = true;
        this.msgError = null;
      }
    },
  },
};
</script>
