<script>
import { formatDate } from '@/utils/function';

export default {
  props: ['exploreMores'],
  name: 'CardNews',
  data() {
    return {
      formatDate: formatDate,
    };
  },
  methods: {
    linkTo(item) {
      sessionStorage.setItem('project', JSON.stringify(item));
      this.$router.push('/project-details');
    },
  },
};
</script>

<template>
  <br />
  <br />
  <div class="margin-auto">
    <VRow>
      <VCol md="4" sm="6" cols="12" v-for="row in exploreMores" v-bind:key="row.id">
        <v-card class="ma-1" min-width="" rounded="xl">

          <v-img
            height="200"
            :src="row.urlImage"
            cover
            class="d-flex align-end pa-5"
          >
            <h3 class="text-orange text-uppercase font-weight-regular">
              Country
            </h3>

            <h3 class="text-white">
              {{ row.description_project }}
            </h3>
          </v-img>

          <div class="d-flex justify-lg-space-around my-2">
            <span class="text-small">
              Abbr.text
            </span>
            <span class="text-small">
              |
            </span>
            <span class="text-small">
              Pro Category
            </span>
            <span class="text-small">
              |
            </span>
            <span class="text-small">
              {{ formatDate(row.board_approval_date, 'MMM DD, YYYY') }}
            </span>
          </div>

          <VDivider />
          <v-card-text>

            <div class="">
              <h4 class="font-weight-regular">
                {{ row.description_project }}
              </h4>
            </div>
            <br />
            <v-btn variant="text">
              <h3 class="text-primary font-weight-bold text-uppercase" @click="linkTo(row)">
                Read more
                <VIcon>mdi-arrow-right</VIcon>
              </h3>
            </v-btn>
            <br />
          </v-card-text>
        </v-card>
      </VCol>
    </VRow>
  </div>
  <br />
  <br />
</template>

<style scoped>
.text-small {
  font-size: 12px;
}
</style>