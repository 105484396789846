<script setup>
import FrontendLayout from '@/views/website/components/FrontendLayout.vue';
import CardNews from '@/views/website/covid/components/CardNews.vue';

const exploreMores = [
  {
    id: 1,
    image: 'https://s30383.pcdn.co/wp-content/uploads/2024/08/Learning-Design-Level-1-Microcredential.jpg',
  },
  {
    id: 2,
    image: 'https://launcherimpact.org/wp-content/uploads/2024/02/woman-standing-up-in-front-of-colleagues-during-meeting-7644066.jpg.webp',
  },
  {
    id: 3,
    image: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRloX3kR1SbYzJahrcg3KxEIIfsDJ0P9LKPlgP7X1dddci6zbPU',
  },
  {
    id: 4,
    image: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcR617b2rd_Cg2Lm4u87ZccIZ7Y0digPBV5m09L-LmoRExJFcF0h',
  },
  {
    id: 5,
    image: 'https://img.freepik.com/premium-psd/skyscrapers-reaching-sky_713655-18801.jpg?w=1060',
  },
  {
    id: 6,
    image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
  },
];

let form = {
  page: 1,
};

</script>

<template>
  <FrontendLayout>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        COVID-19 Response
      </h2>

      <div class="margin-auto">

        <p class="text-content text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
          odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
          consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent
          taciti sociosqu ad l
        </p>

        <router-link to="/covid-details" class="text-decoration-none text-center">
          <h2 class="text-primary font-weight-bold text-uppercase">
            Read more
            <VIcon>mdi-arrow-right</VIcon>
          </h2>
        </router-link>

        <h2 class="text-uppercase text-blue-darken-3 py-5">
          COVID-19 Response Events and news
        </h2>
      </div>

      <CardNews :exploreMores="exploreMores" />

      <div class="d-flex justify-center">
        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          First
        </VBtn>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          Previous
        </VBtn>

        <v-pagination
          v-model="form.page"
          :length="6"
          rounded="circle"
          prev-icon=""
          next-icon=""
        ></v-pagination>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          Next
        </VBtn>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          Last
        </VBtn>
      </div>

      <br /><br /><br />

    </div>
  </FrontendLayout>
</template>

<style scoped>

</style>