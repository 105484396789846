<template>
 <div>
   <v-divider />
   <v-card min-height="220" elevation="0">
     <v-row>
       <v-col cols="12" sm="12" md="5">
         <div class="mt-10">
           <div class="text-center">
             <h3>{{$t("user_profile")}}</h3>
             <p>{{$t('home_upgrade')}}</p>
           </div>
         </div>
       </v-col>

       <v-col cols="12" sm="12" md="2">
         <div class="mt-5 text-center">
           <v-hover>
             <template v-slot:default="{ isHovering, props }">
               <v-badge
                 :color="
                  profile.status.toLowerCase() !== 'done' ? 'red' : 'green'
                "
                 inline
                 stacked
                 location="top"
                 style="margin-left: 15px; position: absolute; z-index: 1"
               >
               </v-badge>
               <v-avatar size="150" v-bind="props" color="grey">
                 <v-img
                   onclick="document.getElementById('profile').click()"
                   v-if="isHovering"
                   cover
                   alt="John"
                   onerror="this.src='https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg'"
                   :src="'https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg'"
                 ></v-img>
                 <input
                   style="display: none"
                   type="file"
                   id="profile"
                   @change="UploadProfile"
                 />

                 <v-img
                   v-if="!isHovering"
                   cover
                   alt="John"
                   onerror="this.src='https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg'"
                   :src="previewImage"
                 ></v-img>
               </v-avatar>
             </template>
           </v-hover>
         </div>
       </v-col>

       <v-col cols="12" sm="12" md="5">
         <div class="mt-5 profile-center">
           <h4 class="text-primary text-uppercase">
            <span v-if="profile.lastName">
              {{ profile.firstName + " " + profile.lastName }}
            </span>
             <span v-else> Guest </span>
           </h4>

           <span>@</span>
           <span v-if="profile.lastName" class="text-lowercase">
            {{ profile.firstName + "" + profile.lastName }}
          </span>
           <span v-else> Guest </span>

           <div v-if="profile.numberLevel">
             <v-icon
               v-for="i in parseInt(profile.numberLevel)"
               :key="i"
               size="18"
               color="orange"
               icon="mdi-star"
             ></v-icon>
             <v-icon
               v-for="i in 5 - profile.numberLevel"
               :key="i"
               size="18"
               color="grey"
               icon="mdi-star"
             ></v-icon>
           </div>

           <span v-if="parseInt(profile.numberLevel) < 1" class="text-blue">
            Ordinary Level
          </span>

           <br />

           <h4 class="text-grey-darken-1">
             <span class="text-grey-darken-1"> {{$t('available_balance')}} : </span>
             <span class="text-orange"
             >{{
                 wallet.length > 0
                   ? formatPrice(wallet[0].availableBalance)
                   : "0"
               }}
              $
            </span>
           </h4>
           <div class="mt-1" />
           <h4>
             <span class="text-grey-darken-1"> {{$t('account_balance')}} : </span>
             <span class="text-primary"
             >{{
                 wallet.length > 0 ? formatPrice(wallet[0].accountBalance) : "0"
               }}
              $
            </span>
           </h4>
         </div>
       </v-col>
     </v-row>
   </v-card>
 </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import {
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PATH,
  UPDATE_PHOTO,
} from "@/utils/customerApiUrl";

export default {
  name: "CardProfile",
  computed: {
    ...mapState(["profile", "wallet"]),
  },
  data: () => ({
    previewImage: null,
  }),
  methods: {
    formatPrice(price = 0) {
      return price.toLocaleString();
    },
    async UploadProfile(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(UPLOAD_PHOTO, data).then((response) => {
        this.postData(response.data.filename);
        console.log("Image has been uploaded.", response);
      });
    },

    async postData(photo) {
      this.axios
        .post(UPDATE_PHOTO, { photo })
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            this.$store.dispatch("getUserProfile");
          }, 4000);
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    setTimeout(() => {
      this.previewImage = this.profile.profile
        ? UPLOAD_PHOTO_PATH + "" + this.profile.profile
        : "https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg";
    }, 500);
  },
};
</script>

<style scoped></style>
