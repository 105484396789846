<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search star rank referal..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="starRankReferals"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'created_at', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <input type="hidden" v-model="hiddenId" />
                    <v-select
                      v-model="rank_name"
                      :items="rankNameTypes"
                      :rules="[() => !!rank_name || 'This field is required']"
                      label="Rank Name"
                      variant="outlined"
                      density="compact"
                      prepend-inner-icon="mdi-format-list-bulleted-type"
                      required
                      persistent-placeholder
                      placeholder="Choose rank name..."
                      return-object
                      @update:modelValue="selectedItemChanging"
                      disabled
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="percent_ratio"
                      :rules="[
                        () => !!percent_ratio || 'This field is required',
                      ]"
                      label="% Ratio"
                      placeholder="% ratio"
                      variant="outlined"
                      prepend-inner-icon="mdi-percent-box-outline"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="total_value"
                      :rules="[() => !!total_value || 'This field is required']"
                      label="Total Value"
                      placeholder="Total value"
                      variant="outlined"
                      prepend-inner-icon="mdi-alpha-t-box-outline"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="service_fee"
                      :rules="[() => !!service_fee || 'This field is required']"
                      label="Service Fee"
                      placeholder="Service fee"
                      variant="outlined"
                      prepend-inner-icon="mdi-face-agent"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <div>
                      <div class="text-caption">Star Number</div>
                      <v-slider
                        v-model="num_star"
                        :max="5"
                        :ticks="tickLabels"
                        show-ticks="always"
                        step="1"
                        tick-size="6"
                        thumb-label
                        thumb-color="purple"
                        disabled
                      ></v-slider>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                      clear-icon="mdi-close-circle"
                      label="Transfer Upgrade"
                      rows="3"
                      v-model="transfer_upgrade"
                      variant="outlined"
                      density="compact"
                      required
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-label class="font-weight-thin x-small"
                      >Description</v-label
                    >
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 300px"
                      v-model:content="description"
                      theme="snow"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->

      <template v-slot:[`item.num_star`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.num_star) }}
      </template>
      <template v-slot:[`item.percent_ratio`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.percent_ratio) }}
      </template>
      <template v-slot:[`item.total_value`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.total_value) }}
      </template>
      <template v-slot:[`item.service_fee`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.service_fee) }}
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <div
          v-html="
            item.description !== null
              ? item.description.length > 50
                ? item.description.substring(0, 50) + '...'
                : item.description
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
          <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { URL_STAR_RANK_REFERAL } from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { formatNumber } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    items: [],
    starRankReferals: [],
    sectors: [],
    rankNameTypes: [
      "Ordinary Level",
      "Star 1",
      "Star 2",
      "Star 3",
      "Star 4",
      "Star 5",
    ],

    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    tickLabels: {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
    },

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    rank_name: null,
    description: null,
    transfer_upgrade: null,
    num_star: 0,
    percent_ratio: 0,
    total_value: 0,
    service_fee: 0,

    editedIndex: -1,
    hiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Rank Name", key: "rank_name", align: "center" },
      { title: "Star Number", key: "num_star", align: "center" },
      { title: "% Ratio", key: "percent_ratio", align: "center" },
      { title: "Total Value", key: "total_value", align: "center" },
      { title: "Service Fee", key: "service_fee", align: "center" },
      { title: "Description", key: "description", align: "center" },
      { title: "Transfer Upgrade", key: "transfer_upgrade", align: "center" },
      { title: "Created at", key: "created_at", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Star Rank Referal"
        : "Update Star Rank Referal";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.starRankReferals.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulatestarRankReferals();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      //this.rank_name = null;
      //this.num_star = 0;
      this.percent_ratio = 0;
      this.description = null;
      this.transfer_upgrade = null;
      this.total_value = 0;
      this.service_fee = 0;
      this.visible = true;
      this.PopulatestarRankReferals();
    },

    async PopulatestarRankReferals() {
      await axios
        .get(URL_STAR_RANK_REFERAL, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.starRankReferals = res.data;
          this.items.push(this.starRankReferals);

          this.GetRankNameOrderFromOrderLevelToStar5(this.starRankReferals);
        });
    },

    GetRankNameOrderFromOrderLevelToStar5(component) {
      const countRecord = component.length;
      //alert(component[0].rank_name + ", " + this.rankNameTypes[0])
      if (countRecord === 0) {
        this.rank_name = "Ordinary Level";
        this.num_star = 0;
      } else {
        component.filter((item, index) => {
          //alert("Value= " + item.rank_name + ", index= " + index);
          //if (value > 5) return true;
          switch (item.rank_name) {
            case "Ordinary Level":
              index += 1;
              this.rank_name = this.rankNameTypes[index];
              this.num_star = index;
              break;
            case "Star 1":
              index += 1;
              this.rank_name = this.rankNameTypes[index];
              this.num_star = index;
              break;
            case "Star 2":
              index += 1;
              this.rank_name = this.rankNameTypes[index];
              this.num_star = index;
              break;
            case "Star 3":
              index += 1;
              this.rank_name = this.rankNameTypes[index];
              this.num_star = index;
              break;
            case "Star 4":
              index += 1;
              this.rank_name = this.rankNameTypes[index];
              this.num_star = index;
              break;
          }
        });
      }
    },

    editItem(item) {
      this.editedIndex = this.starRankReferals.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.rank_name = item.rank_name;
      this.selectedItemChanging(item.rank_name);
      this.percent_ratio = item.percent_ratio;
      this.description = item.description;
      this.transfer_upgrade = item.transfer_upgrade;
      this.total_value = item.total_value;
      this.service_fee = item.service_fee;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.starRankReferals.indexOf(item);
      this.hiddenId = item.id;

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.starRankReferals.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_STAR_RANK_REFERAL + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const data = {
          rank_name: this.rank_name,
          num_star: parseFloat(this.num_star),
          percent_ratio: parseFloat(this.percent_ratio),
          description: this.description,
          transfer_upgrade: this.transfer_upgrade,
          total_value: parseFloat(this.total_value),
          service_fee: parseFloat(this.service_fee),
        };

        if (this.editedIndex > -1) {
          // edit
          const id = this.hiddenId;
          const url = URL_STAR_RANK_REFERAL + id;

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );

          this.close();
        } // create new
        else {
          await axios
            .post(URL_STAR_RANK_REFERAL, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );
        }

        //this.clearContent();
        this.PopulatestarRankReferals();

        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },

    selectedItemChanging(component) {
      switch (component) {
        case "Ordinary Level":
          this.num_star = 0;
          break;
        case "Star 1":
          this.num_star = 1;
          break;
        case "Star 2":
          this.num_star = 2;
          break;
        case "Star 3":
          this.num_star = 3;
          break;
        case "Star 4":
          this.num_star = 4;
          break;
        default:
          this.num_star = 5;
      }
    },
  },
};
</script>
