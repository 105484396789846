<script>
import { Path_Our_Impact_Image, URL_OUR_IMPACT } from "@/utils/apiUrl";
import FrontendLayout from "@/views/website/components/FrontendLayout.vue";
import axios from "axios";

export default {
  name: "OurImpactPage",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
      path: Path_Our_Impact_Image,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_OUR_IMPACT)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <v-img
      min-height="720"
      width="100%"
      cover
      :src="path + data.image"
    >
      <h2 class="text-uppercase text-center text-white">Out Impact</h2>
    </v-img>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        Out Impact
      </h2>

      <div class="margin-auto">
        <h2 class="text-uppercase text-blue-darken-3">
          {{ data.title }}
        </h2>

        <p class="text-content text-justify" v-html="data.desc"></p>
        <br /><br /><br />
      </div>
    </div>
  </FrontendLayout>
</template>

<style scoped></style>
