<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-select
            v-if="(isStaffLoginAllCountry === 1) | (isStaffLoginAdmin === 1)"
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            clearable
            @update:modelValue="filterItems"
          >
          </v-select>
          <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
          <v-select
            v-else
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            disabled
            clearable
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            label="Search bank account deposit..."
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="bankAccountDeposits"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[
        { key: 'created_at', order: 'desc' },
        { key: 'bank_type_id', order: 'asc' },
        { key: 'name', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-2"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                    <div class="">
                      <!-- Credit Card (Flippable) -->
                      <v-row>
                        <v-col cols="12">
                          <div
                            class="card-wrapper"
                            :class="{
                              flipped: isCvvFocused,
                              blurred: isBlurred,
                            }"
                          >
                            <!-- Front of the card -->
                            <v-card class="credit-card front">
                              <v-card-text>
                                <div class="card-top">
                                  <span class="card-brand">{{
                                    cardBrand
                                  }}</span>
                                  <span class="chip"></span>
                                </div>
                                <div class="card-number">
                                  {{
                                    formattedCardNumber || "xxxx xxxx xxxx xxxx"
                                  }}
                                </div>
                                <div class="card-details">
                                  <div class="card-holder">
                                    <label>Card Holder</label>
                                    <span>{{
                                      uppercasedName || "FULL NAME"
                                    }}</span>
                                  </div>
                                  <div class="card-expiry">
                                    <label>Valid Thru</label>
                                    <span
                                      >{{ expiryMonth || "MM" }}/{{
                                        expiryYear || "YY"
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </v-card-text>
                            </v-card>

                            <!-- Back of the card -->
                            <v-card class="credit-card back">
                              <v-card-text>
                                <div class="black-strip"></div>
                                <div class="signature-strip">
                                  <label>CVC</label>
                                  <span>{{ cvc || "xxx" }}</span>
                                </div>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                    <v-row>
                      <v-col cols="12" v-show="visibleCountry">
                        <v-select
                          v-if="
                            (isStaffLoginAllCountry === 1) |
                              (isStaffLoginAdmin === 1)
                          "
                          v-model="countryId"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          label="Country"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          single-line
                          density="compact"
                          @update:modelValue="
                            selectedCountryClearAllComboboxItem
                          "
                        >
                        </v-select>
                        <v-select
                          v-else
                          v-model="countryId"
                          :items="countries"
                          :item-title="'name'"
                          :item-value="'id'"
                          label="Country"
                          variant="outlined"
                          prepend-inner-icon="mdi-flag-outline"
                          required
                          persistent-placeholder
                          placeholder="Choose country..."
                          return-object
                          single-line
                          density="compact"
                          disabled
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" lg="6" xl="6">
                        <v-select
                          v-model="bank_type_method_payment"
                          :items="bankTypeMehtod"
                          :rules="[
                            () =>
                              !!bank_type_method_payment ||
                              'This field is required',
                          ]"
                          label="Bank Type"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-bank-circle-outline"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          placeholder="Choose bank type..."
                          return-object
                          @update:modelValue="
                            selectedBankTypeMethodPaymentChangeItems
                          "
                          :disabled="disabled == 0"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" lg="6" xl="6">
                        <input type="hidden" v-model="hiddenId" />
                        <input type="hidden" v-model="bank_type_id" />
                        <v-select
                          v-model="name"
                          :items="bankNames"
                          :item-title="'bankName'"
                          :item-value="'id'"
                          :rules="[() => !!name || 'This field is required']"
                          label="Bank Name"
                          variant="outlined"
                          density="compact"
                          prepend-inner-icon="mdi-bank-circle-outline"
                          required
                          autocomplete="off"
                          persistent-placeholder
                          placeholder="Choose bank name..."
                          return-object
                          @update:modelValue="selectedChangeItems"
                          :disabled="disabled == 0"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-show="visibleBankTransfer"
                          v-model.trim="card_number"
                          label="Card Number"
                          placeholder="xxxx xxxx xxxx xxxx"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          maxlength="16"
                          @input="onlyDigits('card_number', $event)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-show="visibleBankTransfer"
                          v-model.trim="expiryMonth"
                          label="Expiry Month"
                          maxlength="2"
                          placeholder="MM"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          @input="onlyDigits('expiryMonth', $event)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-show="visibleBankTransfer"
                          v-model.number.trim="expiryYear"
                          label="Expiry Year"
                          maxlength="2"
                          placeholder="YY"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          @input="onlyDigits('expiryYear', $event)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-show="visibleBankTransfer"
                          v-model.number.trim="cvc"
                          label="CVC"
                          placeholder="###"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                          maxlength="3"
                          @focus="isCvvFocused = true"
                          @blur="isCvvFocused = false"
                          @input="onlyDigits('cvc', $event)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-model.number.trim="bank_account"
                          v-show="visibleBankTransfer"
                          label="Bank Account"
                          placeholder="Bank Account"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-show="visibleBankTransfer"
                          v-model.number.trim="wallet_account"
                          label="Wallet Account"
                          placeholder="Wallet Account"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-text-field
                          v-show="visibleBankTransfer"
                          v-model.number.trim="api_link"
                          label="API Link"
                          placeholder="API Link"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          v-show="visibleCryptoCurrency"
                          v-model.number.trim="usdt_link"
                          label="USDT Link"
                          placeholder="USDT Link"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                        <v-text-field
                          v-show="visibleCryptoCurrency"
                          v-model.trim="usdt_qr_code"
                          label="USDT QR Code"
                          placeholder="USDT QR Code"
                          variant="outlined"
                          required
                          autocomplete="off"
                          density="compact"
                          persistent-placeholder
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue-darken-1" variant="text">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.created_at`]="{ item }">
        <div class="text-end">
          {{ new Date(item.created_at).toLocaleString() }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
          <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  URL_BANK_ACC_DEPOSIT,
  URL_BANK_ACC_DEPOSIT_SEARCH,
  URL_BANK_NAME_BY_TYPE_METHOD_PAYMENT,
  URL_COUNTRY,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";

export default {
  data: () => ({
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    visibleBankTransfer: true,
    visibleCryptoCurrency: true,
    isCvvFocused: false, // To track if the CVV input is focused
    isBlurred: false,
    visibleCountry: true,

    items: [],
    bankAccountDeposits: [],
    bankNames: [],
    bankTypeMehtod: [
      "Bank transfer",
      "Online bank transfer",
      "Crypto currency",
    ],
    countries: [{ id: 0, name: "-- All --" }],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    bank_type_id: null,
    bank_type_method_payment: null,
    name: null,
    bank_account: null,
    usdt_link: null,
    wallet_account: null,
    card_number: "",
    cvc: null,
    expired_date: null,
    api_link: null,
    usdt_qr_code: null,
    cardBrand: "VISA",
    expiryMonth: null,
    expiryYear: null,
    disabled: null,
    searchCountryId: null,
    countryId: null,

    editedIndex: -1,
    hiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    staffLoginCountryId: originalCode(
      parseInt(localStorage.getItem("countryId"))
    ),
    isStaffLoginAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isStaffLoginAllCountry: originalCode(
      parseInt(localStorage.getItem("allCountry"))
    ),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Country", key: "bankType.country.name", align: "center" },
      { title: "Bank Type", key: "bankType.paymentMethod", align: "center" },
      { title: "Name", key: "name", align: "center" },
      { title: "Bank Account", key: "bank_account", align: "center" },
      { title: "USDT Link", key: "usdt_link", align: "center" },
      { title: "Wallet Account", key: "wallet_account", align: "center" },
      { title: "Card Number", key: "card_number", align: "center" },
      { title: "CVC", key: "cvc", align: "center" },
      { title: "Expired Date", key: "expired_date", align: "center" },
      { title: "API Link", key: "api_link", align: "center" },
      { title: "USDT QR Code", key: "usdt_qr_code", align: "center" },
      { title: "Created At", key: "created_at", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Bank Account Deposit"
        : "Update Bank Account Deposit";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },

    formattedCardNumber() {
      // Format the card number as '1234 5678 9012 3456'
      return (this.card_number || "").replace(/(.{4})/g, "$1 ").trim();
    },
    uppercasedName() {
      return (this.name || "").toUpperCase();
    },
    pageCount() {
      return Math.ceil(this.bankAccountDeposits.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateCountries();
    this.filterItems();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.bank_type_id = null;
      this.name = null;
      this.bank_account = null;
      this.usdt_link = null;
      this.wallet_account = null;
      this.card_number = null;
      this.cvc = null;
      this.expired_date = null;
      this.expiryMonth = null;
      this.expiryYear = null;
      this.api_link = null;
      this.usdt_qr_code = null;
      this.visible = true;
      this.visibleBankTransfer = true;
      this.visibleCryptoCurrency = true;
      this.bankNames = [];
      this.disabled = 1;
      this.bank_type_method_payment = null;
      this.visibleCountry = true;
      this.countryId = this.staffLoginCountryId;
    },

    isAdminOrAllCountryPermission() {
      return (this.isStaffLoginAdmin || this.isStaffLoginAllCountry) == 1
        ? true
        : false;
    },

    async PopulateCountries() {
      await axios
        .get(URL_COUNTRY, { headers: TOKEN_HEADERS })
        .then((response) => {
          response.data.forEach((country) => {
            this.countries.push({
              id: country.id,
              name: country.name,
            });
          });

          this.searchCountryId = this.staffLoginCountryId;
          this.countryId = this.staffLoginCountryId;
        });
    },

    async filterItems() {
      var searchCountryId = null;
      if (this.searchCountryId) {
        searchCountryId =
          this.searchCountryId.id === undefined
            ? null
            : this.searchCountryId.id;
      } else {
        if (this.searchCountryId === null) {
          searchCountryId = this.isAdminOrAllCountryPermission()
            ? null
            : this.staffLoginCountryId;
        } else {
          searchCountryId = this.staffLoginCountryId;
        }
      }

      this.PopulatebankAccountDepositsByCountry(searchCountryId);
    },

    async PopulatebankAccountDepositsByCountry(countryId) {
      await axios
        .get(URL_BANK_ACC_DEPOSIT_SEARCH + this.staffLoginCountryId, {
          headers: TOKEN_HEADERS,
        })
        .then((res) => {
          //const searchCountryId = this.searchCountryId.id;
          this.bankAccountDeposits = res.data.filter((item) => {
            const itemCountry = item.bankType.countryId;
            const matchesCountry = !countryId || itemCountry === countryId;

            //alert(startDate + ", " + itemDate + ", " + matchesDateRange);
            //const matchesSearch = !search || item.name.toLowerCase().includes(search);

            return matchesCountry;
          });

          this.items.push(this.bankAccountDeposits);
        });
    },

    editItem(item) {
      this.editedIndex = this.bankAccountDeposits.indexOf(item);
      this.disabled = 0;
      this.visibleCountry = false;
      this.hiddenId = item.id;
      this.bank_type_method_payment = item.bankType.paymentMethod;
      this.PopulateBankNameByMethodPayment(item.bankType.paymentMethod);
      //this.editedItem = Object.assign({}, item);
      this.bank_type_id = item.bank_type_id;
      this.name = item.name;
      this.bank_account = item.bank_account;
      this.usdt_link = item.usdt_link;
      this.wallet_account = item.wallet_account;
      this.card_number = item.card_number;
      this.cvc = item.cvc;
      this.expiryMonth = item.expired_date.slice(0, 2);
      this.expiryYear = item.expired_date.slice(-2);
      this.api_link = item.api_link;
      this.usdt_qr_code = item.usdt_qr_code;

      if (
        item.bankType.paymentMethod === "Bank transfer" ||
        item.bankType.paymentMethod === "Online bank transfer"
      ) {
        this.visibleBankTransfer = true;
        this.visibleCryptoCurrency = false;
        this.isBlurred = false;
      } else {
        this.visibleCryptoCurrency = true;
        this.visibleBankTransfer = false;
        this.isBlurred = true;
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.bankAccountDeposits.indexOf(item);
      this.hiddenId = item.id;

      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        var yearNumber = new Date().getFullYear().toString().substr(-2);
        if (this.expiryMonth > 12) {
          this.visible = false;
          this.msgError =
            "Expiry Month cannot bigger than 12. Please check your month expired card again.";
          return;
        }
        alert(yearNumber)
        if (parseInt(this.expiryYear) < parseInt(yearNumber)) {
          
          this.visible = false;
          this.msgError =
            "Expiry Year cannot smaller than current year. Please check your year expired card again.";
          return;
        }

        const expiredDate = this.expiryMonth + "/" + this.expiryYear;
        const data = {
          bank_account: this.bank_account,
          usdt_link: this.usdt_link,
          wallet_account: this.wallet_account,
          card_number: this.card_number,
          cvc: this.cvc,
          expired_date: expiredDate,
          api_link: this.api_link,
          usdt_qr_code: this.usdt_qr_code,
        };

        if (this.editedIndex > -1) {
          // edit
          const id = this.hiddenId;
          const url = URL_BANK_ACC_DEPOSIT + id;

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );

          this.close();
        } // create new
        else {
          data.bank_type_id = parseInt(this.bank_type_id);
          data.name = this.name;

          await axios
            .post(URL_BANK_ACC_DEPOSIT, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );
        }

        //this.clearContent();
        //this.PopulatebankAccountDeposits();
        var countryId = this.isAdminOrAllCountryPermission()
          ? null
          : this.staffLoginCountryId;
        this.PopulatebankAccountDepositsByCountry(countryId);

        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    PopulateBankNameByMethodPayment(methodPayment) {
      axios
        .get(
          URL_BANK_NAME_BY_TYPE_METHOD_PAYMENT +
            this.countryId +
            "/" +
            methodPayment,
          {
            headers: TOKEN_HEADERS,
          }
        )
        .then((response) => {
          this.bankNames = [];
          response.data.forEach((item) => {
            this.bankNames.push({
              id: item.id,
              bankName: item.bankName,
            });
          });
        });
    },

    selectedCountryClearAllComboboxItem() {
      if (this.countryId !== "-- All --") {
        this.countryId = this.countryId.id;
      } else {
        this.countryId = 0;
      }

      this.bank_type_method_payment = null;
      this.name = null;
      this.bankNames = [];
    },

    selectedBankTypeMethodPaymentChangeItems(component) {
      this.name = null;
      this.bankNames = [];

      if (
        component === "Bank transfer" ||
        component === "Online bank transfer"
      ) {
        this.visibleBankTransfer = true;
        this.visibleCryptoCurrency = false;
        this.isBlurred = false;
      } else {
        this.visibleCryptoCurrency = true;
        this.visibleBankTransfer = false;
        this.isBlurred = true;
      }

      this.PopulateBankNameByMethodPayment(component);
    },

    selectedChangeItems(component) {
      this.bank_type_id = component.id;
      this.name = component.bankName;
    },

    upperCase(value) {
      this.name = String(value).toUpperCase();
    },

    onlyDigits(field, event) {
      this[field] = event.target.value.replace(/\D/g, "");
    },
  },
};
</script>
<style scoped>
/** card */
.credit-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.card-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  perspective: 1000px;
}

.credit-card {
  width: 100%;
  height: auto;
  border-radius: 16px;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  color: white;
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.card-wrapper.flipped .front {
  transform: rotateY(180deg);
}

.card-wrapper.flipped .back {
  transform: rotateY(0);
}

.front {
  background: linear-gradient(135deg, #4d79ff, #3250d3);
}

.back {
  background: #333;
  transform: rotateY(180deg);
}

.card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-brand {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.chip {
  width: 40px;
  height: 30px;
  background: linear-gradient(to right, #e0e0e0, #f2f2f2);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.card-number {
  font-size: 24px;
  letter-spacing: 3px;
  margin: 20px 0;
}

.card-details {
  display: flex;
  justify-content: space-between;
}

.card-holder,
.card-expiry {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #ccc;
}

span {
  font-size: 18px;
  font-weight: bold;
}

.black-strip {
  width: 100%;
  height: 40px;
  background-color: black;
  margin-bottom: 20px;
}

.signature-strip {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 5px 10px;
  border-radius: 4px;
  color: #333;
}

.signature-strip label {
  color: #333;
}

.v-input__control {
  color: #333; /* Input text color */
  font-size: 16px; /* Input font size */
}

.blurred {
  filter: blur(5px);
}
</style>
