<template>
  <FrontendLayout>

    <v-img
      min-height="720"
      width="100%"
      cover
      src="https://www.miga.org/sites/default/files/2018-06/header_political-risk-insurance.jpg"
    >
      <h2 class="text-uppercase text-center text-white">
        Out Process
      </h2>
    </v-img>



    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5">
        Out Process
      </h3>

      <div class="margin-auto py-10">
        <p class="text-justify">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
          vulputate libero et velit interdum, ac aliquet odio mattis. Class
          aptent taciti sociosqu ad litora torquent per conubia nostra, per
          inceptos himenaeos. consectetur adipiscing elit. Nunc vulputate libero
          et velit interdum, ac aliquet odio mattis. Class aptent taciti
          sociosqu ad l
        </p>
      </div>
    </div>
  </FrontendLayout>
</template>

<script>
import FrontendLayout from "../components/FrontendLayout";
export default {
  name: "OutProcessPage",
  components: { FrontendLayout },
};
</script>

<style scoped></style>
