<template>
  <FrontendLayout>
    <v-img
      min-height="720"
      width="100%"
      cover
      :src="path + data.image"
    >
      <h2 class="text-uppercase text-center text-blue-darken-5">
        {{ data.title }}
      </h2>
    </v-img>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ data.title }}
      </h2>

      <div class="margin-auto">
        <v-row>
          <v-col cols="12" md="7">
            <br /><br />

            <p class="text-content text-justify"
            v-html="data.desc"
            ></p>

            
          </v-col>

          <v-col cols="12" md="5">
            <div style="height: 500px"></div>
            <h2>Related</h2>
            <hr />
            <template v-for="i in 6" v-bind:key="i">
              <CardRelated />
            </template>
          </v-col>
        </v-row>

        <br /><br /><br />
      </div>
    </div>
  </FrontendLayout>
</template>
<script>
import FrontendLayout from "@/views/website/components/FrontendLayout.vue";
import CardRelated from "@/views/website/about-us/components/CardRelated.vue";
import { Path_Our_Political_Risk_Insurance_Image, URL_Our_Political_Risk_Insurance } from "@/utils/apiUrl";
import axios from "axios";

export default {
  name: "AboutUs",
  components: { FrontendLayout, CardRelated },
  data() {
    return {
      data: [],
      path: Path_Our_Political_Risk_Insurance_Image,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_Our_Political_Risk_Insurance)
        .then((response) => {
          this.data = response.data[0];
          
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
