<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
      v-for="(row, index) in mainRewards"
      v-bind:key="row.title"
      class="pa-0"
    >
      <v-card
        height="170px"
        class="pa-2 text-center align-content-center"
        elevation="0"
        style="margin: 1px"
      >
        <h4>
          {{ row.title }}
        </h4>
        <h2 :class="index === 0 ? 'text-blue' : 'text-red'">
          $ {{ formatPrice(row.total) }}
        </h2>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="row in rewards"
          v-bind:key="row.title"
          class="pa-0"
        >
          <v-card
            height="85px"
            class="pa-2 text-center align-content-center"
            elevation="0"
            style="margin: 1px"
          >
            <h5>
              {{ row.nameLevel }}
            </h5>
            <h4 class="text-red">$ {{ formatPrice(row._sum.amountReward) }}</h4>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <br />
  <h4 class="text-uppercase text-grey">Invitation Rewards</h4>
  <br />
  <v-row>
    <v-col
      cols="12"
      md="6"
      v-for="row in invitation_rewards"
      v-bind:key="row.title"
    >
      <v-card rounded="xl" height="" class="pa-3">
        <h4 class="my-2">{{ row.title }} {{ row.number }}</h4>

        <v-row class="">
          <v-col
            v-for="item in row.listClaim"
            v-bind:key="item.referral"
            cols="4"
            md="2"
          >
            <p class="text-right text-grey text-small">
              {{ item.referral }}
            </p>
            <p class="text-small">$ {{ formatPrice(item.price) }}</p>
            <v-btn
              class="my-2 text-white text-none"
              elevation="1"
              rounded="xl"
              size="x-small"
              color="orange"
              block
              @click="onClaim(item, row.title)"
              >Claim
            </v-btn>
            <!-- v-if="item.claim" -->

            <!-- <v-btn
              v-if="!item.claim"
              disabled
              class="my-2 text-white text-none"
              rounded="xl"
              size="x-small"
              color="grey"
              block
              >Claim
            </v-btn> -->
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
  <br />

  <div class="text-center pa-4">
    <v-dialog v-model="dialog" width="auto" persistent>
      <v-card max-width="600">
        <v-card-text>
          <p>Are your sure you want to claim ?</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            class="ms-auto text-none"
            text="cancel"
            @click="dialog = false"
          ></v-btn>

          <v-btn
            class="ms-auto text-none"
            text="Ok"
            @click="handleConfirm()"
            :loading="loading"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <ListRewards
    :transactions="transactions"
    :total="total"
    :getTake="getTake"
    :getRemark="getRemark"
  />
</template>

<script>
import { formatPrice } from "../../../../../utils/function";
import ListRewards from "./ListRewards";
import { REFERRAL } from "@/utils/customerApiUrl";

export default {
  name: "MyRewardsTab",
  components: { ListRewards },
  data() {
    return {
      loading: false,
      dialog: false,

      formatPrice: formatPrice,
      //
      mainRewards: [
        {
          title: " Successful Referrals",
          total: "0",
        },
        {
          title: " Total Rewards",
          total: "0",
        },
      ],
      //
      rewards: [
        {
          nameLevel: "Ordinary Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "1 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "2 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "3 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "4 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
        {
          nameLevel: "5 Start Rewards",
          _sum: {
            amountReward: "0",
          },
        },
      ],
      // models invitations
      invitation_rewards: [
        {
          title: "Ordinary Referrals",
          number: 9,
          listClaim: [
            {
              referral: "5 Refs",
              price: "1",
              claim: true,
            },
            {
              referral: "10 Refs",
              price: "3",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "9",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "20",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "30",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "50",
              claim: false,
            },
          ],
        },
        {
          title: "1 Start Referrals",
          number: "30",
          listClaim: [
            {
              referral: "5 Refs",
              price: "10",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "20",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "150",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "400",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "750",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "1200",
              claim: false,
            },
          ],
        },
        {
          title: "2 Start Referrals",
          number: "0",
          listClaim: [
            {
              referral: "5 Refs",
              price: "100",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "400",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "1500",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "4000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "7500",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "12000",
              claim: false,
            },
          ],
        },
        {
          title: "3 Start Referrals",
          number: "15",
          listClaim: [
            {
              referral: "5 Refs",
              price: "1000",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "4000",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "15000",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "40000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "75000",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "120000",
              claim: false,
            },
          ],
        },
        {
          title: "4 Start Referrals",
          number: "0",
          listClaim: [
            {
              referral: "5 Refs",
              price: "3000",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "12000",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "45000",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "120000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "225000",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "360000",
              claim: false,
            },
          ],
        },
        {
          title: "5 Start Referrals",
          number: "0",
          listClaim: [
            {
              referral: "5 Refs",
              price: "5000",
              claim: false,
            },
            {
              referral: "10 Refs",
              price: "20000",
              claim: false,
            },
            {
              referral: "25 Refs",
              price: "75000",
              claim: false,
            },
            {
              referral: "50 Refs",
              price: "200000",
              claim: false,
            },
            {
              referral: "75 Refs",
              price: "357000",
              claim: false,
            },
            {
              referral: "100 Refs",
              price: "600000",
              claim: false,
            },
          ],
        },
      ],
      //
      row: {},
      nameLevel: "Ordinary Rewards",
      params: {
        take: 10,
        skip: 1,
        remark: "",
      },
      transactions: [],
      total: 0,
      referralClaims: 0,
    };
  },
  methods: {
    async fetchData() {
      this.axios
        .get(
          REFERRAL +
            `?skip=${this.params.skip}&take=${this.params.take}&remark=${this.params.remark}`
        )
        .then((response) => {
          let { transactions, rewards, total, referralClaims } = response.data;
          ///console.log(response.data.data);
          this.transactions = transactions;
          this.total = total;
          this.referralClaims = referralClaims;
          if (rewards) {
            this.rewards = rewards;
            let amountReward = 0;
            for (let reward in rewards) {
              amountReward += rewards[reward]._sum.amountReward;
            }
            this.mainRewards[1].total = amountReward;
          }
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
    onClaim(row, nameLevel) {
      this.dialog = true;
      this.row = row;
      this.nameLevel = nameLevel;
    },
    handleConfirm() {
      console.log(this.row);
      this.loading = true;
      let data = {
        rankStar: parseFloat(this.row.referral),
        numberStar: parseFloat(this.row.referral),
        numberReferralAll: parseFloat(this.row.referral),
        amountReward: parseFloat(this.row.price),
        nameLevel: this.nameLevel,
      };
      this.axios
        .post(REFERRAL, data)
        .then((response) => {
          //   console.log(response.data.data);
          let { data } = response;
          if (data) {
            console.log(data);
          }
          this.loading = false;
          this.dialog = false;
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.dialog = false;
          console.error("Failed to", error);
        });
    },

    getTake(take) {
      this.params.take = take;
      this.fetchData();
    },

    getRemark(remark) {
      this.params.remark = remark;
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
.text-small {
  font-size: 12px;
}
</style>
