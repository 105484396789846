<script>
export default {
  props: ['closeDrawer'],
};
</script>

<template>
  <div class="pa-4">
    <div class="d-flex justify-lg-space-between">
      <VIcon @click="closeDrawer()">
        mdi-arrow-left
      </VIcon>
      <h3>Notifications</h3>
    </div>

    <br />

    <div class="d-flex justify-lg-space-between">
      <h3 class="text-grey-lighten-1">System</h3>
      <h3 class="text-primary text-decoration-underline">Announcement</h3>
    </div>

    <br />

    <v-card
      class="mx-auto pa-3 my-4"
      v-for="i in 5"
      v-bind:key="i"
      rounded="md"
      border
      flat
    >
      <v-card-title>
        <VIcon color="grey-lighten-1">
          mdi-bullhorn
        </VIcon>
        <span class="text-h5 text-primary">
          Announce Title 1
        </span>
      </v-card-title>
      <v-card-text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
        <br /><br />
        <p>
          Released : 1-1-2024
        </p>
      </v-card-text>
    </v-card>

  </div>
</template>

<style scoped>

</style>