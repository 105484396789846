<template>
    <v-card rounded="0" class="px-10 pa-3">
        <v-row>
            <v-col cols="6" sm="6" md="2">
                <img class="mt-2" width="200px" src="../../../assets/logo.png" alt=""/>
            </v-col>

            <v-col cols="12" sm="12" md="8" class="hidden"></v-col>

            <v-col cols="6" sm="6" md="2">
                <div class="d-flex justify-end">
                    <v-btn variant="text" icon="mdi-close" @click="handleBack"></v-btn>
                </div>
            </v-col>
        </v-row>
    </v-card>

    <div class="margin-center">
        <br/>
        <br/>
        <h2 class="text-center">Are you sure reset now ?</h2>
        <p class="text-center">
            <span> Already have an account </span>
            <router-link
                    class="text-decoration-none text-black text-decoration-underline"
                    to="/sign-up"
            >
                Sign up ?
            </router-link>
        </p>
        <div v-if="error">
            <br/>
            <br/>
            <v-card class="pa-4" color="grey-lighten-3" elevation="0">
                <v-avatar color="red">
                    <v-icon>mdi-close</v-icon>
                </v-avatar>
                <span>
          {{ message }}
        </span>
            </v-card>
        </div>
        <br/>
        <p class="ma-1">First, enter your email address</p>
        <v-form ref="formLogin">
            <v-text-field
                    prepend-inner-icon="mdi-email"
                    v-model="form.email"
                    variant="outlined"
                    density="compact"
                    autocomplete="off"
                    placeholder="Enter your email address"
                    v-on:keyup="handleChange"
                    type="email"
                    :rules="nameRules"
            />
            <!--      <v-text-field-->
            <!--        v-model="state.form.password"-->
            <!--        variant="outlined"-->
            <!--        density="compact"-->
            <!--        autocomplete="off"-->
            <!--        placeholder="Enter your password"-->
            <!--        prepend-inner-icon="mdi-lock"-->
            <!--        :append-inner-icon="state.visible ? 'mdi-eye-off' : 'mdi-eye'"-->
            <!--        :rules="nameRulesPass"-->
            <!--        :type="state.visible ? 'text' : 'password'"-->
            <!--        @click:append-inner="state.visible = !state.visible"-->
            <!--      />-->
            <v-btn
                    block
                    rounded="xl"
                    :disabled="
          form.email === '' ? true : false
        "
                    color="blue"
                    elevation="0"
                    @click="handleSubmit()"
                    :loading="loading"
            >
                <p class="text-capitalize">
                    Sent email
                </p>
            </v-btn>
        </v-form>
        <!--    <p class="my-6">Or login with</p>-->

        <!--    <v-row>-->
        <!--      <v-col cols="12">-->
        <!--        <GoogleSignInButton-->
        <!--          @success="handleLoginSuccess"-->
        <!--          @error="handleLoginError"-->
        <!--          ref="google"-->
        <!--        >-->
        <!--        </GoogleSignInButton>-->
        <!--      </v-col>-->
        <!--    </v-row>-->

        <br/>
        <br/>

        <!--    <p class="text-center">-->
        <!--      By registering, you accept our-->
        <!--      <router-link-->
        <!--        class="text-decoration-none text-black text-decoration-underline"-->
        <!--        to="/"-->
        <!--      >-->
        <!--        Term of use-->
        <!--      </router-link>-->
        <!--      and-->
        <!--      <router-link-->
        <!--        class="text-decoration-none text-black text-decoration-underline"-->
        <!--        to="/"-->
        <!--      >-->
        <!--        Privacy policy-->
        <!--      </router-link>-->
        <!--    </p>-->
    </div>
</template>

<script>
    import {defineComponent} from "vue";

    const nameRules = [
        (value) => {
            if (value) return true;
            return "Email is required.";
        },
        (value) => {
            if (value.length > 0) {
                const pattern =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid Email address";
            }
        },
    ];

    export default defineComponent({
        name: 'ForgetPasswordPage',
        data() {
            return {
                visible: false,
                form: {
                    email: "",
                },
                loading: false,
                nameRules,
                error: false,
                message: "Something went wrong.",
            }
        },
        methods: {
            handleSubmit() {
                // let payload = {
                //     ...state.form,
                //     customer_login_information: state.customer_login_information,
                //     google_id: "0",
                // };
                // signIn(payload);
            },

            handleLoginSuccess() {
                // const {credential} = response;
                //
                // let decodedCredential = decodeCredential(credential);
                //
                // const payload = {
                //     ...decodedCredential,
                //     google_id: decodedCredential.id,
                //     //customer_login_information: state.customer_login_information,
                //     password: "",
                // };
                // //console.log(payload);
                // signIn(payload);
            },

            handleLoginError(error) {
                console.error("Login error", error);
            }

        }
    })

    // import { CUSTOMER_SIGN_IN } from "@/utils/constant";
    // import { setLocalStorage } from "@/utils/function";
    // import axios from "axios";

    // import { useRouter } from "vue-router";
    // import { decodeCredential } from "vue3-google-signin";

    // const router = useRouter();
    //
    // const state = reactive({
    //   visible: false,
    //   form: {
    //     email: "",
    //     password: "",
    //   },
    //   loading: false,
    //   customer_login_information: {
    //     ip: "",
    //     city: "",
    //     region: " ",
    //     region_code: "",
    //     country: "",
    //     country_code: "",
    //     country_capital: " ",
    //     postal: null,
    //     latitude: 0,
    //     longitude: 0,
    //     organization: "",
    //     browser: "",
    //   },
    //   error: false,
    //   message: "Something went wrong.",
    // });
    //

    //
    // // const nameRulesPass = [
    // //   (value) => {
    // //     if (value) return true;
    // //     return "Password is required.";
    // //   },
    // // ];
    //
    // const handleChange = (event) => {
    //   state.form.email = event.target.value;
    //   state.error = false;
    // };
    //
    // const handleBack = () => {
    //   setLocalStorage("form_login", []);
    //   router.go(-1);
    // };
    //
    // function uuidv4() {
    //   return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    //     (
    //       +c ^
    //       (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    //     ).toString(16)
    //   );
    // }
    //
    // const signIn = async (payload) => {
    //   state.loading = true;
    //
    //   await axios
    //     .post(CUSTOMER_SIGN_IN, payload)
    //     .then((response) => {
    //       const { access_token } = response.data;
    //       localStorage.setItem("client_token", access_token);
    //
    //       if (access_token) {
    //         setTimeout(() => {
    //           state.loading = false;
    //           //router.push("/");
    //           location.href = "/profile?id=" + uuidv4();
    //         }, 2000);
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status === 400) {
    //         state.message = error.response.data.message;
    //         state.error = true;
    //         state.loading = false;
    //       }
    //     });
    // };
    //
    // const handleSubmit = () => {
    //   let payload = {
    //     ...state.form,
    //     customer_login_information: state.customer_login_information,
    //     google_id: "0",
    //   };
    //   signIn(payload);
    // };
    //
    // // const handleLoginSuccess = (response) => {
    // //   const { credential } = response;
    // //
    // //   let decodedCredential = decodeCredential(credential);
    // //
    // //   const payload = {
    // //     ...decodedCredential,
    // //     google_id: decodedCredential.id,
    // //     customer_login_information: state.customer_login_information,
    // //     password: "",
    // //   };
    // //   //console.log(payload);
    // //   signIn(payload);
    // // };
    //
    // // const handleLoginError = (error) => {
    // //   console.error("Login error", error);
    // // };
    //
    // const userInfo = async () => {
    //   fetch("https://ipapi.co/json")
    //     .then((response) => response.json())
    //     .then((data) => {
    //       //console.log(data);
    //       const {
    //         ip,
    //         city,
    //         region,
    //         region_code,
    //         country_name,
    //         country_code,
    //         country_capital,
    //         country_area,
    //         latitude,
    //         longitude,
    //         org,
    //       } = data;
    //
    //       state.customer_login_information = {
    //         ip: ip,
    //         city: city,
    //         region: region,
    //         region_code: region_code,
    //         country: country_name,
    //         country_code: country_code,
    //         country_capital: country_capital,
    //         postal: country_area,
    //         latitude: latitude,
    //         longitude: longitude,
    //         organization: org,
    //         browser: navigator.userAgent,
    //       };
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // };
    //
    // onMounted(() => {
    //   localStorage.removeItem("client_token");
    //    userInfo();
    //   // state.form = {
    //   //   email: "admin@gmail.com",
    //   //   password: "12345678",
    //   //   register: false,
    //   // };
    // });
</script>

<style scoped></style>
