<script>
import { Path_Currency_Image, URL_CURRENCY } from "@/utils/apiUrl";
import FrontendLayout from "@/views/website/components/FrontendLayout.vue";
import axios from "axios";

export default {
  name: "SmallInvestmentProgramPage",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
      path: Path_Currency_Image,
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_CURRENCY)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <FrontendLayout>
    <v-img
      min-height="720"
      width="100%"
      cover
      src="https://www.miga.org/sites/default/files/2018-06/header_money.jpg"
    >
      <h2 class="text-uppercase text-center text-white">Currency</h2>
    </v-img>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ data.title }}
      </h2>

      <div class="margin-auto">
        <p class="text-content text-justify" v-html="data.desc"></p>

        <br /><br /><br />

        <iframe
          width="100%"
          height="600"
          :src="data.link_youtube"
        >
        </iframe>

        <br /><br />
        <!--
        <p class="text-content text-justify">
          nostra, per inceptos himenaeos. consectetur adipiscing elit. Nunc
          vulputate libero et velit interdum, ac aliquet odio mattis. Class
          aptent taciti sociosqu ad l Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet
          odio mattis. Class aptent taciti sociosqu ad litora torquent per
          conubia nostra, per inceptos himenaeos. consectetur adipiscing elit.
          Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class
          aptent taciti sociosqu ad l
        </p>
        <br /><br /><br /> -->
      </div>
    </div>
  </FrontendLayout>
</template>

<style scoped></style>
