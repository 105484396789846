<template>
  <v-layout>
    <v-app-bar density="compact" class="margin-auto bg-primary">
      <p class="main-title">Welcome to MEGA World Bank</p>

      <template v-slot:append>
        <router-link to="/sign-in" class="text-decoration-none" v-if="!login">
          <p class="text-white">Login</p>
        </router-link>

        <router-link to="/sign-up" class="text-decoration-none" v-if="!login">
          <p class="ml-10 text-white">Register</p>
        </router-link>

        <v-btn class="text-none" variant="text" @click="logOut()" v-if="login">
          <p class="text-white">Log out</p>
        </v-btn>

        <v-btn icon="mdi-facebook" size="small"></v-btn>

        <v-btn icon="mdi-linkedin" size="small"></v-btn>

        <v-btn icon="mdi-twitter" size="small"></v-btn>

        <v-btn icon="mdi-youtube" size="small"></v-btn>

        <v-btn icon="mdi-whatsapp" size="small"></v-btn>
      </template>
    </v-app-bar>

    <v-main>
      <!--      <div class="margin-auto main-nav main-hover">-->
      <!--        <v-row class="d-flex align-center">-->
      <!--          <v-col cols="12" md="4" sm="12">-->
      <!--            <router-link to="/">-->
      <!--              <img-->
      <!--                class="mt-2"-->
      <!--                width="200"-->
      <!--                src="../../../assets/logo.png"-->
      <!--                alt=""-->
      <!--              />-->
      <!--            </router-link>-->
      <!--          </v-col>-->

      <!--          <v-col cols="12" md="2" sm="12">-->
      <!--            <v-btn block color="red" size="small" rounded="0">Covid-19</v-btn>-->
      <!--          </v-col>-->

      <!--          <v-col cols="12" md="6" sm="12">-->

      <!--            <span class="ma-3 nav-menu-hover">-->
      <!--              <router-link-->
      <!--                to="/about-us"-->
      <!--                class="text-decoration-none text-black"-->
      <!--              >-->
      <!--                About-->
      <!--              </router-link>-->
      <!--            </span>-->

      <!--            <span class="ma-3">-->
      <!--              <router-link-->
      <!--                to="/projects"-->
      <!--                class="text-decoration-none text-black"-->
      <!--              >-->
      <!--                Projects-->
      <!--              </router-link>-->
      <!--            </span>-->

      <!--            <span class="ma-3">-->
      <!--              <router-link-->
      <!--                to="/contact-us"-->
      <!--                class="text-decoration-none text-black"-->
      <!--              >-->
      <!--                Contact-->
      <!--              </router-link>-->
      <!--            </span>-->

      <!--            <span class="ma-3">-->
      <!--              <router-link-->
      <!--                to="/our-process"-->
      <!--                class="text-decoration-none text-black"-->
      <!--              >-->
      <!--                Our Process-->
      <!--              </router-link>-->
      <!--            </span>-->

      <!--            <v-badge color="error" content="6" class="mx-2">-->
      <!--              <v-icon size="large" color="primary" @click="drawer = !drawer">mdi-bell</v-icon>-->
      <!--            </v-badge>-->

      <!--            <router-link-->
      <!--              to="/profile"-->
      <!--              class="text-decoration-none text-black"-->
      <!--              v-if="login"-->
      <!--            >-->
      <!--              <VIcon size="small" class="mx-2">-->
      <!--                <v-icon color="primary">mdi-account</v-icon>-->
      <!--              </VIcon>-->
      <!--            </router-link>-->

      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </div>-->

      <nav class="navs">
        <div class="items">
          <div class="logo" style="display: flex; align-items: center">
            <router-link to="/">
              <img width="200" src="../../../assets/logo.png" alt="logo" />
            </router-link>
          </div>
        </div>

        <div class="items">
          <v-btn color="red" size="small" rounded="0">Covid-19</v-btn>
        </div>

        <div class="items">
          <a class="text-decoration-none" href="#">
            <router-link to="/about-us" class="text-decoration-none text-black">
              About
            </router-link>
          </a>
          <div class="all-subs">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-avatar rounded="0" size="150">
                    <v-img
                      rounded="xl"
                      src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
                      cover
                    ></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="5">
                  <p
                    class="text-start text-white pa-3"
                    v-html="truncateDescription(data.description)"
                  ></p>
                </v-col>
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-3">
                    <router-link
                      to="/about-us"
                      class="text-decoration-none text-white"
                    >
                      About us
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/annual-report"
                      class="text-decoration-none text-white"
                    >
                      Annual Report
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/political-risk-insurances"
                      class="text-decoration-none text-white"
                    >
                      Political risk & Insurance
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/events"
                      class="text-decoration-none text-white"
                    >
                      Events
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items">
          <a class="text-decoration-none" href="#">
            <router-link to="/projects" class="text-decoration-none text-black">
              Projects
            </router-link>
          </a>
          <div class="all-subs">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-avatar rounded="0" size="150">
                    <v-img
                      rounded="xl"
                      src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
                      cover
                    ></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="5">
                  <p class="text-start text-white pa-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                  </p>
                </v-col>
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-3">
                    <router-link
                      to="/newest-project"
                      class="text-decoration-none text-white"
                    >
                      Newest Projects
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/feature-projects"
                      class="text-decoration-none text-white"
                    >
                      Featured Projects
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/closing-soon"
                      class="text-decoration-none text-white"
                    >
                      Closing Soon
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/crowd-funding"
                      class="text-decoration-none text-white"
                    >
                      Crowdfunding
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/contact-us"
              class="text-decoration-none text-black"
            >
              Contact
            </router-link>
          </a>
          <div class="all-subss"></div>
        </div>

        <div class="items">
          <a class="text-decoration-none" href="#">
            <router-link
              to="/our-process"
              class="text-decoration-none text-black"
            >
              Our Process
            </router-link>
          </a>
          <div class="all-subs">
            <div class="ma-5">
              <v-row>
                <v-col cols="12" md="2">
                  <v-avatar rounded="0" size="150">
                    <v-img
                      rounded="xl"
                      src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
                      cover
                    ></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="5">
                  <p class="text-start text-white pa-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                  </p>
                </v-col>
                <v-col cols="12" md="2"> </v-col>
                <v-col cols="12" md="3">
                  <p class="ma-3">
                    <router-link
                      to="/our-impact"
                      class="text-decoration-none text-white"
                    >
                      Our Impact
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/asia-pacific-web"
                      class="text-decoration-none text-white"
                    >
                      Asia Pacific
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/small-investment-program"
                      class="text-decoration-none text-white"
                    >
                      Small Investment Program
                    </router-link>
                  </p>

                  <p class="ma-3">
                    <router-link
                      to="/currency-web"
                      class="text-decoration-none text-white"
                    >
                      Currency
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <div class="items">
          <div class="d-flex">
            <v-badge color="error" content="6" class="mx-2">
              <v-icon size="large" color="primary" @click="drawer = !drawer"
                >mdi-bell</v-icon
              >
            </v-badge>

            <router-link
              to="/profile"
              class="text-decoration-none text-black"
              v-if="login"
            >
              <VIcon size="small" class="mx-2">
                <v-icon color="primary">mdi-account</v-icon>
              </VIcon>
            </router-link>
          </div>
        </div>
      </nav>

      <div>
        <slot />
      </div>

      <v-navigation-drawer
        v-model="drawer"
        temporary
        location="right"
        width="400"
      >
        <DrawerNotification :closeDrawer="closeDrawer" />
      </v-navigation-drawer>

      <FooterPage />
    </v-main>
  </v-layout>
</template>

<script>
import FooterPage from "./FooterPage";
import store from "../../../store/customerStore";
import { mapState } from "vuex";
import DrawerNotification from "@/views/website/components/DrawerNotification.vue";
import { ABOUT_US_BLOG } from "@/utils/customerApiUrl";

export default {
  name: "FrontendLayout",
  components: { DrawerNotification, FooterPage },
  computed: {
    ...mapState(["login", "profile"]),
  },
  data() {
    return {
      drawer: null,
      data: {},
    };
  },
  methods: {
    async logOut() {
      setTimeout(() => {
        this.$router.push("/projects");
        localStorage.removeItem("client_token");
        store.commit("SET_PROFILE", {});
        store.commit("SET_LOGIN", false);
      }, 1000);
    },

    closeDrawer() {
      this.drawer = null;
    },

    async fetchData() {
      this.axios
        .get(ABOUT_US_BLOG)
        .then((response) => {
          let { data } = response.data;
          this.data = data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },

    truncateDescription(desc) {
      if (desc) {
        return desc.length > 200 ? desc.substring(0, 300) + "..." : desc;
      }
    },
  },

  created() {
    this.$store.dispatch("getUserProfile");

    this.$store.dispatch("getBankType");

    let token = localStorage.getItem("token");
    if (token === null) {
      localStorage.setItem("token", "token");
    }

    this.fetchData();
  },
};
</script>

<style>
.margin-auto {
  margin: auto;
  padding: 0 150px;
}

.text-content {
  font-size: 27px;
  color: #505050;
}

.navs {
  position: relative;
  margin: auto;
  padding: 0 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.items {
  padding-top: 20px;
}
.items a {
  display: block;
  width: 100%;
  color: black;
  font-weight: bold;
}
.items .all-subs {
  display: none;
  position: absolute;
  background: #1867c0;
  width: 100%;
  left: 0;
  z-index: 1;
  padding: 20px;
}
.items .sub-items {
  padding: 50px;
  z-index: 1;
  width: 100%;
}
.items:hover {
  text-decoration: underline;
}
.items:hover > .all-subs {
  display: block;
}

@media screen and (max-width: 768px) {
  .margin-auto {
    margin: auto;
    padding: 5px;
  }

  .navs {
    margin: auto;
    padding: 5px;
  }

  .main-title {
    display: none;
  }

  .profile-center {
    text-align: center;
  }

  .text-content {
    font-size: 12px;
  }

  .menu-grid {
    grid-template-columns: 1fr;
  }
}
</style>
