import axios from "axios";
import {BASE_URL} from "@/utils/customerApiUrl";

let token = localStorage.getItem("client_token");

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ` + token,
    },
});

export default axiosInstance;