<template>
    <MainLayout>

        <v-row>
            <v-col md="8" sm="12" class="text-grey">
                <v-row>
                    <v-col
                            class="flex-grow-0 flex-shrink-0"
                            cols="3"
                    >
                        <v-btn
                                class="text-capitalize"
                                rounded="0"
                                elevation="0"
                                color="grey-lighten-2"
                                block
                        >
                            Filter
                        </v-btn>
                    </v-col>

                    <v-col
                            class="flex-grow-0 flex-shrink-0"
                            cols="3"
                    >
                        <v-select
                                rounded="0"
                                variant="outlined"
                                density="compact"
                                label="Select Date Range"
                                :items="['All Member']"
                                menu-icon="mdi-chevron-right"
                        ></v-select>
                    </v-col>

                </v-row>
            </v-col>
            <v-col md="4" sm="12">
                <v-row>
                    <v-col md="6">
                        <v-text-field
                                rounded="0"
                                placeholder="..."
                                variant="outlined"
                                density="compact"
                        ></v-text-field>
                    </v-col>
                    <v-col md="6">
                        <v-btn
                                class="text-capitalize"
                                rounded="0"
                                elevation="0"
                                color="grey-lighten-2"
                                block
                        >
                            Search File Name
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                    v-for="n in 9"
                    :key="n"
                    class="d-flex child-flex"
                    cols="3"
            >
                <v-img
                        :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                        :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                        aspect-ratio="1"
                        class="bg-grey-lighten-2"
                        cover
                >
                    <template v-slot:placeholder>
                        <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                        >
                            <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </v-col>
        </v-row>


        <div class="text-center mt-5">
            <v-pagination
                    v-model="page"
                    :length="15"
                    :total-visible="7"
                    size="small"
            ></v-pagination>
        </div>

    </MainLayout>
</template>

<script>
    import MainLayout from "../../components/MainLayout";
    export default {
        name: "MediaPage",
        components: {MainLayout},
        data () {
            return {
                page: 1,
            }
        },
    }
</script>

<style scoped>

</style>