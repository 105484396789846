import { defineStore } from "pinia";
import axios from "axios";
import { URL_STAFF_LOGOUT, URL_STAFF_LOGIN } from "@/utils/apiUrl";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    id: localStorage.getItem("id") || null,
    staffName: localStorage.getItem("staffName") || null,
    imgProfile: localStorage.getItem("img") || null,
    admin: localStorage.getItem("admin") || null,
    allCountry: localStorage.getItem("allCountry") || null,
    countryId: localStorage.getItem("countryId") || null,
    token: localStorage.getItem("token") || null,
    countryCode: localStorage.getItem("code") || null,
  }),
  getters: {},
  actions: {
    async login(email, password) {
      try {
        const url = URL_STAFF_LOGIN;
        const data = {
          email: email,
          password: password,
        };

        await axios
          .post(url, data)
          .then((response) => {
            if (response.data.statusCode === 400) {
              return response.data.message;
            } else {
              this.id = response.data.id;
              this.staffName = response.data.name;
              this.imgProfile = response.data.img;
              this.admin = response.data.admin;
              this.allCountry = response.data.allCountry;
              this.countryId = response.data.countryId;
              this.token = response.data.access_token;
              this.countryCode = response.data.countryCode;

              // add to localStorage
              localStorage.setItem("id", this.id);
              localStorage.setItem("staffName", this.staffName);
              localStorage.setItem("img", this.imgProfile);
              localStorage.setItem("admin", this.admin);
              localStorage.setItem("allCountry", this.allCountry);
              localStorage.setItem("countryId", this.countryId);
              localStorage.setItem("token", this.token);
              localStorage.setItem("code", this.countryCode);

              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.token}`;
            }
          })
          .catch((error) => {
            if (error.response.data.statusCode === 400) {
              localStorage.setItem("msgInvalid", error.response.data.message);
              return error.response.data.message;
            } else if (error.request) {
              localStorage.setItem("msgInvalid", error.message);
              return error.request + ", " + error.message;
            }
            //console.error("record updated was an error!", error)
          });
      } catch (error) {
        console.log("Login failed.", error);
      }
    },

    async logout(id) {
      await axios.patch(URL_STAFF_LOGOUT + id).then((response) => {
        console.log(response.data.message);
      });

      this.id = null;
      this.staffName = null;
      this.imgProfile = null;
      this.admin = null;
      this.allCountry = null;
      this.countryId = null;
      this.token = null;
      this.countryCode = null;

      // Remove from localStorage
      localStorage.removeItem("id");
      localStorage.removeItem("staffName");
      localStorage.removeItem("img");
      localStorage.removeItem("admin");
      localStorage.removeItem("allCountry");
      localStorage.removeItem("countryId");
      localStorage.removeItem("token");
      localStorage.removeItem("code");

      delete axios.defaults.headers.common["Authorization"];
    },
  },
});
