<script>
export default {
  name: 'CardNews',
  data() {
    return {};
  },
};
</script>

<template>
  <div class="margin-auto">
    <br />
    <VCard class="py-8 elevation-0">
      <VRow>
        <VCol cols="12" md="6">
          <p class="my-10 text-h5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos.
          </p>
        </VCol>
        <VCol cols="12" md="6">
          <VCard height="400" rounded="xl" elevation="0">
            <v-img
              src="https://media.istockphoto.com/id/846489122/photo/responsive-web-design-website-wireframe-sketch-layout-on-computer-screen.jpg?s=612x612&w=0&k=20&c=mtPGkXm8Sj9AHcg1pEAF8tAdSpn-gRyb-UJnpsYn8bI="
              cover
            ></v-img>
          </VCard>
        </VCol>
      </VRow>
    </VCard>
  </div>
</template>

<style scoped>

</style>