<script>
export default {
  props: [
    'lineHeader', 'header', 'columns',
  ],
  name: 'RowFacts',
};
</script>

<template>
  <v-divider class="mx-4 mb-1" v-if="lineHeader"></v-divider>
  <div class="d-flex flex-row">
    <v-sheet class="pa-2" width="50%" color="light-blue-darken-4">
      <h4 class="text-end text-uppercase"> {{ header }}</h4>
    </v-sheet>
    <v-sheet class="pa-2" width="50%" color="light-blue-darken-4">
      <h4 class="font-weight-regular"> {{ columns }}</h4>
    </v-sheet>
  </div>
  <v-divider class="mx-4 mb-1"></v-divider>
</template>

<style scoped>

</style>