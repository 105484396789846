<script>

import ProjectFacts from '@/views/website/projects/components/ProjectFacts.vue';
import FrontendLayout from '@/views/website/components/FrontendLayout.vue';
import RelatedNews from '@/views/website/projects/components/RelatedNews.vue';
import CardShareLink from '@/views/website/projects/components/CardShareLink.vue';
import { formatDate, formatPrice } from '@/utils/function';

export default {
  name: 'ProjectDetailsPage',
  components: { CardShareLink, RelatedNews, ProjectFacts, FrontendLayout },
  data() {
    return {
      formatDate: formatDate,
      formatPrice: formatPrice,
      project: null,
    };
  },
  created() {
    let project = sessionStorage.getItem('project');
    if (project) {
      project = JSON.parse(project);
      this.project = project;
      //console.log(project);
    }
  },
};
</script>

<template>
  <FrontendLayout>

    <div class="bg-grey-lighten-4">
      <VCard min-height="200" rounded="0">
        <div class="text-center py-5">
          <h3 class="text-orange text-uppercase">
            Project | Country
          </h3>
          <br />
          <h1 class="text-primary">
            {{ project.procurement_category }}
          </h1>
          <br />
          <h3 class="text-blue font-weight-regular">
            ${{ formatPrice(project.all_amount_invest) }} Million | {{ project.Sector.name }} |
            {{ project.Sector.project_brief }} | {{ project.project_status }}
          </h3>
        </div>
      </VCard>

      <VRow class="margin-auto">
        <VCol cols="12" md="7">
          <h2>
            Project Description
          </h2>
          <br />
          <h4>
            <p>
              {{ project.description_project }}
            </p>
          </h4>
          <br />
          <p>
            The Guarantee Was Cancelled On {{ formatDate(project.date_spg_disclosed, 'MMM DD, YYYY') }}.
          </p>
        </VCol>

        <VCol cols="12" md="5">
          <ProjectFacts :project="project" />
          <RelatedNews />
          <CardShareLink />
        </VCol>
      </VRow>

      <br />
    </div>

  </FrontendLayout>
</template>

<style scoped>

</style>