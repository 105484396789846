<template>
  <div>
    <BoardCard />

    <br />
    <br />
    <MemberLevel />
    <br />

    <v-divider />
    <br />

    <FundTransfers />
  </div>
</template>

<script>
import BoardCard from "./BoardCard";
import FundTransfers from "./FundTransfers";
import MemberLevel from "./MemberLevel";
export default {
  name: "PortfoliosTab",
  components: {
    BoardCard,
    FundTransfers,
    MemberLevel,
  },
};
</script>

<style scoped>
.td {
  padding: 10px;
}
</style>
