export const messages = {
    en: {
        user_profile: 'User Profile',
        home_upgrade: 'Home || Upgrade',
        available_balance: 'Available Balance',
        account_balance: 'Account Balance',
    },
    ja: {
        user_profile: 'りんご一つ',
        home_upgrade: 'りんご一つ || りんご一つ',
        available_balance: 'Available Balance',
        account_balance: 'Account Balance'
    }
};