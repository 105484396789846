<template>
  <div>
    <table width="100%" cellspacing="0" cellpadding="0">
      <tr class="bg-blue">
        <td class="td">Member Level</td>
        <td class="td text-center">Total Value</td>
        <td class="td text-center">Ratio</td>
        <td class="td text-center">Transfer & Upgrade</td>
        <td class="td text-center">Service Fee</td>
      </tr>

      <tr style="background: #fff">
        <td class="td">Ordinary</td>
        <td class="td text-center">$ -</td>
        <td class="td text-center">0</td>
        <td class="td text-center">
          <v-icon color="red">mdi-minus-circle</v-icon>
        </td>
        <td class="td text-center">10%</td>
      </tr>

      <tr
        style="background: #fff"
        v-for="(level, index) in memberLevel"
        v-bind:key="index"
      >
        <td class="td">
          <v-icon
            v-for="i in parseInt(level.level)"
            :key="i"
            size="18"
            color="orange"
            icon="mdi-star"
          ></v-icon>
          <v-icon
            v-for="i in 5 - level.level"
            :key="i"
            size="18"
            color="grey"
            icon="mdi-star"
          ></v-icon>
        </td>
        <td class="td text-center">$ - {{ formatPrice(level.totalValue) }}</td>
        <td class="td text-center">
          {{ level.ratio }}
        </td>
        <td class="td text-center">
          <v-icon color="green">mdi-check-circle</v-icon>
        </td>
        <td class="td text-center">{{ level.fee }}%</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "MemberLevel",
  data() {
    return {
      memberLevel: [
        {
          level: 1,
          totalValue: 100,
          ratio: 0.25,
          status: true,
          fee: 5,
        },
        {
          level: 2,
          totalValue: 1000,
          ratio: 0.25,
          status: true,
          fee: 4,
        },
        {
          level: 3,
          totalValue: 10000,
          ratio: 1,
          status: true,
          fee: 3,
        },
        {
          level: 4,
          totalValue: 30000,
          ratio: 2,
          status: true,
          fee: 2,
        },
        {
          level: 5,
          totalValue: 50000,
          ratio: 3,
          status: true,
          fee: 1,
        },
      ],
    };
  },
  methods: {
    formatPrice(price = 0) {
      return price.toLocaleString();
    },
  },
};
</script>
<style scoped>
.td {
  padding: 10px;
}
</style>
