<template>
  <Suspense timeout="0">
    <router-view />
  </Suspense>
</template>

<script>
export default {
  name: 'App',
  created() {
    document.title = 'Miga World Bank';
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
