import {createStore} from "vuex";
import {BANK_TYPE, CUSTOMER_PROFILE} from "@/utils/customerApiUrl";
import axiosInstance from "@/servicesAxios/axiosInstance";

let token = localStorage.getItem("client_token");

export default createStore({
    state: {
        login: false,
        profile: {
            email: "",
            firstName: "",
            lastName: "",
            name: "",
            profile: "",
            numberLevel: "",
            phone: "",
            status: "",
            myReferal: "",
            identity: "",
            expireDate: "",
            gender: "",
            nationality: "",
            countryOfBirth: "",
            cityOfBirth: "",
            dateOfBirth: "",
            occupation: "",
            typeCard: "",
            cardNumber: "",
            frontCard: "",
            backCard: "",
            referal: "",
            referalLink: "",
        },
        wallet: [
            {
                id: 0,
                availableBalance: 0,
                accountBalance: 0,
                bankAccount: "N/A",
                walletType: "",
                pin_code: '',
            },
        ],
        socialMedia: {
            facebookLink: null,
            telegramLink: null,
            whatsApp: null,
            linkedIn: null,
            twitterLink: null,
            remark: null,
        },
        bankType: [
            [
                {
                    "id": 1,
                    "bankName": "ABA Bank",
                    "logoBank": "https://cdn6.aptoide.com/imgs/2/a/6/2a6b391e2053870eac06539bd99d51a6_icon.png",
                    "paymentMethod": "USDT"
                },
                {
                    "id": 2,
                    "bankName": "Wing Bank",
                    "logoBank": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0bm5ePMqe3PDWJChqdXheEWyJbx9RwAi6t4dxBPSbvy-57L1nAx8fP2zVz71_9L5sD2I&usqp=CAU",
                    "paymentMethod": "USDT"
                },
                {
                    "id": 3,
                    "bankName": "Bitcon",
                    "logoBank": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWc-OjffrnH_evPd-49v8XxFi9MpiSK9rhDg&s",
                    "paymentMethod": "USDT"
                },
                {
                    "id": 4,
                    "bankName": "True Money",
                    "logoBank": "https://play-lh.googleusercontent.com/C4Tp0Z5AjBifUrdaIUe53pBT_pDTqadeMvtjOeybWpF9BVJzXin7dIKZowwoY0MWcg",
                    "paymentMethod": "USDT"
                }
            ]
        ],
        bankAccountWithdraw: [
            {
                "id": 1,
                "bankTypeId": 6,
                "fullName": "Nouen Thary",
                "typeBank": null,
                "checkType": "Checking",
                "routing": "12343678",
                "usdtQr": "",
                "usdtLink": "11",
                "wallet": null,
                "cardNumber": "12343678",
                "cvc": null,
                "expire": null,
                "createdAt": "2024-09-04T01:13:39.568Z",
                "updatedAt": "2024-09-04T01:13:39.569Z",
                "is_active": 1,
                "customer_id": 1
            },
        ]
    },
    getters: {
        getProfile: (state) => state.profile,
    },
    mutations: {
        SET_LOGIN(state, payload) {
            state.login = payload;
        },
        SET_PROFILE(state, payload) {
            state.profile = payload;
        },
        SET_WALLET(state, payload) {
            state.wallet = payload;
        },
        SET_SOCIAL_MEDIA(state, payload) {
            state.socialMedia = payload;
        },
        SET_BANK_TYPE(state, payload) {
            state.bankType = payload;
        },
        SET_BANK_ACCOUNT_WITHDRAW(state, payload) {
            state.bankAccountWithdraw = payload;
        },
    },
    actions: {
        async getUserProfile({commit}) {
            if(token) {
                axiosInstance
                  .get(CUSTOMER_PROFILE)
                  .then((response) => {
                      if (response.data !== null) {
                          const { user, wallet, socialMedia, bankAccountWithdraw } = response.data;
                          commit("SET_PROFILE", user);
                          commit("SET_WALLET", wallet);
                          commit("SET_SOCIAL_MEDIA", socialMedia[0]);
                          commit("SET_LOGIN", true);
                          commit("SET_BANK_ACCOUNT_WITHDRAW", bankAccountWithdraw);
                      }
                  })
                  .catch((error) => {
                      commit("SET_LOGIN", false);
                      console.log("profile : ", error);
                  });
            }
        },

        async getBankType({commit}) {
            if(token) {
                axiosInstance
                  .get(BANK_TYPE + "?page=0&pageSize=50")
                  .then((response) => {
                      if (response.data !== null) {
                          const { data } = response;
                          commit("SET_BANK_TYPE", data);
                      }
                  })
                  .catch((error) => {
                      commit("SET_LOGIN", false);
                      console.log("bank type : ", error);
                  });
            }
        },
    },
    modules: {},
});
