<template>
  <!--page 1-->
  <div v-if="index == 1">
    <h3 class="text-center">Let's open your migawb account</h3>

    <br />

    <v-card width="" elevation="" rounded="">
      <v-toolbar color="white">
        <v-btn icon="mdi-arrow-left" @click="nextStep(4)"></v-btn>
      </v-toolbar>
      <p class="mt-5 text-center">steps to get set up</p>

      <div class="">
        <v-list class="text-start">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-avatar color="grey-lighten-3" size="small">
                  <v-icon
                    icon="mdi-card-account-details-outline"
                    size="15"
                  ></v-icon>
                </v-avatar>

                <v-icon size="12" color="green" class="mt-5">
                  mdi-check-circle</v-icon
                >

                <span class="text-light-green-darken-4">
                  Fill in your personal details
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-avatar color="grey-lighten-3" size="small">
                  <v-icon icon="mdi-account-circle-outline" size="15"></v-icon>
                </v-avatar>
                <v-icon
                  size="12"
                  v-if="profile.status.toLowerCase() === 'done'"
                  color="green"
                  class="mt-5"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-if="profile.status.toLowerCase() !== 'done'"
                  size="12"
                  color="amber-accent-4"
                  class="mt-5"
                >
                  mdi-clock-time-eight
                </v-icon>
                <span class="text-light-green-darken-4">
                  Verify your identity
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="ma-4">
          <v-btn
            v-if="profile.status.toLowerCase() !== 'done'"
            color="primary"
            class="pa-4 text-none"
            block
            rounded="xl"
            @click="nextStep(2)"
          >
            Continue
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
  <!--page 1-->

  <!--page 2-->
  <v-card v-if="index == 2">
    <v-form ref="form">
      <v-toolbar color="white">
        <v-btn icon="mdi-arrow-left" @click="nextStep(1)"></v-btn>

        <span>Account verification</span>
      </v-toolbar>

      <v-row class="mx-5">
        <v-col cols="12" sm="12" md="5">
          <p class="text-start">Name</p>
          <v-text-field
            :rules="fullNameRules"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            v-model="account_verification.name"
            rounded="xl"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="5">
          <p class="text-start">Gender</p>
          <v-select
            :rules="genderRules"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            rounded="xl"
            :items="['Male', 'Female', 'Other']"
            v-model="account_verification.gender"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <p class="text-start">Identification Type</p>
          <v-select
            :rules="cardTypeRules"
            v-model="account_verification.typeCard"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            rounded="xl"
            :items="['Identity Card', 'Passport', 'Driver License']"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <p class="text-start">Identification No.</p>
          <v-text-field
            :rules="cardNumberRules"
            v-model="account_verification.cardNumber"
            placeholder="- - - - - - "
            variant="outlined"
            density="compact"
            rounded="xl"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <p class="text-start">Expire Date</p>
          <v-text-field
            :rules="expireDateRules"
            v-model="account_verification.expireDate"
            placeholder="DD-MM-YYYY"
            variant="outlined"
            density="compact"
            rounded="xl"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <p class="text-start">Upload your Id / Passport / Drive Licence</p>

          <v-row>
            <v-col cols="12" md="6">
              <p class="text-center">Front Image</p>
              <img
                :src="previewImageFront"
                width="100%"
                height="200px"
                onerror="this.src='https://hushforms.com/f/photo/frrf.id.can'"
                alt=""
              />
              <v-btn
                onclick="document.getElementById('front_image').click()"
                density="compact"
                color="primary"
                class="text-none"
                rounded="xl"
                width="100"
              >
                Upload
              </v-btn>
              <input
                style="display: none"
                type="file"
                id="front_image"
                @change="frontImage"
              />
            </v-col>

            <v-col cols="6">
              <p class="text-center">Back Image</p>
              <img
                :src="previewImageBack"
                width="100%"
                onerror="this.src='https://hushforms.com/f/photo/frrf.id.can'"
                height="200px"
                alt=""
              />
              <v-btn
                onclick="document.getElementById('back_image').click()"
                density="compact"
                color="primary"
                class="text-none"
                rounded="xl"
                width="100"
              >
                Upload
              </v-btn>
              <input
                style="display: none"
                type="file"
                id="back_image"
                @change="backImage"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
          class="d-flex justify-lg-space-between"
        >
          <v-btn
            block
            color="primary"
            class="text-none"
            rounded="xl"
            width="100"
            @click="submitNextUpdate()"
          >
            Next
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <br />
  </v-card>
  <!--page 2-->

  <!--page 3-->
  <v-card v-if="index == 3">
    <v-toolbar color="white">
      <v-btn icon="mdi-arrow-left" @click="nextStep(2)"></v-btn>

      <span>Account verification</span>
    </v-toolbar>

    <v-row class="mx-5">

       <v-col cols="12" md="12" sm="12">
        <v-alert
          variant="outlined"
          color="error"
          v-if="message"
          :text="message"
          title="Error"
          border="end"
          density="compact"
          border-color="error"
          type="error"
        ></v-alert>

        <v-alert
          variant="outlined"
          color="success"
          type="success"
          v-if="success"
          :text="success"
          title="Success"
          border="end"
          density="compact"
          border-color="success"
        ></v-alert>
      </v-col>

      <v-col cols="12" sm="12" md="8">
        <p class="text-start">Referral ID</p>
        <v-text-field
          v-model="account_verification.referal"
          placeholder="xxx-xxx-xxx-xxx"
          variant="outlined"
          density="compact"
          rounded="xl"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="12">
        <h2>Enter a referral link</h2>

        <p>
          <span class="text-grey-darken-1">
            If you forget to sign up with a link, you can add it here. You and
            your friend will be a step closer to your referral rewards.
          </span>
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="8">
        <p class="text-start">Enter an invite link</p>
        <v-text-field
          v-model="account_verification.referalLink"
          placeholder="www.example.com/1234"
          variant="outlined"
          density="compact"
          rounded="xl"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="12" md="12" class="d-flex justify-sm-end">
        <v-btn
          :loading="loading"
          color="primary"
          class="text-none"
          rounded="xl"
          width="100"
          @click="submitSaveAccountVerification()"
        >
          Submit
        </v-btn>
      </v-col>
    </v-row>

    <br />
  </v-card>
  <!--page 3-->

  <!--page 4-->
  <div v-if="index == 4">
    <table class="my-table">
      <tr>
        <td class="td">Profile info</td>
        <td class="td text-center">
          <v-btn
            class="text-none"
            variant="text"
            size="small"
            @click="showModal()"
            >Update</v-btn
          >
        </td>
      </tr>
      <tr>
        <td class="td">
          <div class="d-flex">
            Verify account
            <v-spacer />
            <v-chip
              :color="profile.status === 'pending' ? 'primary' : 'green'"
              size="small"
              variant="tonal"
            >
              {{
                profile.status === "pending"
                  ? "Is Review"
                  : "Is " + profile.status
              }}
            </v-chip>
          </div>
        </td>
        <td class="td text-center">
          <v-btn
            class="text-none"
            variant="text"
            size="small"
            @click="nextStep(1)"
            >Verify</v-btn
          >
        </td>
      </tr>
      <tr>
        <td class="td">Notifications</td>
        <td class="td text-center">
          <v-btn icon="mdi-chevron-right" variant="text" size="small"></v-btn>
        </td>
      </tr>
      <tr>
        <td class="td">Service agreement</td>
        <td class="td text-center">
          <v-btn icon="mdi-chevron-right" variant="text" size="small"></v-btn>
        </td>
      </tr>
      <tr>
        <td class="td">Privacy</td>
        <td class="td text-center">
          <v-btn icon="mdi-chevron-right" variant="text" size="small"></v-btn>
        </td>
      </tr>
    </table>
  </div>
  <!--page 4-->

  <!--form-->
  <div class="pa-4 text-center">
    <v-dialog v-model="dialog" max-width="800">
      <v-card rounded="xl">
        <v-card-title>
          <div class="d-flex justify-lg-space-between my-3">
            <p></p>
            <h4 class="text-grey-darken-1">Profile Information</h4>
            <v-btn
              density="compact"
              icon="mdi-close"
              color="red"
              @click="dialog = false"
            ></v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form ref="formProfile">
            <v-alert
              variant="outlined"
              color="error"
              v-if="message"
              :text="message"
              title="Error"
              border="end"
              density="compact"
              border-color="error"
              type="error"
            ></v-alert>

            <v-alert
              variant="outlined"
              color="success"
              type="success"
              v-if="success"
              :text="success"
              title="Success"
              border="end"
              density="compact"
              border-color="success"
            ></v-alert>

            <v-row dense>
              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">First Name</p>
                <v-text-field
                  v-model="form.firstName"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="First Name"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Last Name</p>
                <v-text-field
                  v-model="form.lastName"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Last Name"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Email</p>
                <v-text-field
                  disabled
                  v-model="form.email"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Email"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Phone Number</p>
                <v-text-field
                  disabled
                  v-model="form.phone"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Phone Number"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <p class="ma-1">Facebook Link</p>
                <v-text-field
                  v-model="form.facebookLink"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Facebook Link"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <p class="ma-1">Telegram Link</p>
                <v-text-field
                  v-model="form.telegramLink"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Telegram Link"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <p class="ma-1">Twitter Link</p>
                <v-text-field
                  v-model="form.twitterLink"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Twitter Link"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Current Password</p>
                <v-text-field
                  v-model="form.oldPassword"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Current Password"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">New Password</p>
                <v-text-field
                  :rules="form.oldPassword ? newPasswordRules : []"
                  v-model="form.password"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="New Password"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12"> </v-col>

              <v-col cols="12" md="6" sm="12">
                <p class="ma-1">Confirm Password</p>
                <v-text-field
                  :rules="form.oldPassword ? [newPasswordRules, matchingPasswords] : []"
                  v-model="form.confirmPassword"
                  density="compact"
                  variant="outlined"
                  required
                  placeholder="Confirm Password"
                  rounded="xl"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <v-btn
                  color="primary"
                  :loading="loading"
                  text="Update"
                  variant="flat"
                  class="text-none"
                  rounded="xl"
                  @click="submitUpdate()"
                  width="150"
                ></v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <!--form-->
</template>

<script>
import { mapState } from "vuex";
import {
  UPDATE_PROFILE,
  UPDATE_ACCOUNT_VERIFICATION,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PATH,
} from "@/utils/customerApiUrl";
import axios from "axios";
import {
  validateDate,
  validatePassword,
  fieldRequired,
} from "@/utils/function";

let keyLocalStorage = "account_setting_index";

let identity = "https://hushforms.com/f/photo/frrf.id.can";

let allRules = {
  newPasswordRules: validatePassword,
  expireDateRules: validateDate("expire date"),
  fullNameRules: fieldRequired("full name"),
  genderRules: fieldRequired("gender"),
  cardTypeRules: fieldRequired("card type"),
  cardNumberRules: fieldRequired("card number"),
};

export default {
  name: "AccountSettings",
  computed: {
    ...mapState(["profile", "socialMedia"]),
  },
  data: () => ({
    index: 1,
    dialog: false,
    form: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      facebookLink: "",
      telegramLink: "",
      twitterLink: "",
      whatsApp: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    previewImageFront: identity,
    previewImageBack: identity,
    account_verification: {
      name: "",
      gender: "",
      typeCard: "",
      cardNumber: "",
      expireDate: "",
      frontCard: "",
      backCard: "",
      referal: "",
      referalLink: "",
    },
    loading: false,
    message: "",
    success: "",
    //
    ...allRules,
  }),
  methods: {
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getLocalStorage(key) {
      return localStorage.getItem(key);
    },
    nextStep(index) {
      this.index = index;
      this.setLocalStorage(keyLocalStorage, index);
    },
    showModal() {
      this.form = {
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        email: this.profile.email,
        phoneNumber: this.profile.phoneNumber,
        facebookLink: this.socialMedia.facebookLink,
        telegramLink: this.socialMedia.telegramLink,
        twitterLink: this.socialMedia.twitterLink,
        whatsApp: this.socialMedia.whatsApp,
        oldPassword: "",
        password: "",
        confirmPassword: "",
      };
      this.dialog = true;
    },

    async submitUpdate() {
      const { valid } = await this.$refs.formProfile.validate();

      if (valid) {
        this.loading = true;

        this.axios
          .post(UPDATE_PROFILE, this.form)
          .then((response) => {
            console.error("Failed to", response);
            this.success = response.data.message;
            this.loading = false;
            setTimeout(() => {
              //this.dialog = false;
              this.message = "";
              this.success = "";
            }, 4000);
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.loading = false;
            console.error("Failed to", error);
          });
      }
    },

    async frontImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImageFront = e.target.result;
        console.log(this.previewImageFront);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(UPLOAD_PHOTO, data).then((response) => {
        this.account_verification.frontCard = response.data.filename;
        console.log("Image has been uploaded.");
      });
    },

    async backImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImageBack = e.target.result;
        console.log(this.previewImageBack);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(UPLOAD_PHOTO, data).then((response) => {
        this.account_verification.backCard = response.data.filename;
        console.log("Image has been uploaded.");
      });
    },

    async submitNextUpdate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.nextStep(3);
      }
    },
    async submitSaveAccountVerification() {
      this.loading = true;
      //console.log(this.form);
      this.axios
        .post(UPDATE_ACCOUNT_VERIFICATION, this.account_verification)
        .then((response) => {
          //console.error("response to", response);
          this.success = response.data.message;
          setTimeout(() => {
            this.loading = false;
            this.message = "";
            this.success = "";
            //this.nextStep(4);
          }, 4000);
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.loading = false;
          console.error("Failed to", error.response.data.message);
        });
    },

    matchingPasswords: function () {
      if (this.form.password === this.form.confirmPassword) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
  },
  created() {
    this.index = this.getLocalStorage(keyLocalStorage) || 1;

    setTimeout(() => {
      this.account_verification = {
        name: this.profile.name,
        gender: this.profile.gender,
        typeCard: this.profile.typeCard,
        cardNumber: this.profile.cardNumber,
        expireDate: this.profile.expireDate,
        frontCard: this.profile.frontCard,
        backCard: this.profile.backCard,
        referal: this.profile.referal,
        referalLink: this.profile.referalLink,
      };
      this.previewImageFront = this.profile.frontCard
        ? UPLOAD_PHOTO_PATH + this.profile.frontCard
        : "https://hushforms.com/f/photo/frrf.id.can";
      this.previewImageBack = this.profile.backCard
        ? UPLOAD_PHOTO_PATH + this.profile.backCard
        : "https://hushforms.com/f/photo/frrf.id.can";
    }, 2000);
  },
};
</script>

<style scoped>
.my-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
