<template>
    <v-card height="480px" width="" class="pa-10" rounded="xl">

        <v-row>
            <v-col cols="12" md="6">
                <label> NAME </label>
                <v-text-field
                        rounded="xl"
                        label=""
                        placeholder="Enter Name"
                        variant="outlined"
                        density="compact"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6"></v-col>

             <v-col cols="12" md="6">
                <label> NAME </label>
                <v-text-field
                        rounded="xl"
                        label=""
                        placeholder="Enter Name"
                        variant="outlined"
                        density="compact"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6"></v-col>


            <v-col cols="12" md="6">
                <label> NAME </label>
                <v-text-field
                        rounded="xl"
                        label=""
                        placeholder="Enter Name"
                        variant="outlined"
                        density="compact"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6"></v-col>

            <v-col cols="12" md="6">
                <label> NAME </label>
                <v-text-field
                        rounded="xl"
                        label=""
                        placeholder="Enter Name"
                        variant="outlined"
                        density="compact"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6"></v-col>


            <v-col cols="12" md="6">
                <label> NAME </label>
                <v-text-field
                        rounded="xl"
                        label=""
                        placeholder="Enter Name"
                        variant="outlined"
                        density="compact"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6"></v-col>

            <v-col cols="12" md="6">
                <label> NAME </label>
                <v-text-field
                        rounded="xl"
                        label=""
                        placeholder="Enter Name"
                        variant="outlined"
                        density="compact"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6"></v-col>

        </v-row>

        <v-btn
                class="text-capitalize"
                color="blue-darken-3"
                rounded="xl"
                size="large"
                variant="tonal"
                width="140"
        >
            Deposit
        </v-btn>
    </v-card>
</template>

<script>
    export default {
        name: "DepositTab"
    }
</script>

<style scoped>

</style>