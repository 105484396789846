<template>
  <v-row>
    <v-col
      md="3"
      sm="12"
      cols="12"
      v-for="(row, index) in tab"
      v-bind:key="index"
    >
      <v-btn
        size="x-large"
        block
        rounded="xl"
        class="text-none mx-2"
        width="240"
        @click="setBtnTab(row)"
        :color="btnTab === row ? 'primary' : 'grey-lighten-2'"
        elevation="0"
      >
        {{ row }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
let tab = [
  "Portfolios",
  "Deposit",
  "Withdraw",
  "Referral",
  "Upgrade",
  "Exchange",
  "Statement",
  "Settings",
];

export default {
  name: "ButtonTab",
  props: ["active", "set_tab"],
  data: function () {
    return {
      tab: tab,
      btnTab: this.active,
    };
  },
  methods: {
    setBtnTab(type) {
      this.btnTab = type;
      this.set_tab(type);
      this.$router.push({
        path: "/profile",
        query: {
          ...this.$route.query,
          tab: type,
        },
      });
    },
  },
  created() {
    this.btnTab = this.$route.query.tab || tab[0];
  },
};
</script>

<style scoped></style>
