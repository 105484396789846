<script>
import RowFacts from '@/views/website/projects/components/RowFacts.vue';
import { mapState } from 'vuex';
import { formatDate, formatPrice } from '@/utils/function';

export default {
  name: 'ProjectFacts',
  components: { RowFacts },
  computed: {
    ...mapState(['profile']),
  },
  props: ['project'],
  data() {
    return {
      formatPrice: formatPrice,
      firstRow: [
        'Project Id',
        'Guarantee Holder',
        'Investor Country',
        'Environment Category',
        'Date SPG Disclosed',
        'Board Approval Date',
        'Project Type',
        'Fiscal Year',
      ],
      secondRow: [
        'xxxx',
        'xxxx',
        'Country',
        'xxxx',
        'xxxx',
        'xxxx',
        'xxxx',
        'xxxx',
      ],
      dialog: false,
      form: {
        settlement_interest: null,
        accountName: '000 121 012',
      },
      settlement_interests: [
        'Daily', 'Weekly', 'Monthly', 'Quarterly', 'Semi-Annually',
      ],
    };
  },
  created() {
    let project = { ...this.project }; console.log(project);
    this.secondRow = [
      this.project.project_id,
      this.project.guarantee_holder,
      this.project.investor_country,
      this.project.environmental_category,
      formatDate(this.project.date_spg_disclosed, 'MMM DD, YYYY'),
      formatDate(this.project.board_approval_date, 'MMM DD, YYYY'),
      this.project.project_type,
      this.project.fiscal_year,
    ];
  },
};
</script>

<template>
  <v-card
    class="mx-auto my-12"
    color="light-blue-darken-4"
    rounded="xl"
  >
    <v-img
      height="250"
      src="https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk="
      cover
    ></v-img>

    <v-card-item>
      <v-card-title>Project Facts</v-card-title>
    </v-card-item>

    <template v-for="(row,index) in firstRow.length" v-bind:key="row">
      <RowFacts :lineHeader="index === 0" :header="firstRow[index]" :columns="secondRow[index]" />
    </template>

    <br />
    <v-card-actions class="mx-2">
      <v-btn
        class="bg-orange"
        block
        size="large"
        rounded="xl"
        variant="outlined"
        @click="dialog=true"
        :disabled="!profile.email"
      >
        <h4>
          Invest Now
        </h4>
      </v-btn>
    </v-card-actions>
    <br />
  </v-card>

  <v-dialog
    v-model="dialog"
    width="800"
  >
    <v-card class="pa-10" rounded="xl">
      <div class="d-flex">
        <h2>
          PROJECT | COUNTRY
        </h2>
        <v-spacer />
        <v-btn @click="dialog=false" elevation="0" color="red" density="default" icon="mdi-close"></v-btn>
      </div>
      <p class="my-3">
        {{project.project_title}}
      </p>
      <br />
      <VRow>
        <VCol cols="12" md="4">
          <h3 class="font-weight-regular">Project Shares: {{formatPrice(project.project_share)}}</h3>
        </VCol>
        <VCol cols="12" md="4">
          <h3 class="font-weight-regular">Available Shares: {{formatPrice(project.limit_share)}}</h3>
        </VCol>
        <VCol cols="12" md="4">
          <h3 class="font-weight-regular">Price Per Shares: <span class="text-primary">${{formatPrice(project.price_per_share)}}</span></h3>
        </VCol>
      </VRow>
      <br />
      <div>
        <h3 class="font-weight-regular">Share Purchase</h3>
        <br />
        <v-select width="50%"
                  :items="['000 121 012','000 121 012']"
                  variant="outlined"
                  rounded="xl"
                  v-model="form.accountName"
                  prepend-inner-icon="mdi-wallet">

        </v-select>
      </div>
      <h3 class="font-weight-regular">Compensation</h3>
      <br />
      <VRow>
        <VCol cols="12" md="4">
          <h4 class="font-weight-regular">Type</h4>
          <h3 class="text-center">Short Term</h3>
        </VCol>
        <VCol cols="12" md="4">
          <h4 class="font-weight-regular">Contact</h4>
          <h3 class="text-center">30 days</h3>
        </VCol>
        <VCol cols="12" md="4">
          <h4 class="font-weight-regular">Share Limitations</h4>
          <h3 class="text-center">{{project.limit_share}}</h3>
        </VCol>
      </VRow>
      <br />
      <h3 class="font-weight-regular">Settlement Interest</h3>
      <br />
      <VRow>
        <VCol cols="12" md="2" v-for="row in settlement_interests" v-bind:key="row">
          <VBtn class="text-none"
                size="large"
                rounded="xl"
                color="primary"
                @click="form.settlement_interest = row"
                :variant="form.settlement_interest === row ? 'outlined' : 'text'">
            {{ row }}
          </VBtn>
        </VCol>
      </VRow>
      <br />
      <div>
        <div>
          <v-row class="mx-0">
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-ts-xl">
                <h5>Interest Rate</h5>
                <h2 class="text-end">1.00%</h2>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-te-xl">
                <h5>Total Interest Rate</h5>
                <h2 class="text-end">6%</h2>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-bs-xl">
                <h5>Profit</h5>
                <h2 class="text-end">$1.500</h2>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-sheet border height="80" class="pa-3 rounded-be-xl">
                <h5>Total Profit</h5>
                <h2 class="text-end">$8.500</h2>
              </v-sheet>
            </v-col>
          </v-row>
        </div>

        <br /><br />

        <h3 class="font-weight-regular">Wallet Account No:</h3>

        <br />
        <!--          <v-card rounded="xl" height="60" width="50%">-->
        <!--            <v-card rounded="xl" height="60" width="20%">-->
        <!--              <VIcon>mdi-wallet</VIcon>-->
        <!--            </v-card>-->
        <!--          </v-card>-->

        <v-select width="50%"
                  :items="['000 121 012','000 121 012']"
                  variant="outlined"
                  rounded="xl"
                  v-model="form.accountName"
                  prepend-inner-icon="mdi-wallet">

        </v-select>

        <br />

        <VBtn color="primary" size="large" rounded="xl" class="text-none">
          Invest Now
        </VBtn>

        <VBtn color="orange" size="large" rounded="xl" class="text-none mx-4">
          Cancel
        </VBtn>


      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped>

</style>