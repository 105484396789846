<template>
  <h4>Upgrade Account</h4>
  <v-divider />
  <br />
  <p class="text-primary text-decoration-underline">Fulfill Form Request</p>
  <h4 class="text-grey-darken-1 text-center">Add receipt</h4>
  <h4 class="text-grey-darken-1">Personal data</h4>

  <v-form ref="form">
    <v-row dense class="mx-10">
      <v-col cols="12" md="12" sm="12">
        <v-alert
          variant="outlined"
          color="error"
          v-if="message"
          :text="message"
          title="Error"
          border="end"
          density="compact"
          border-color="error"
          type="error"
        ></v-alert>

        <v-alert
          variant="outlined"
          color="success"
          type="success"
          v-if="success"
          :text="success"
          title="Success"
          border="end"
          density="compact"
          border-color="success"
        ></v-alert>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">National / Passport / Licence Number *</p>
        <v-text-field
          v-model="form.identity"
          density="compact"
          variant="outlined"
          required
          placeholder="xxx xxx xxx"
          rounded="xl"
          append-inner-icon="mdi-card-account-details-outline"
          :rules="identityRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Expiry Date *</p>
        <v-text-field
          v-model="form.expireDate"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter expiry date"
          rounded="xl"
          append-inner-icon="mdi-clock-time-eight-outline"
          :rules="expireDateRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Full Name *</p>
        <v-text-field
          v-model="form.name"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter Full Name"
          rounded="xl"
          :rules="fullNameRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Gender</p>
        <v-select
          v-model="form.gender"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="gender"
          menu-icon="mdi-chevron-right"
          :rules="genderRules"
          disabled
        ></v-select>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Nationality *</p>
        <v-autocomplete
          v-model="form.nationality"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="nationality"
          menu-icon="mdi-chevron-right"
          :rules="nationalityRules"
          disabled
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Country of Birth *</p>
        <v-autocomplete
          v-model="form.countryOfBirth"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="nationality"
          menu-icon="mdi-chevron-right"
          :rules="countryOfBirthRules"
          disabled
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">City of Birth *</p>
        <v-text-field
          v-model="form.cityOfBirth"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter your city of birth"
          rounded="xl"
          :rules="cityOfBirthRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Date of Birth *</p>
        <v-text-field
          v-model="form.dateOfBirth"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter date of Birth"
          rounded="xl"
          :rules="dateOfBirthRules"
          disabled
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="12">
        <p class="ma-1">Occupation *</p>
        <v-autocomplete
          v-model="form.occupation"
          density="compact"
          variant="outlined"
          rounded="xl"
          :items="job"
          menu-icon="mdi-chevron-right"
          :rules="occupationRules"
          disabled
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="12" sm="12" class="text-end" style="display: none">
        <v-btn
          color="primary"
          text="Next"
          variant="flat"
          class="text-none"
          rounded="xl"
          @click="submitUpdate"
          width="150"
          :loading="loading"
        ></v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { UPDATE_ACCOUNT } from "@/utils/customerApiUrl";
import { mapState } from "vuex";
import {
  fieldRequired,
  formatDate,
  genderOption,
  jobOption,
  nationalityOption,
  validateDate,
} from "@/utils/function";

let allValid = {
  identityRules: fieldRequired("identity"),
  expireDateRules: validateDate("expire date"),
  fullNameRules: fieldRequired("full name"),
  nationalityRules: fieldRequired("nationality"),
  countryOfBirthRules: fieldRequired("country of birth"),
  cityOfBirthRules: fieldRequired("date of birth"),
  dateOfBirthRules: validateDate("date of birth"),
  occupationRules: fieldRequired("occupation"),
};

export default {
  name: "TabUpgrade",
  computed: {
    ...mapState(["profile"]),
  },
  data: () => ({
    job: jobOption(),
    nationality: nationalityOption(),
    gender: genderOption(),
    form: {
      identity: "",
      expireDate: "",
      name: "",
      gender: "",
      nationality: "",
      countryOfBirth: "",
      cityOfBirth: "",
      dateOfBirth: "",
      occupation: "",
    },
    message: "",
    success: "",
    loading: false,
    // validate form
    ...allValid,
  }),
  methods: {
    async submitUpdate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        console.log(this.form);
        this.axios
          .post(UPDATE_ACCOUNT, this.form)
          .then((response) => {
            console.error("response to", response);
            this.success = response.data.message;
            this.loading = false;
            setTimeout(() => {
              this.message = "";
              this.success = "";
              this.$store.dispatch("getUserProfile");
            }, 4000);
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.loading = false;
            console.error("Failed to", error);
          });
      }
    },
  },
  created() {
    let data = formatDate(this.profile.dateOfBirth);
    setTimeout(() => {
      this.form = {
        identity: this.profile.identity,
        expireDate: this.profile.expireDate,
        name: this.profile.name,
        gender: this.profile.gender,
        nationality: this.profile.nationality,
        countryOfBirth: this.profile.countryOfBirth,
        cityOfBirth: this.profile.cityOfBirth,
        dateOfBirth: data,
        occupation: this.profile.occupation,
      };
    }, 1000);
  },
};
</script>

<style scoped></style>
