<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search money claim level..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="moneyClaimLevels"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'createdAt', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-alert
                  v-if="numberPerson1 > numberPerson2 && numberPerson2 !== 0"
                  type="error"
                >
                  No.Person 1 should not be bigger than No.Person 2
                </v-alert>
                <v-alert
                  v-if="numberPerson2 > numberPerson3 && numberPerson3 !== 0"
                  type="error"
                >
                  No.Person 2 should not be bigger than No.Person 3
                </v-alert>
                <v-alert
                  v-if="numberPerson3 > numberPerson4 && numberPerson4 !== 0"
                  type="error"
                >
                  No.Person 3 should not be bigger than No.Person 4
                </v-alert>
                <v-alert
                  v-if="numberPerson4 > numberPerson5 && numberPerson5 !== 0"
                  type="error"
                >
                  No.Person 4 should not be bigger than No.Person 5
                </v-alert>
                <v-alert
                  v-if="numberPerson5 > numberPerson6 && numberPerson6 !== 0"
                  type="error"
                >
                  No.Person 5 should not be bigger than No.Person 6
                </v-alert>
                <v-alert
                  v-if="moneyClaim1 > moneyClaim2 && moneyClaim2 !== 0"
                  type="error"
                >
                  Money Claim 1 should not be bigger than Money Claim 2
                </v-alert>
                <v-alert
                  v-if="moneyClaim2 > moneyClaim3 && moneyClaim3 !== 0"
                  type="error"
                >
                  Money Claim 2 should not be bigger than Money Claim 3
                </v-alert>
                <v-alert
                  v-if="moneyClaim3 > moneyClaim4 && moneyClaim4 !== 0"
                  type="error"
                >
                  Money Claim 3 should not be bigger than Money Claim 4
                </v-alert>
                <v-alert
                  v-if="moneyClaim4 > moneyClaim5 && moneyClaim5 !== 0"
                  type="error"
                >
                  Money Claim 4 should not be bigger than Money Claim 5
                </v-alert>
                <v-alert
                  v-if="moneyClaim5 > moneyClaim6 && moneyClaim6 !== 0"
                  type="error"
                >
                  Money Claim 5 should not be bigger than Money Claim 6
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12">
                    <input type="hidden" v-model="hiddenId" />
                    <v-select
                      v-model="nameLevel"
                      :items="rankLevelNameTypes"
                      :rules="[() => !!nameLevel || 'This field is required']"
                      label="Level Name"
                      variant="outlined"
                      density="compact"
                      prepend-inner-icon="mdi-format-list-bulleted-type"
                      required
                      persistent-placeholder
                      placeholder="Choose level name..."
                      return-object
                      disabled
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="numberPerson1"
                      :rules="[
                        () => !!numberPerson1 || 'This field is required',
                      ]"
                      label="No. Person 1"
                      placeholder="No. Person 1"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="moneyClaim1"
                      :rules="[() => !!moneyClaim1 || 'This field is required']"
                      label="Money Claim 1"
                      placeholder="Money Claim 1"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="numberPerson2"
                      :rules="[
                        () => !!numberPerson2 || 'This field is required',
                      ]"
                      label="No. Person 2"
                      placeholder="No. Person 2"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="moneyClaim2"
                      :rules="[() => !!moneyClaim2 || 'This field is required']"
                      label="Money Claim 2"
                      placeholder="Money Claim 2"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="numberPerson3"
                      :rules="[
                        () => !!numberPerson3 || 'This field is required',
                      ]"
                      label="No. Person 3"
                      placeholder="No. Person 3"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="moneyClaim3"
                      :rules="[() => !!moneyClaim3 || 'This field is required']"
                      label="Money Claim 3"
                      placeholder="Money Claim 3"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="numberPerson4"
                      :rules="[
                        () => !!numberPerson4 || 'This field is required',
                      ]"
                      label="No. Person 4"
                      placeholder="No. Person 4"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="moneyClaim4"
                      :rules="[() => !!moneyClaim4 || 'This field is required']"
                      label="Money Claim 4"
                      placeholder="Money Claim 4"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="numberPerson5"
                      :rules="[
                        () => !!numberPerson5 || 'This field is required',
                      ]"
                      label="No. Person 5"
                      placeholder="No. Person 5"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="moneyClaim5"
                      :rules="[() => !!moneyClaim5 || 'This field is required']"
                      label="Money Claim 5"
                      placeholder="Money Claim 5"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="numberPerson6"
                      :rules="[
                        () => !!numberPerson6 || 'This field is required',
                      ]"
                      label="No. Person 6"
                      placeholder="No. Person 6"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field
                      v-model.number.trim="moneyClaim6"
                      :rules="[() => !!moneyClaim6 || 'This field is required']"
                      label="Money Claim 6"
                      placeholder="Money Claim 6"
                      variant="outlined"
                      prepend-inner-icon="mdi-currency-usd"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-label class="font-weight-regular mb-1"
                      >Busines Desc</v-label
                    >
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 150px"
                      v-model:content="desc"
                      theme="snow"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->

      <template v-slot:[`item.numberPerson1`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.numberPerson1) }}
      </template>
      <template v-slot:[`item.moneyClaim1`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.moneyClaim1) }}
      </template>
      <template v-slot:[`item.numberPerson2`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.numberPerson2) }}
      </template>
      <template v-slot:[`item.moneyClaim2`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.moneyClaim2) }}
      </template>
      <template v-slot:[`item.numberPerson3`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.numberPerson3) }}
      </template>
      <template v-slot:[`item.moneyClaim3`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.moneyClaim3) }}
      </template>
      <template v-slot:[`item.numberPerson4`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.numberPerson4) }}
      </template>
      <template v-slot:[`item.moneyClaim4`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.moneyClaim4) }}
      </template>
      <template v-slot:[`item.numberPerson5`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.numberPerson5) }}
      </template>
      <template v-slot:[`item.moneyClaim5`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.moneyClaim5) }}
      </template>
      <template v-slot:[`item.numberPerson6`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.numberPerson6) }}
      </template>
      <template v-slot:[`item.moneyClaim6`]="{ item }">
        {{ this.convertNumber1000To1K1M1B(item.moneyClaim6) }}
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        <div
          v-html="
            item.desc !== null
              ? item.desc.length > 50
                ? item.desc.substring(0, 50) + '...'
                : item.desc
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
            <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { URL_ADD_MONEY_CLAIM_LEVEL } from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,

    items: [],
    moneyClaimLevels: [],
    sectors: [],
    rankLevelNameTypes: [
      "Ordinary Level",
      "Star 1",
      "Star 2",
      "Star 3",
      "Star 4",
      "Star 5",
    ],

    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    nameLevel: null,
    desc: null,

    numberPerson1: 0,
    moneyClaim1: 0,
    numberPerson2: 0,
    moneyClaim2: 0,
    numberPerson3: 0,
    moneyClaim3: 0,
    numberPerson4: 0,
    moneyClaim4: 0,
    numberPerson5: 0,
    moneyClaim5: 0,
    numberPerson6: 0,
    moneyClaim6: 0,

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    editedIndex: -1,
    hiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Level Name", key: "nameLevel", align: "center" },
      { title: "No. Person 1", key: "numberPerson1", align: "center" },
      { title: "Money Claim 1", key: "moneyClaim1", align: "center" },
      { title: "No. Person 2", key: "numberPerson2", align: "center" },
      { title: "Money Claim 2", key: "moneyClaim2", align: "center" },
      { title: "No. Person 3", key: "numberPerson3", align: "center" },
      { title: "Money Claim 3", key: "moneyClaim3", align: "center" },
      { title: "No. Person 4", key: "numberPerson4", align: "center" },
      { title: "Money Claim 4", key: "moneyClaim4", align: "center" },
      { title: "No. Person 5", key: "numberPerson5", align: "center" },
      { title: "Money Claim 5", key: "moneyClaim5", align: "center" },
      { title: "No. Person 6", key: "numberPerson6", align: "center" },
      { title: "Money Claim 6", key: "moneyClaim6", align: "center" },
      { title: "Description", key: "desc", align: "center" },
      { title: "Staff", key: "staff.fullName", align: "center" },
      { title: "Created at", key: "createdAt", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Money Claim Level"
        : "Update Money Claim Level";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.moneyClaimLevels.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulatemoneyClaimLevels();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      //nameLevel: null,
      this.numberPerson1 = 0;
      this.moneyClaim1 = 0;
      this.numberPerson2 = 0;
      this.moneyClaim2 = 0;
      this.numberPerson3 = 0;
      this.moneyClaim3 = 0;
      this.numberPerson4 = 0;
      this.moneyClaim4 = 0;
      this.numberPerson5 = 0;
      this.moneyClaim5 = 0;
      this.numberPerson6 = 0;
      this.moneyClaim6 = 0;
      this.desc = null;

      this.PopulatemoneyClaimLevels();
    },

    async PopulatemoneyClaimLevels() {
      await axios
        .get(URL_ADD_MONEY_CLAIM_LEVEL, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.moneyClaimLevels = res.data;
          this.items.push(this.moneyClaimLevels);

          this.GetRankNameOrderFromOrderLevelToStar5(this.moneyClaimLevels);
        });
    },

    GetRankNameOrderFromOrderLevelToStar5(component) {
      const countRecord = component.length;
      //alert(component[0].rank_name + ", " + this.rankLevelNameTypes[0])
      if (countRecord === 0) {
        this.nameLevel = "Ordinary Level";
        //this.num_star = 0;
      } else {
        component.filter((item, index) => {
          //alert("Value= " + item.rank_name + ", index= " + index);
          //if (value > 5) return true;
          switch (item.nameLevel) {
            case "Ordinary Level":
              index += 1;
              this.nameLevel = this.rankLevelNameTypes[index];
              //this.num_star = index;
              break;
            case "Star 1":
              index += 1;
              this.nameLevel = this.rankLevelNameTypes[index];
              //this.num_star = index;
              break;
            case "Star 2":
              index += 1;
              this.nameLevel = this.rankLevelNameTypes[index];
              //this.num_star = index;
              break;
            case "Star 3":
              index += 1;
              this.nameLevel = this.rankLevelNameTypes[index];
              //this.num_star = index;
              break;
            case "Star 4":
              index += 1;
              this.nameLevel = this.rankLevelNameTypes[index];
              //this.num_star = index;
              break;
          }
        });
      }
    },

    editItem(item) {
      this.editedIndex = this.moneyClaimLevels.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.nameLevel = item.nameLevel;
      this.selectedItemChanging(item.nameLevel);
      this.numberPerson1 = item.numberPerson1;
      this.moneyClaim1 = item.moneyClaim1;
      this.numberPerson2 = item.numberPerson2;
      this.moneyClaim2 = item.moneyClaim2;
      this.numberPerson3 = item.numberPerson3;
      this.moneyClaim3 = item.moneyClaim3;
      this.numberPerson4 = item.numberPerson4;
      this.moneyClaim4 = item.moneyClaim4;
      this.numberPerson5 = item.numberPerson5;
      this.moneyClaim5 = item.moneyClaim5;
      this.numberPerson6 = item.numberPerson6;
      this.moneyClaim6 = item.moneyClaim6;
      this.desc = item.desc;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.moneyClaimLevels.indexOf(item);
      this.hiddenId = item.id;

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.moneyClaimLevels.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_ADD_MONEY_CLAIM_LEVEL + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        if (this.numberPerson1 > this.numberPerson2) {
          this.visible = true;
          this.msgError =
            "No.Person 1 should not be bigger than No.Person 2. Please check it again!!!!!!!";
          return;
        }
        if (this.numberPerson2 > this.numberPerson3) {
          this.visible = true;
          this.msgError =
            "No.Person 2 should not be bigger than No.Person 3. Please check it again!!!!!!!";
          return;
        }
        if (this.numberPerson3 > this.numberPerson4) {
          this.visible = true;
          this.msgError =
            "No.Person 3 should not be bigger than No.Person 4. Please check it again!!!!!!!";
          return;
        }
        if (this.numberPerson4 > this.numberPerson5) {
          this.visible = true;
          this.msgError =
            "No.Person 4 should not be bigger than No.Person 5. Please check it again!!!!!!!";
          return;
        }
        if (this.numberPerson5 > this.numberPerson6) {
          this.visible = true;
          this.msgError =
            "No.Person 5 should not be bigger than No.Person 6. Please check it again!!!!!!!";
          return;
        }

        if (this.moneyClaim1 > this.moneyClaim2) {
          this.visible = true;
          this.msgError =
            "Money Claim 1 should not be bigger than Money Claim 2. Please check it again!!!!!!!";
          return;
        }
        if (this.moneyClaim2 > this.moneyClaim3) {
          this.visible = true;
          this.msgError =
            "Money Claim 2 should not be bigger than Money Claim 3. Please check it again!!!!!!!";
          return;
        }
        if (this.moneyClaim3 > this.moneyClaim4) {
          this.visible = true;
          this.msgError =
            "Money Claim 3 should not be bigger than Money Claim 4. Please check it again!!!!!!!";
          return;
        }
        if (this.moneyClaim4 > this.moneyClaim5) {
          this.visible = true;
          this.msgError =
            "Money Claim 4 should not be bigger than Money Claim 5. Please check it again!!!!!!!";
          return;
        }
        if (this.moneyClaim5 > this.moneyClaim6) {
          this.visible = true;
          this.msgError =
            "Money Claim 5 should not be bigger than Money Claim 6. Please check it again!!!!!!!";
          return;
        }

        const data = {
          nameLevel: this.nameLevel,
          numberPerson1: parseFloat(this.numberPerson1),
          moneyClaim1: parseFloat(this.moneyClaim1),
          numberPerson2: parseFloat(this.numberPerson2),
          moneyClaim2: parseFloat(this.moneyClaim2),
          numberPerson3: parseFloat(this.numberPerson3),
          moneyClaim3: parseFloat(this.moneyClaim3),
          numberPerson4: parseFloat(this.numberPerson4),
          moneyClaim4: parseFloat(this.moneyClaim4),
          numberPerson5: parseFloat(this.numberPerson5),
          moneyClaim5: parseFloat(this.moneyClaim5),
          numberPerson6: parseFloat(this.numberPerson6),
          moneyClaim6: parseFloat(this.moneyClaim6),
          createdBy: this.staffLoginId,
          desc: this.desc,
        };

        if (this.editedIndex > -1) {
          // edit
          const id = this.hiddenId;
          const url = URL_ADD_MONEY_CLAIM_LEVEL + id;

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );

          this.close();
        } // create new
        else {
          await axios
            .post(URL_ADD_MONEY_CLAIM_LEVEL, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );
        }

        //this.clearContent();
        this.PopulatemoneyClaimLevels();

        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },

    selectedItemChanging(component) {
      switch (component) {
        case "Ordinary Level":
          this.num_star = 0;
          break;
        case "Star 1":
          this.num_star = 1;
          break;
        case "Star 2":
          this.num_star = 2;
          break;
        case "Star 3":
          this.num_star = 3;
          break;
        case "Star 4":
          this.num_star = 4;
          break;
        default:
          this.num_star = 5;
      }
    },
  },
};
</script>
