<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      v-for="(row, index) in mainRewards"
      v-bind:key="row.title"
      class="pa-0"
    >
      <v-card
        height="170px"
        class="pa-2 text-center align-content-center"
        elevation="0"
        style="margin: 1px"
      >
        <h4>
          {{ row.title }}
        </h4>
        <h2 :class="index === 0 ? 'text-blue' : 'text-green'">
          {{ formatPrice(row.total) }}
          <v-icon size="22">mdi-account</v-icon>
        </h2>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-layout row>
        <v-text-field
          placeholder="Referral ID "
          class="myTextField"
          variant="solo"
          density="compact"
          v-model="referralId"
        >
        </v-text-field>
        <v-btn
          class="myBtn text-none"
          color="primary"
          width="100"
          @click="copyCode(referralId)"
        >
          Copy
        </v-btn>
      </v-layout>
    </v-col>

    <v-col cols="12" md="6">
      <v-layout row>
        <v-text-field
          placeholder="Referral Link "
          class="myTextField"
          variant="solo"
          density="compact"
          v-model="referralLink"
        >
        </v-text-field>
        <v-btn
          class="myBtn text-none"
          color="orange"
          width="100"
          @click="copyLink(referralLink)"
        >
          Copy
        </v-btn>
      </v-layout>
    </v-col>
  </v-row>

  <v-snackbar v-model="snackbar" :timeout="timeout">
    {{ text }}

    <template v-slot:actions>
      <v-btn color="blue" variant="text" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>

  <br />

  <div style="width: 240px">
    <h5 class="text-grey">Invite friends via</h5>
    <v-divider></v-divider>
  </div>

  <div class="my-1"></div>
  <!-- {{ socialMedia }} -->
  <div class="d-flex">
    <v-btn
      color="indigo"
      size="30"
      icon="mdi-facebook"
      elevation="0"
      @click="copyLink(socialMedia.facebookLink)"
    ></v-btn>
    <v-btn
      color="primary"
      size="30"
      class="mx-2"
      icon="mdi-linkedin"
      elevation="0"
      @click="copyLink(socialMedia.linkedIn)"
    ></v-btn>
    <v-btn
      color="blue"
      size="30"
      class="mx-2"
      icon="mdi-twitter"
      elevation="0"
      @click="copyLink(socialMedia.twitterLink)"
    ></v-btn>
    <v-btn
      color="red"
      size="30"
      class="mx-2"
      icon="mdi-youtube"
      elevation="0"
      @click="copyLink(socialMedia.telegramLink)"
    ></v-btn>
    <v-btn
      color="green"
      size="30"
      class="mx-2"
      icon="mdi-whatsapp"
      elevation="0"
      @click="copyLink(socialMedia.whatsApp)"
    ></v-btn>
  </div>
  <br />

  <ListReferrals />
</template>

<script>
import { formatPrice } from "../../../../../utils/function";
import ListReferrals from "./ListReferrals.vue";
import { mapState } from "vuex";

export default {
  computed: mapState(["profile", "socialMedia"]),
  name: "MyReferralsTab",
  components: {
    ListReferrals,
  },
  data() {
    return {
      referralId: "1234",
      referralLink: location.origin + "/sign-up?code=",
      //
      formatPrice: formatPrice,
      //
      mainRewards: [
        {
          title: " Successful Referrals",
          total: "0",
        },
        {
          title: " Total Rewards",
          total: "0",
        },
      ],
      //
      snackbar: false,
      text: "Copy",
      timeout: 2000,
    };
  },
  methods: {
    copyCode(text) {
      this.snackbar = true;
      navigator.clipboard.writeText(location.origin + "/sign-up?code=" + text);
    },
    copyLink(link) {
      this.snackbar = true;
      if (link != null) {
        navigator.clipboard.writeText(link);
      }
    },
  },
};
</script>

<style scoped>
.myBtn {
  border-radius: 0px 20px 20px 0px;
  height: 40px !important;
}

.myTextField {
  border-radius: 20px 20px 20px 20px !important;
}
</style>
