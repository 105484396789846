<script>
export default {
  props: ['exploreMores'],
  name: 'CardNews',
};
</script>

<template>
  <div class="margin-auto">
    <VRow>
      <VCol md="4" sm="6" cols="12" v-for="row in exploreMores" v-bind:key="row.id">
        <v-card class="ma-1" min-width="" rounded="xl">

          <v-img
            height="200"
            :src="row.image"
            cover
            class="d-flex align-end pa-5"
          >
            <h3 class="text-orange text-uppercase font-weight-regular">
              Country
            </h3>

            <h3 class="text-white">
              Turns out semicolon-less style.
            </h3>
          </v-img>

          <div class="d-flex justify-lg-space-around my-2">
            <span class="text-small">
              Abbr.text
            </span>
            <span class="text-small">
              |
            </span>
            <span class="text-small">
              Pro Category
            </span>
            <span class="text-small">
              |
            </span>
            <span class="text-small">
              Dec 12, 2024
            </span>
          </div>

          <VDivider />
          <v-card-text>

            <div class="">
              <h4 class="font-weight-regular">
                Small plates, salads & sandwiches - an intimate setting with 12
                indoor seats plus patio seating. Small plates, salads & sandwiches
                - an intimate setting with 12 indoor seats plus patio seating.
                Small plates, salads & sandwiches - an intimate setting with 12
                indoor seats plus patio seating.
              </h4>
            </div>
            <br />
            <router-link to="/project-details" class="text-decoration-none">
              <h3 class="text-primary font-weight-bold text-uppercase">
                Read more
                <VIcon>mdi-arrow-right</VIcon>
              </h3>
            </router-link>
            <br />
          </v-card-text>
        </v-card>
      </VCol>
    </VRow>
  </div>
  <br/>
  <br/>
</template>

<style scoped>
  .text-small{
    font-size: 12px;
  }
</style>