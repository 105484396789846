<template>
  <!-- {{ transactions[0] }} -->
  <div>
    <h4 class="text-grey">View Rewards History by Selecting Date</h4>
    <br />
    <v-row>
      <v-col md="4" cols="12">
        <v-row>
          <v-col cols="3" md="2">
            <p style="padding-top: 5px">Show</p>
          </v-col>

          <v-col cols="6" md="4">
            <v-select
              rounded="xl"
              :items="[10, 20, 50, 100, 200, 'all']"
              v-model="take"
              density="compact"
              variant="solo"
              menu-icon=""
            ></v-select>
          </v-col>
          <v-col cols="3" md="2">
            <p style="padding-top: 5px">Entry</p>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4" cols="12">
        <v-autocomplete
          rounded="xl"
          placeholder="All Referrals"
          density="compact"
          variant="solo"
          v-model="remark"
          :items="['All', 'pending', 'reject', 'done']"
        >
        </v-autocomplete>
      </v-col>

      <v-col md="4" cols="12">
        <v-text-field
          class="elevation-0"
          rounded="xl"
          placeholder="YYYY/MM/DD - YYYY-MM-DD"
          density="compact"
          variant="solo"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <br />

    <v-table density="compact" hover>
      <thead>
        <tr class="bg-primary">
          <th class="text-left">Date</th>
          <th class="text-center">Time</th>
          <th class="text-center">Referral Level</th>
          <th class="text-center">Referrals</th>
          <th class="text-center">Rewards</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(level, index) in transactions"
          v-bind:key="index"
          :style="'background:' + (index % 2 == 1 ? '#E3F2FD' : 'white')"
        >
          <td class="td">
            {{ formatDate(level.createdAt) }}
          </td>
          <td class="td text-center">
            {{ formatDate(level.createdAt, "HH:mm:ss") }} UTC
          </td>
          <td class="td text-center">
            {{ level.nameLevel }}
          </td>
          <td class="td text-center">
            {{ formatPrice(level.numberStar) }}
          </td>
          <td class="td text-center">
            {{ level.currency }} {{ formatPrice(level.amountReward) }}
          </td>
        </tr>
      </tbody>
    </v-table>

    <div class="text-center">
      <v-pagination
        size="small"
        rounded="circle"
        v-model="page"
        length="15"
        :total-visible="7"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import { formatPrice } from "../../../../../utils/function";
import moment from "moment";

export default {
  name: "ListRewards",
  props: ["transactions", "total", "getRemark", "getTake"],
  data() {
    return {
      formatPrice: formatPrice,
      page: 0,
      take: 10,
      remark: "",
      // transactions: [
      //   {
      //     id: 1,
      //     amount: 1000,
      //     currency: "$",
      //     date: "2024-12-12 12:10:10",
      //     level: "Level 1",
      //     referral: "10",
      // ],
    };
  },
  methods: {
    formatDate(date, format = "YYYY-MM-DD") {
      return moment(date).format(format);
    },
  },
  watch: {
    take(value) {
      //alert(value);
      this.getTake(value);
    },
    remark(value) {
      // alert(value);
      if (value == "All") {
        value = "";
      }
      this.getRemark(value);
    },
  },
};
</script>
<style scoped>
th,
td {
  white-space: nowrap;
}
</style>
