<script>
import FrontendLayout from '@/views/website/components/FrontendLayout.vue';
import CardNews from '@/views/website/home/components/CardNews.vue';
import CalculateProfit from '@/views/website/home/components/CalculateProfit.vue';
import ExploreMore from '@/views/website/home/components/ExploreMore.vue';
import MemberFeedback from '@/views/website/home/components/MemberFeedback.vue';
import CardTotal from '@/views/website/home/components/CardTotal.vue';
import ValueOfContacts from '@/views/website/home/components/ValueOfContacts.vue';

export default {
  name: 'HomeView',
  components: {
    ValueOfContacts,
    CardNews,
    CardTotal,
    MemberFeedback,
    // FrequentlyAsked,
    FrontendLayout,
    CalculateProfit,
    ExploreMore,
  },
  data() {
    return {
      exploreMores: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
      ],
    };
  },
};
</script>

<template>
  <FrontendLayout>
    <CalculateProfit />

    <ValueOfContacts />

    <CardNews />

    <VCard class="elevation-0" rounded="0" color="grey-lighten-4">
      <br />
      <CardTotal />

      <ExploreMore :exploreMores="exploreMores" />

      <MemberFeedback />

      <!--      <FrequentlyAsked />-->

    </VCard>
  </FrontendLayout>
</template>

<style scoped>

</style>