<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">

      <div class="margin-auto">
        <br />
        <div>
          <h2 class=" text-uppercase text-primary">All Events</h2>
        </div>
        <br />
      </div>

      <VCard class="bg-primary" min-height="140" rounded="0">
        <div class="margin-auto">
          <VRow class="my-5">
            <VCol cols="12" md="4">
              <p>Date</p>
              <v-autocomplete
                rounded="xl"
                placeholder="Today"
                density="compact"
                variant="solo"
                v-model="form.projectStatus"
                :items="projectStatus"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="4">
              <p>Type</p>
              <v-autocomplete
                rounded="xl"
                placeholder="Event"
                density="compact"
                variant="solo"
                v-model="form.sector"
                :items="['Event']"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="4">
              <p>Language</p>
              <v-autocomplete
                rounded="xl"
                placeholder="English"
                density="compact"
                variant="solo"
                v-model="form.sector"
                :items="['English','French']"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="4">
              <VBtn
                rounded="xl"
                size="large"
                block
                variant="outlined"
                @click="onSearch()"
                class="text-none"
              >
                Filter
              </VBtn>
            </VCol>
          </VRow>

          <v-row class="my-5">
            <VCol cols="12" md="8">
              <v-text-field
                rounded="xl"
                placeholder="Search"
                density="compact"
                variant="solo"
                menu-icon="mdi-chevron-right"
              />
            </VCol>

            <VCol cols="12" md="4">
              <VBtn
                rounded="xl"
                size="large"
                block
                variant="outlined"
                @click="onSearch()"
                class="text-none"
              >
                Search
              </VBtn>
            </VCol>
          </v-row>
        </div>

      </VCard>

      <div class="margin-auto">
        <br /><br />
        <p>Showing 1 - 10 of 44 results</p>
        <br /><br />

        <div v-for="i in 6" v-bind:key="i">
          <v-divider />
          <br />
          <p class="text-content font-weight-bold">
            January 01, 2023
          </p>
          <br />
          <p class="text-content font-weight-bold text-justify">
            The 11th South Asia Economic Policy Network Conference on Social Progress in South East Asia
          </p>
          <br />
          <p class="text-content text-justify">
            Join us for the 11th South Asia Economic Policy Network Conference on Social Progress in South Asia,
            hosted by the Office of the Chief Economist for the South Asia Region,
            Poverty and Equity Practice Group of the World Bank and BRAC Institute of Governance and Development
            (BIGD)....
          </p>
          <br />
          <v-row>
            <v-col cols="12" md="4">
              <span class="text-content font-weight-bold">Date:  </span> <span
              class="text-content">January 01, 2023</span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="text-content font-weight-bold">Type:  </span> <span class="text-content">Event</span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="text-content font-weight-bold">Language:  </span> <span class="text-content">English</span>
            </v-col>
          </v-row>
          <br /><br />
        </div>

        <br /> <br />
        <div class="d-flex align-content-start flex-wrap">
          <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
            First
          </VBtn>

          <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
            Previous
          </VBtn>

          <v-pagination
            v-model="form.page"
            :length="6"
            rounded="circle"
            prev-icon=""
            next-icon=""
          ></v-pagination>

          <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
            Next
          </VBtn>

          <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal">
            Last
          </VBtn>
        </div>
      </div>

      <br />
      <br />
    </div>
  </FrontendLayout>
</template>

<script>
import FrontendLayout from '../components/FrontendLayout';

export default {
  name: 'EventsPage',
  components: {
    FrontendLayout,
  },
  data() {
    return {
      projectStatus: [
        'Active',
        'Sold out',
        'Proposed',
        'Crowdfunding',
      ],
      sectors: [
        'Agribusiness',
        'Banking',
        'Capital Markets',
        'Chemicals',
        'Construction',
        'Education',
      ],
      form: {
        projectStatus: null,
        sector: null,
        page: 1,
      },
      exploreMores: [
        {
          id: 1,
          image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
        },
        {
          id: 2,
          image: 'https://inkstickmedia.com/wp-content/uploads/2021/11/jake-grella-Q0sAi6qdgUI-unsplash-scaled-e1636518588857.jpg',
        },
        {
          id: 3,
          image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
        },
        {
          id: 4,
          image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
        },
        {
          id: 5,
          image: 'https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk=',
        },
        {
          id: 6,
          image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
        },
        {
          id: 7,
          image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
        },
        {
          id: 8,
          image: 'https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk=',
        },
        {
          id: 9,
          image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
        },
      ],
    };
  },
  methods: {
    onSearch() {
      alert('onSearch');
    },
  },
};
</script>

<style scoped></style>
