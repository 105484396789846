<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search fee account ballance..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
      ></v-text-field>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="feeAccountBallanceCollection"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'create_at', order: 'desc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                  flat
                  @click="showForm"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visibleMsg"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" v-show="!visibleEditCustomer">
                    <h1 class="font-weight-regular text-end">
                      {{ customerName }}
                    </h1>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <input type="hidden" v-model="hiddenId" />
                    <v-text-field
                      v-model.number.trim="risk_quarantee_fee"
                      :rules="[
                        () => !!risk_quarantee_fee || 'This field is required',
                      ]"
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                      label="Risk quarantee fee"
                      placeholder="Risk quarantee fee"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-switch
                      v-model="risk_quarantee_status"
                      color="success"
                      :label="isRiskQuaranteeStatus"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 150px"
                      v-model:content="risk_quarantee_text"
                      theme="snow"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model.number.trim="exchange_fee"
                      :rules="[
                        () => !!exchange_fee || 'This field is required',
                      ]"
                      label="Exchange fee"
                      placeholder="Exchange fee"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-switch
                      v-model="exchange_status"
                      color="success"
                      :label="isExchangeStatus"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 150px"
                      v-model:content="exchange_text"
                      theme="snow"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model.number.trim="consultant_fee"
                      :rules="[
                        () => !!consultant_fee || 'This field is required',
                      ]"
                      label="Consultant fee"
                      placeholder="Consultant fee"
                      variant="outlined"
                      prepend-inner-icon="mdi-cash"
                      required
                      autocomplete="off"
                      density="compact"
                      persistent-placeholder
                      type="number"
                      :step="0.1"
                      v-model="value"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-switch
                      v-model="consultant_status"
                      color="success"
                      :label="isConsultantStatus"
                      hide-details
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 150px"
                      v-model:content="consultant_text"
                      theme="snow"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-1">
                  <v-card
                    class="mx-auto"
                    subtitle="Customers"
                    width="100%"
                    v-show="!visibleCustomerTable"
                  >
                    <v-card variant="outlined">
                      <v-text-field
                        v-model="search"
                        label="Search customers..."
                        prepend-inner-icon="mdi-magnify"
                        variant="outlined"
                        hide-details
                        single-line
                        clearable
                      ></v-text-field>
                      <v-data-table
                        v-model="customerSelected"
                        :items="customers"
                        :headers="visibleCustomerHeaders"
                        :search="search"
                        item-value="id"
                        show-select
                        color="#b2d7ef"
                        density="comfortable"
                        rounded="compact"
                        divided
                        striped
                        hover
                        class="elevation-1"
                        fixed-header
                      >
                        <template v-slot:[`item.profile`]="{ item }">
                          <v-avatar size="64" class="my-1" v-if="item.profile">
                            <v-img :src="path + item.profile" cover></v-img>
                          </v-avatar>
                          <v-avatar
                            class="my-1"
                            v-else
                            image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
                            size="80"
                          >
                          </v-avatar>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.customer.profile`]="{ item }">
        <v-avatar size="64" class="my-1" v-if="item.customer.profile">
          <v-img :src="path + item.customer.profile" cover></v-img>
        </v-avatar>
        <v-avatar
          class="my-1"
          v-else
          image="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          size="80"
        >
        </v-avatar>
      </template>
      <template v-slot:[`item.risk_quarantee_status`]="{ item }">
        <div v-if="item.risk_quarantee_status" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.exchange_status`]="{ item }">
        <div v-if="item.exchange_status" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.consultant_status`]="{ item }">
        <div v-if="item.consultant_status" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.risk_quarantee_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.risk_quarantee_fee) }}
      </template>
      <template v-slot:[`item.exchange_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.exchange_fee) }}
      </template>
      <template v-slot:[`item.consultant_fee`]="{ item }">
        {{ convertNumber1000To1K1M1B(item.consultant_fee) }}
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <v-icon icon="mdi-clipboard-text-clock"></v-icon>
        {{ new Date(item.create_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.risk_quarantee_text`]="{ item }">
        <div
          v-html="
            item.risk_quarantee_text !== null
              ? item.risk_quarantee_text.length > 50
                ? item.risk_quarantee_text.substring(0, 50) + '...'
                : item.risk_quarantee_text
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.exchange_text`]="{ item }">
        <div
          v-html="
            item.exchange_text !== null
              ? item.exchange_text.length > 50
                ? item.exchange_text.substring(0, 50) + '...'
                : item.exchange_text
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.consultant_text`]="{ item }">
        <div
          v-html="
            item.consultant_text !== null
              ? item.consultant_text.length > 50
                ? item.consultant_text.substring(0, 50) + '...'
                : item.consultant_text
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.staff.fullName`]="{ item }">
        {{ item.staff.fullName }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
          <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Customer_Image,
  URL_CUS_FEE_ACCOUNT_BALLANCE,
  URL_FEE_ACCOUNT_BALLANCE,
  URL_FEE_ACCOUNT_BALLANCE_UPDATE_CUS,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { formatNumber, originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    path: Path_Customer_Image,

    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: false,
    visibleMsg: true,
    visibleEditCustomer: true,
    visibleCustomerTable: false,

    items: [],
    feeAccountBallanceCollection: [],
    customerSelected: [],
    itemCustomers: [],
    customers: [],
    validation: {
      min: 0,
      max: 10,
      decimal: 10,
    },

    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,

    customerId: null,
    risk_quarantee_fee: 0,
    exchange_fee: 0,
    consultant_fee: 0,
    risk_quarantee_text: null,
    exchange_text: null,
    consultant_text: null,

    risk_quarantee_status: 0,
    exchange_status: 0,
    consultant_status: 0,

    editedIndex: -1,
    hiddenId: 0,
    customerName: null,

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Photo", key: "customer.profile", align: "center" },
      { title: "Customer", key: "customer.name", align: "center" },
      {
        title: "Risk Quarantee Fee",
        key: "risk_quarantee_fee",
        align: "center",
      },
      {
        title: "Risk Quarantee Text",
        key: "risk_quarantee_text",
        align: "center",
      },
      {
        title: "Risk Quarantee Status",
        key: "risk_quarantee_status",
        align: "center",
      },
      { title: "Exchange Fee", key: "exchange_fee", align: "center" },
      { title: "Exchange Text", key: "exchange_text", align: "center" },
      { title: "Exchange Status", key: "exchange_status", align: "center" },
      { title: "Consultant Fee", key: "consultant_fee", align: "center" },
      { title: "Consultant Text", key: "consultant_text", align: "center" },
      { title: "Consultant Status", key: "consultant_status", align: "center" },
      { title: "Created at", key: "create_at", align: "center" },
      { title: "created by", key: "staff.fullName", align: "center" },
    ],
    headerCustomers: [
      { title: "", key: "profile", align: "center" },
      { title: "First name", key: "firstName", align: "center" },
      { title: "Last name", key: "lastName", align: "center" },
      { title: "Sex", key: "gender", align: "center" },
      { title: "Email", key: "email", align: "center" },
      { title: "Phone", key: "phone", align: "center" },
      { title: "Customer Id", key: "id" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Fee Account Ballance"
        : "Update Fee Account Ballance";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    isRiskQuaranteeStatus() {
      return this.risk_quarantee_status.toString() === "true"
        ? "Risk Quarantee"
        : "No Risk Quarantee";
    },
    isExchangeStatus() {
      return this.exchange_status.toString() === "true"
        ? "Exchange Status"
        : "No Exchange Status";
    },
    isConsultantStatus() {
      return this.consultant_status.toString() === "true"
        ? "Consultant Status"
        : "No Consultant Status";
    },
    visibleCustomerHeaders() {
      return this.headerCustomers.filter((header) => {
        if (header.key === "id") {
          return header.visible;
        }

        return true;
      });
    },
    pageCount() {
      return Math.ceil(
        this.feeAccountBallanceCollection.length / this.itemsPerPage
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.PopulatefeeAccountBallanceCollection();
    this.PopulateCustomers();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.risk_quarantee_fee = 0;
      this.exchange_fee = 0;
      this.consultant_fee = 0;
      this.risk_quarantee_status = 0;
      this.exchange_status = 0;
      this.consultant_status = 0;
      this.risk_quarantee_text = null;
      this.exchange_text = null;
      this.consultant_text = null;
      this.visibleMsg = true;
      this.visibleEditCustomer = true;
      this.visibleCustomerTable = false;
    },

    async PopulatefeeAccountBallanceCollection() {
      await axios
        .get(URL_FEE_ACCOUNT_BALLANCE, { headers: TOKEN_HEADERS })
        .then((res) => {
          this.feeAccountBallanceCollection = res.data;
          this.items.push(res.data);
        });
    },

    PopulateCustomers() {
      axios
        .get(URL_CUS_FEE_ACCOUNT_BALLANCE, {
          headers: TOKEN_HEADERS,
        })
        .then((response) => {
          this.customers = response.data;
          this.itemCustomers.push(response.data);
          /**
          response.data.forEach((cus) => {
            this.itemCustomers.push({
              name: cus.name,
              id: cus.id,
            });
          }); */
        });
    },

    showForm() {
      this.PopulateCustomers();
      this.dialog = true;
    },

    editItem(item) {
      this.editedIndex = this.feeAccountBallanceCollection.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.customerName = "Customer name: " + item.customer.name;
      this.customerId = item.customerId;
      this.risk_quarantee_fee = item.risk_quarantee_fee;
      this.exchange_fee = item.exchange_fee;
      this.consultant_fee = item.consultant_fee;
      this.risk_quarantee_text = item.risk_quarantee_text;
      this.exchange_text = item.exchange_text;
      this.consultant_text = item.consultant_text;
      this.risk_quarantee_status =
        item.risk_quarantee_status.toString() === "1" ? true : false;
      this.exchange_status =
        item.exchange_status.toString() === "1" ? true : false;
      this.consultant_status =
        item.consultant_status.toString() === "1" ? true : false;

      this.visibleEditCustomer = false;
      this.visibleCustomerTable = true;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.feeAccountBallanceCollection.indexOf(item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.feeAccountBallanceCollection.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_FEE_ACCOUNT_BALLANCE + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const data = {
          risk_quarantee_fee: parseFloat(this.risk_quarantee_fee),
          exchange_fee: parseFloat(this.exchange_fee),
          consultant_fee: parseFloat(this.consultant_fee),
          risk_quarantee_status: this.risk_quarantee_status === true ? 1 : 0,
          risk_quarantee_text: this.risk_quarantee_text,
          exchange_status: this.exchange_status === true ? 1 : 0,
          exchange_text: this.exchange_text,
          consultant_status: this.consultant_status === true ? 1 : 0,
          consultant_text: this.consultant_text,
          create_by: this.staffLoginId,
        };

        if (this.editedIndex > -1) {
          // edit
          const id = this.hiddenId;
          data.customerId = this.customerId;
          const url = URL_FEE_ACCOUNT_BALLANCE + id;

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visibleMsg = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );

          this.close();
        } // create new
        else {
          if (this.customerSelected.length === 0) {
            this.visibleMsg = false;
            this.msgError = "Please choose customer at lease one.";
            return false;
          } else {
            this.visibleMsg = true;
            this.customerSelected.forEach(async (key) => {
              data.customerId = parseInt(key);
              await axios
                .post(URL_FEE_ACCOUNT_BALLANCE, data, {
                  headers: TOKEN_HEADERS,
                })
                .then((response) => {
                  this.updateCustomer(parseInt(key));
                  this.snakeMessage("success", response.data.message);
                  this.clearContent();
                  this.dialog = false;
                })
                .catch((error) => {
                  this.visibleMsg = false;
                  if (
                    error.response &&
                    error.response.data.statusCode === 400
                  ) {
                    // this.snakeMessage("error", error.response.data.message);
                    this.msgError = error.response.data.message;
                  } else if (error.request) {
                    // The request was made but no response was received
                    // this.snakeMessage("error", error.request);
                    this.msgError = error.request;
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    // this.snakeMessage("error", error.message);
                    this.msgError = error.message;
                  }
                  this.dialog = true;
                  return false;
                });

              this.customerSelected = [];
              this.PopulatefeeAccountBallanceCollection();
            });
          }
        }

        this.clearContent();
        this.PopulatefeeAccountBallanceCollection();
        //this.dialog = false;
      }
    },

    async updateCustomer(customerId) {
      await axios
        .patch(URL_FEE_ACCOUNT_BALLANCE_UPDATE_CUS + customerId, {
          headers: TOKEN_HEADERS,
        })
        .then((res) => {
          console.log(res.data.message);
        });
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },

    convertNumber1000To1K1M1B(value) {
      return formatNumber(value);
    },
  },
};
</script>
