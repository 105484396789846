<script setup>

import FrontendLayout from '@/views/website/components/FrontendLayout.vue';
import CardNews from '@/views/website/about-us/components/CardNews.vue';

const exploreMores = [
  {
    id: 1,
    image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
  },
  {
    id: 2,
    image: 'https://inkstickmedia.com/wp-content/uploads/2021/11/jake-grella-Q0sAi6qdgUI-unsplash-scaled-e1636518588857.jpg',
  },
  {
    id: 3,
    image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
  },
  {
    id: 4,
    image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
  },
  {
    id: 5,
    image: 'https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk=',
  },
  {
    id: 6,
    image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
  },
  {
    id: 7,
    image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
  },
  {
    id: 8,
    image: 'https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk=',
  },
  {
    id: 9,
    image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
  },
];

</script>

<template>
  <FrontendLayout>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        Annual Report
      </h2>

      <div class="margin-auto py-10">
        <CardNews :exploreMores="exploreMores" />
      </div>
      <br /><br /><br />
    </div>
  </FrontendLayout>
</template>

<style scoped>

</style>