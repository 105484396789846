<template>
  <FrontendLayout>
<!--    <pre>-->
<!--      {{ data }}-->
<!--    </pre>-->
    <v-img
      min-height="720"
      width="100%"
      cover
      :src="data.urlImage"
    >
      <h2 class="text-uppercase text-center text-white">
        {{ data.title }}
      </h2>
    </v-img>

    <div class="bg-grey-lighten-4">
      <h2 class="text-uppercase text-center text-blue-darken-3 pa-5">
        {{ data.title }}
      </h2>

      <div class="margin-auto py-10">
        <p class="text-content text-justify" v-html="data.description">
        </p>
      </div>
      <br /><br /><br />
    </div>
  </FrontendLayout>
</template>

<script>
import FrontendLayout from '../components/FrontendLayout.vue';
import { ABOUT_US_BLOG } from '@/utils/customerApiUrl';

export default {
  name: 'AboutUs',
  components: { FrontendLayout },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    async fetchData() {
      this.axios
        .get(
          ABOUT_US_BLOG,
        )
        .then((response) => {
          let { data } = response.data;
          this.data = data[0];
        })
        .catch((error) => {
          console.error('Failed to', error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
