<template>
  <div class="margin-auto">
    <br />
    <div class="text-center">
      <h2 class="text-uppercase text-primary">Feature Projects</h2>
      <br />
      <h3 class="text-center font-weight-regular">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit.
      </h3>
    </div>
    <br />

    <div>
      <VRow>
        <VCol md="4" sm="6" cols="12" v-for="row in exploreMores" v-bind:key="row.id">
          <v-card class="ma-1" min-width="" rounded="xl">

            <v-img
              height="300"
              src="https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk="
              cover
              class="d-flex align-end pa-5"
            >
              <h3 class="text-orange text-uppercase font-weight-regular">
                Country
              </h3>

              <h2 class="text-white">
                Turns out semicolon-less style.
              </h2>
            </v-img>

            <div class="d-flex justify-lg-space-around my-2">
              <p>
                Abbr.text
              </p>
              <p>
                |
              </p>
              <p>
                Pro Category
              </p>
              <p>
                |
              </p>
              <p>
                Dec 12, 2024
              </p>
            </div>

            <VDivider />
            <v-card-text>

              <div class="">
                <h4 class="font-weight-regular">
                  Small plates, salads & sandwiches - an intimate setting with 12
                  indoor seats plus patio seating. Small plates, salads & sandwiches
                  - an intimate setting with 12 indoor seats plus patio seating.
                  Small plates, salads & sandwiches - an intimate setting with 12
                  indoor seats plus patio seating.
                </h4>
              </div>
              <br />
              <router-link to="/" class="text-decoration-none">
                <h3 class="text-primary font-weight-bold text-uppercase">
                  Read more
                  <VIcon>mdi-arrow-right</VIcon>
                </h3>
              </router-link>
              <br />
            </v-card-text>
          </v-card>
        </VCol>
        <VCol md="4" sm="6">
          <VBtn rounded="xl" size="x-large" varian="text" height="60" block color="primary">
            <h3>
              Explore For more
            </h3>
          </VBtn>
        </VCol>
      </VRow>
    </div>

  </div>
</template>
<script>
export default {
  name: 'ExploreMore',
  props: ['exploreMores'],
};
</script>
<style></style>
