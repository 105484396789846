<template>
  <v-card flat>
    <template v-slot:text>
      <v-text-field
        v-model="search"
        label="Search compensation..."
        prepend-inner-icon="mdi-magnify"
        variant="outlined"
        hide-details
        single-line
        clearable
      ></v-text-field>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="compensationCollection"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[{ key: 'created_at', order: 'asc' }]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                  type="error"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <input type="hidden" v-model="hiddenId" />
                    <v-select
                      v-model="interest_name"
                      :items="types"
                      :rules="[
                        () => !!interest_name || 'This field is required',
                      ]"
                      label="Type"
                      variant="outlined"
                      density="compact"
                      prepend-inner-icon="mdi-format-list-bulleted-type"
                      required
                      persistent-placeholder
                      placeholder="Choose type..."
                      return-object
                      disabled
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-text-field
                      v-model.number="contrast"
                      :rules="[() => !!contrast || 'This field is required']"
                      label="Contrast"
                      placeholder="Contrast"
                      variant="outlined"
                      prepend-inner-icon="mdi-file-document-edit-outline"
                      required
                      autocomplete="off"
                      density="compact"
                      clearable
                      persistent-placeholder
                      type="number"
                      :step="1"
                      :formatter="formatNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-label class="font-weight-thin mb-1 x-small"
                      >Description</v-label
                    >
                    <QuillEditor
                      toolbar="essential"
                      contentType="html"
                      style="height: 150px"
                      v-model:content="description"
                      theme="snow"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert :value="true" color="error" icon="warning" class="text-left">
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.description`]="{ item }">
        <div
          v-html="
            item.description !== null
              ? item.description.length > 50
                ? item.description.substring(0, 50) + '...'
                : item.description
              : ''
          "
        ></div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ new Date(item.created_at).toLocaleString() }}
      </template>
      <template v-slot:[`item.update_time`]="{ item }">
        {{
          item.update_time !== null
            ? new Date(item.update_time).toLocaleString()
            : ""
        }}
      </template>
      <template v-slot:[`item.Staff`]="{ item }">
        {{ item.Staff !== null ? item.Staff.fullName : "" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
          <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          
          <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { URL_COMPENSATION } from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import moment from "moment";
import { originalCode } from "@/utils/formatNumber";
import { QuillEditor } from "@vueup/vue-quill";

export default {
  components: {
    QuillEditor,
  },
  data: () => ({
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,

    items: [],
    compensationCollection: [],
    types: [
      "Daily",
      "Weekly",
      "Monthly",
      "Quarterly",
      "Semi-Annually",
      "Annually",
    ],

    search: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    interest_name: "Daily",
    contrast: 0,
    description: null,
    disabled: null,
    constrastFromDb: null,
    interestNameFromDb: null,

    staffLogin: parseInt(originalCode(localStorage.getItem("id"))),

    editedIndex: -1,
    hiddenId: 0,

    page: 1,
    itemsPerPage: 10,

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Type", key: "interest_name", align: "center" },
      { title: "Contrast", key: "contrast", align: "center" },
      { title: "Description", key: "description", align: "center" },
      { title: "Created at", key: "created_at", align: "center" },
      { title: "Updated at", key: "update_time", align: "center" },
      { title: "Updated by", key: "Staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Compensation"
        : "Update Compensation";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    pageCount() {
      return Math.ceil(this.compensationCollection.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.Populatecompensations();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      //this.interest_name = null;
      this.contrast = 0;
      this.description = null;
      this.visible = true;
      this.disabled = 1;
    },

    async Populatecompensations() {
      await axios
        .get(URL_COMPENSATION, { headers: TOKEN_HEADERS })
        .then((response) => {
          this.compensationCollection = response.data;
          this.items.push(response.data);

          if (response.data.length > 0) {
            this.constrastFromDb = response.data[0].contrast;
          }

          this.GetTypeNameOrderFromDailyToAnnually(this.compensationCollection);
        });
    },

    GetTypeNameOrderFromDailyToAnnually(component) {
      const countRecord = component.length;
      //alert(component[0].rank_name + ", " + this.rankNameTypes[0])
      if (countRecord === 0) {
        this.interest_name = "Daily";
      } else {
        component.filter((item, index) => {
          //alert("Value= " + item.rank_name + ", index= " + index);
          //if (value > 5) return true;
          switch (item.interest_name) {
            case "Daily":
              index += 1;
              this.interest_name = this.types[index];
              break;
            case "Weekly":
              index += 1;
              this.interest_name = this.types[index];
              break;
            case "Monthly":
              index += 1;
              this.interest_name = this.types[index];
              break;
            case "Quarterly":
              index += 1;
              this.interest_name = this.types[index];
              break;
            case "Semi-Annually":
              index += 1;
              this.interest_name = this.types[index];
              break;
            default:
              index += 1;
              this.interest_name = this.types[index];
              break;
          }
        });
      }
    },

    editItem(item) {
      this.editedIndex = this.compensationCollection.indexOf(item);
      this.hiddenId = item.id;
      this.disabled = 0;
      this.editedItem = Object.assign({}, item);
      this.interest_name = item.interest_name;
      this.interestNameFromDb = item.interest_name;
      this.contrast = item.contrast;
      this.description = item.description;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.compensationCollection.indexOf(item);
      this.hiddenId = item.id;

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.compensationCollection.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_COMPENSATION + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        const data = {
          interest_name: this.interest_name,
          contrast: parseInt(this.contrast),
          description: this.description,
        };

        if (this.editedIndex > -1) {
          // edit
          if (this.interestNameFromDb === this.interest_name) {
            if (this.constrastFromDb < this.contrast) {
              this.visible = false;
              this.msgError =
                this.interest_name +
                " must have contrast equal or smaller than " +
                this.constrastFromDb;
              return;
            }
          }

          const id = this.hiddenId;
          const url = URL_COMPENSATION + id;
          data.start_update_by = this.staffLogin;
          data.update_time = moment(new Date());

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );

          this.close();
        } // create new
        else {
          if (this.constrastFromDb >= this.contrast) {
            this.visible = false;
            this.msgError =
              this.interest_name +
              " must have contrast bigger than " +
              this.constrastFromDb;
            return;
          }

          data.created_at = moment(new Date());
          await axios
            .post(URL_COMPENSATION, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );
        }

        //this.clearContent();
        this.Populatecompensations();

        //this.dialog = false;
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    formatNumber(e) {
      if (e > this.validation.max) {
        return this.validation.max;
      } else if (e < this.validation.min) {
        return this.validation.min;
      } else if (
        Math.round(e * this.validation.decimal) / this.validation.decimal !==
        e
      ) {
        return this.lastValue;
      } else {
        this.lastValue = e;
        return e;
      }
    },
  },
};
</script>
