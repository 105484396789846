<template>
  <!--index 1-->
  <div class="text-center" v-if="recipient_index == 1">
    <img
      width="20%"
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1280px-MasterCard_Logo.svg.png"
      alt=""
    />
    <h1 class="text-uppercase font-weight-bold">
      <strong>IT's Quite in here</strong>
    </h1>
    <br />
    <p class="text-grey-darken-1">
      Start adding people's account details so you're ready send money
    </p>
    <br />
    <v-btn
      class="text-none"
      rounded="xl"
      color="primary"
      size="large"
      @click="addNextRecipient(2)"
    >
      Add recipient
    </v-btn>

    <br />
    <br />

    <v-card class="pa-1">
      <v-list>
        <v-list-subheader inset>List Cryptocurrency</v-list-subheader>
        <v-divider inset></v-divider>
        <v-list-item
          v-for="file in bankAccountWithdraw.filter(
            (i) => i.typeBank === 'Cryptocurrency'
          )"
          :key="file.id"
          :subtitle="file.checkType"
          :title="file.fullName + ' ' + file.cardNumber + ' ' + file.wallet"
        >
          <template v-slot:prepend>
            <v-avatar color="blue">
              <v-img :src="file.bankType.logoBank"></v-img>
            </v-avatar>
          </template>

          <template v-slot:append>
            <v-btn
              color="grey-lighten-1"
              icon="mdi-information"
              variant="text"
            ></v-btn>
          </template>
        </v-list-item>
      </v-list>
    </v-card>

    <br />

    <v-card class="pa-1">
      <v-list>
        <v-list-subheader inset>List Bank Transfer</v-list-subheader>
        <v-divider inset></v-divider>
        <v-list-item
          v-for="file in bankAccountWithdraw.filter(
            (i) => i.typeBank === 'Bank Transfer'
          )"
          :key="file.id"
          :subtitle="file.checkType"
          :title="file.fullName + ' ' + file.cardNumber + ' ' + file.wallet"
        >
          <template v-slot:prepend>
            <v-avatar color="blue">
              <v-img :src="file.bankType.logoBank"></v-img>
            </v-avatar>
          </template>

          <template v-slot:append>
            <v-btn
              color="grey-lighten-1"
              icon="mdi-information"
              variant="text"
            ></v-btn>
          </template>
        </v-list-item>
      </v-list>
    </v-card>

    <br />

    <v-card class="pa-1">
      <v-list>
        <v-list-subheader inset>List Online Bank Transfer</v-list-subheader>
        <v-divider inset></v-divider>
        <v-list-item
          v-for="file in bankAccountWithdraw.filter(
            (i) => i.typeBank === 'Online Bank Transfer'
          )"
          :key="file.id"
          :subtitle="file.checkType"
          :title="file.fullName + ' ' + file.cardNumber + ' ' + file.wallet"
        >
          <template v-slot:prepend>
            <v-avatar color="blue">
              <v-img :src="file.bankType.logoBank"></v-img>
            </v-avatar>
          </template>

          <template v-slot:append>
            <v-btn
              color="grey-lighten-1"
              icon="mdi-information"
              variant="text"
            ></v-btn>
          </template>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
  <!--index 1-->

  <!--index 2-->
  <div v-if="recipient_index == 2">
    <v-btn
      icon="mdi-chevron-left"
      elevation="0"
      @click="addNextRecipient(1)"
    ></v-btn>

    <h4 class="text-center text-grey-darken-1">Add a recipient</h4>

    <br />

    <v-card class="mx-auto">
      <v-list lines="two">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :subtitle="item.subtitle"
          :title="item.title"
          @click="addBankType(item.index, item.payment_method)"
        >
          <template v-slot:prepend>
            <v-avatar color="grey-lighten-1">
              <v-icon color="">{{ item.icon }}</v-icon>
            </v-avatar>
          </template>

          <template v-slot:append>
            <v-btn
              color="grey-dark-1"
              icon="mdi-chevron-right"
              variant="text"
            ></v-btn>
          </template>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
  <!--index 2-->

  <!--index 3-->
  <div v-if="recipient_index == 3">
    <v-btn
      class="my-2"
      icon="mdi-chevron-left"
      elevation="0"
      @click="addNextRecipient(2)"
    ></v-btn>

    <v-col cols="12" md="6">
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="12"
          v-for="(row, index) in listBankType"
          v-bind:key="index"
        >
          <v-card
            color="grey-lighten-3"
            class="text-center pa-3 cursor"
            elevation="0"
            @click="itemAction(4, row)"
          >
            <img :src="row.logoBank" alt="" height="100px" width="100px" />
            <h3>{{ row.bankName }}</h3>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
  <!--index 3-->

  <!--index 4-->
  <div v-if="recipient_index == 4">
    <v-card class="pa-5">
      <v-btn
        class="my-2"
        icon="mdi-chevron-left"
        elevation="0"
        @click="addNextRecipient(3)"
      >
      </v-btn>

      <p class="text-center text-h5" v-if="paymentMethod == 'Bank Transfer' || paymentMethod == 'Online Bank Transfer'">
        Enter their account details
      </p>

      <v-col cols="12" md="12" sm="12">
        <v-alert
          variant="outlined"
          color="error"
          v-if="message"
          :text="message"
          title="Error"
          border="end"
          density="compact"
          border-color="error"
          type="error"
        ></v-alert>

        <v-alert
          variant="outlined"
          color="success"
          type="success"
          v-if="success"
          :text="success"
          title="Success"
          border="end"
          density="compact"
          border-color="success"
        ></v-alert>
      </v-col>

      <div v-if="paymentMethod == 'Bank Transfer' || paymentMethod == 'Online Bank Transfer'">
        <span> Bank details </span>

        <v-divider />
        <br />
        <v-btn color="primary" variant="text" width="100"> ACH </v-btn>
        <v-divider class="mt-1" color="blue" thickness="2" />
      </div>

      <v-form ref="form" v-if="paymentMethod == 'Bank Transfer' || paymentMethod == 'Online Bank Transfer'">
        <p class="ma-1">Full name of the account holder</p>
        <v-text-field
          v-model="form.fullName"
          :rules="fullNameRules"
          density="compact"
          variant="outlined"
          required
          placeholder="Full name of the account holder"
          rounded="md"
          disabled
        />

        <p class="ma-1">ACH routing number</p>
        <v-text-field
          v-model="form.routing"
          density="compact"
          variant="outlined"
          required
          placeholder="ACH routing number (optional)"
          rounded="md"
        />

        <p class="ma-1">Account number</p>
        <v-text-field
          v-model="form.cardNumber"
          :rules="accountNumberRules"
          density="compact"
          variant="outlined"
          required
          placeholder="Account number"
          rounded="md"
        />

        <v-radio-group v-model="form.checkType">
          <p class="ma-1">Account type</p>
          <v-card border flat>
            <v-radio label="Checking" value="Checking" color="primary" />
          </v-card>

          <br />

          <v-card border flat>
            <v-radio label="Savings" value="Savings" color="primary" />
          </v-card>
        </v-radio-group>

        <br />
        <br />

        <v-btn
          block
          :loading="loading"
          class="text-none"
          rounded="xl"
          color="primary"
          @click="submitNext()"
        >
          Confirm
        </v-btn>
      </v-form>

      <v-form ref="formCrypo" v-if="paymentMethod == 'Cryptocurrency'">
        <p class="ma-1">Wallet address</p>
        <v-text-field
          v-model="form.usdtLink"
          density="compact"
          variant="outlined"
          required
          placeholder="Enter wallet address"
          prepend-inner-icon="mdi-qrcode"
          append-inner-icon="mdi-content-copy"
          rounded="md"
        />

        <v-card class="ma-0" width="200">
          <v-avatar rounded="0" size="200">
            <v-img :src="imagePreview" cover></v-img>
          </v-avatar>
        </v-card>

        <v-btn
          class="my-2 text-none"
          width="200"
          onclick="document.getElementById('back_image').click()"
        >
          <v-icon>mdi-cloud-upload-outline</v-icon>
          Upload Qr Code
        </v-btn>

        <input
          style="display: none"
          type="file"
          id="back_image"
          @change="backImage"
        />

        <br />
        <br />

        <v-btn
          block
          :loading="loading"
          class="text-none"
          rounded="xl"
          color="primary"
          @click="submitNextCrypo()"
        >
          Confirm
        </v-btn>
      </v-form>
    </v-card>
  </div>
  <!--index 4-->
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import {
  BANK_ACCOUNT_WITHDRAW,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PATH,
} from "../../../utils/customerApiUrl";

let keyLocalStorage = "payment_info_index";

let qrImage =
  "https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg";

export default {
  name: "PaymentInfo",
  computed: {
    ...mapState(["bankType", "bankAccountWithdraw", "profile"]),
  },
  data: function () {
    return {
      recipient_index: 3,
      items: [
        {
          index: 3,
          subtitle: "",
          title: "Cryptocurrencies",
          icon: "mdi-currency-usd",
          payment_method: "Cryptocurrency",
        },
        // {
        //   index: 3,
        //   subtitle: "Add a recipient's account information",
        //   title: "Enter bank details",
        //   icon: "mdi-bank-outline",
        //   payment_method: "Bank Transfer",
        // },
        {
          index: 3,
          subtitle: "Add a recipient's account information",
          title: "Enter Online bank transfer details",
          icon: "mdi-bank-outline",
          payment_method: "Online Bank Transfer",
        },
      ],
      itemDetails: {},
      form: {
        id: 0,
        fullName: "",
        routing: "",
        cardNumber: "",
        checkType: "Checking",
        usdtQr: "",
        usdtLink: "",
        bankTypeId: 0,
        typeBank: "",
        wallet: "",
      },

      fullNameRules: [(v) => !!v || "Full name is required"],
      accountNumberRules: [(v) => !!v || "Account number is required"],
      success: "",
      message: "",
      loading: false,

      paymentMethod: "",
      listBankType: [],
      imagePreview: qrImage,
    };
  },
  methods: {
    setLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },

    getLocalStorage(key) {
      return localStorage.getItem(key);
    },

    addNextRecipient(index) {
      this.recipient_index = index;
      this.setLocalStorage(keyLocalStorage, this.recipient_index);
    },

    itemAction(index, itemDetails) {
      this.itemDetails = itemDetails;
      this.addNextRecipient(index);

      let checkBankType = this.bankAccountWithdraw.filter(
        (i) => i.bankTypeId === itemDetails.id
      );

      if (checkBankType.length < 1) {
        this.form = {
          id: 0,
          fullName: this.profile.name,
          routing: "",
          cardNumber: "",
          checkType: "Checking",
          usdtQr: "",
          usdtLink: "",
          typeBank: this.paymentMethod,
        };
      }
      this.imagePreview = qrImage;
      if (checkBankType.length > 0) {
        this.form = {
          id: checkBankType[0].id,
          fullName: checkBankType[0].fullName,
          routing: checkBankType[0].routing,
          cardNumber: checkBankType[0].cardNumber,
          checkType: checkBankType[0].checkType,
          usdtQr: checkBankType[0].usdtQr,
          usdtLink: checkBankType[0].usdtLink,
          wallet: checkBankType[0].wallet,
        };

        this.imagePreview = checkBankType[0].usdtQr
          ? UPLOAD_PHOTO_PATH + "" + checkBankType[0].usdtQr
          : qrImage;
      }

      this.form.bankTypeId = itemDetails.id;

      if (this.paymentMethod == "Cryptocurrency") {
        this.form.checkType = "";
        this.form.wallet = itemDetails.bankName;
      } else {
        this.form.wallet = "";
      }
    },

    async backImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
        //console.log(this.imagePreview);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(UPLOAD_PHOTO, data).then((response) => {
        this.form.usdtQr = response.data.filename;
        //console.log("Image has been uploaded.");
      });
    },

    async submitNext() {
      if (this.form.bankTypeId === 0) {
        this.addNextRecipient(3);
        return;
      }

      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.postData();
      }
    },

    async submitNextCrypo() {
      if (this.form.bankTypeId === 0) {
        this.addNextRecipient(3);
        return;
      }

      const { valid } = await this.$refs.formCrypo.validate();

      if (valid) {
        this.postData();
      }
    },

    async postData() {
      // console.log(JSON.stringify(this.form));
      // return;
      this.loading = true;

      this.axios
        .post(BANK_ACCOUNT_WITHDRAW, this.form)
        .then((response) => {
          this.success = response.data.message;
          this.loading = false;
          setTimeout(() => {
            this.message = "";
            this.success = "";
            this.$store.dispatch("getUserProfile");
          }, 4000);
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.loading = false;
          console.error("Failed to", error);
        });
    },

    addBankType(index, paymentMethod) {
      this.addNextRecipient(index);
      this.paymentMethod = paymentMethod;
      let bank = this.bankType.filter((i) => i.paymentMethod == paymentMethod);
      this.listBankType = bank;
    },
  },
  created() {
    let index = this.getLocalStorage(keyLocalStorage) ?? 1;
    if (index) {
      this.recipient_index = parseInt(index);
    } else {
      this.addNextRecipient(1);
    }
  },
};
</script>

<style scoped></style>
