<template>
  <v-card class="text-start px-2" width="" min rounded="xl">
    <v-row>
      <v-col cols="12" md="5" sm="12">
        <div class="ma-4">
          <v-avatar color="grey" rounded="0" size="300">
            <v-img :src="previewImage" cover></v-img>
          </v-avatar>
        </div>
      </v-col>

      <v-col cols="12" md="7" sm="12">
        <br />
        <div>
          <p class="">
            First Name : {{ profile.firstName ? profile.firstName : "John" }}
          </p>
          <hr />
        </div>
        <br />
        <div>
          <p>Last Name : {{ profile.lastName ? profile.lastName : "Smith" }}</p>
          <hr />
        </div>
        <br />
        <div>
          <p>
            Email : {{ profile.email ? profile.email : "example@gmail.com " }}
          </p>
          <hr />
        </div>
        <br />
        <p>
          Phone No: {{ profile.phone ? profile.phone : "(+855) xxx xxx xxx" }}
        </p>
        <br />
        <p>
          Social Media :
          <v-btn
            @click="copyLink(socialMedia.facebookLink)"
            icon="mdi-facebook"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
          <v-btn
            @click="copyLink(socialMedia.telegramLink)"
            icon="mdi-send-circle"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
          <v-btn
            @click="copyLink(socialMedia.twitterLink)"
            icon="mdi-twitter"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
          <v-btn
            @click="copyLink(socialMedia.whatsApp)"
            icon="mdi-whatsapp"
            class="mx-2"
            variant="text"
            size="small"
          ></v-btn>
        </p>
        <br />
        <p>
          Invitation Link :
<!--          <span id="text-referral-rewards">{{ profile.myReferal }}</span>-->
          <v-btn
            @click="copyCode(profile.myReferal == '' ? '' : '2ed231')"
            icon="mdi-content-copy"
            class="mx-2"
            variant="text"
            size="small"
          >
          </v-btn>
          <span class="text-blue" style="font-size: 11px"
            >{{
              getLocation() +
              "/sign-up?code=" +
              (profile.myReferal == "" ? "" : "2ed231")
            }}
          </span>
        </p>

        <p>
          Invitation Code :
<!--          <span id="text-referral-rewards">{{ profile.myReferal }}</span>-->
          <v-btn
            @click="copyLink(profile.myReferal == '' ? '' : '2ed231')"
            icon="mdi-content-copy"
            class="mx-2"
            variant="text"
            size="small"
          >
          </v-btn>
          <span class="text-blue" style="font-size: 11px"
            >{{ profile.myReferal == "" ? "" : "2ed231" }}
          </span>
        </p>
        <br />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { UPLOAD_PHOTO_PATH } from "@/utils/customerApiUrl";
import { mapState } from "vuex";

export default {
  name: "ProfileInfo",
  computed: {
    ...mapState(["profile", "socialMedia"]),
  },
  data: () => ({
    previewImage: null,
  }),
  methods: {
    copyCode(text) {
      navigator.clipboard.writeText(location.origin + "/sign-up?code=" + text);
    },
    copyLink(link) {
      if (link != null) {
        navigator.clipboard.writeText(link);
      }
    },
    getLocation() {
      return location.origin;
    },
  },
  created() {
    setTimeout(() => {
      this.previewImage = this.profile.profile
        ? UPLOAD_PHOTO_PATH + "" + this.profile.profile
        : "https://t4.ftcdn.net/jpg/02/83/72/41/360_F_283724163_kIWm6DfeFN0zhm8Pc0xelROcxxbAiEFI.jpg";
    }, 500);
  },
};
</script>

<style scoped></style>
