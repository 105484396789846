<template>
  <div>
    <p>Create Exchange Rate</p>
    <v-divider></v-divider>
    <br />
    <div class="d-flex">
      <p class="ma-1">Add a new exchange rate</p>

      <v-btn class="mx-5" icon="mdi-plus" size="small" color="primary"></v-btn>
    </div>

    <br />

    <template v-for="i in list" :key="i">
      <div class="d-flex">
        <div style="width: 20%">
          <v-text-field
            v-model="baseCurrency"
            outlined
            variant="outlined"
            rounded="xl"
            suffix="PST"
            density="compact"
            placeholder="USD"
          ></v-text-field>
        </div>

        <p class="ma-2">to</p>

        <div style="width: 20%">
          <v-text-field
            v-model="targetCurrency"
            clearable
            required
            variant="outlined"
            rounded="xl"
            suffix="PST"
            density="compact"
            :placeholder="i.currency"
          ></v-text-field>
        </div>
        <p class="ma-2"></p>
        <v-btn round color="primary" rounded="xl" class="text-none"> Update</v-btn>
        <p class="ma-2"></p>
        <v-btn round color="red" rounded="xl" class="text-none">Remove</v-btn>
        <v-btn class="mx-4" icon="mdi-plus" size="small" color="green"></v-btn>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "ExchangeTab",
  data() {
    return {
      baseCurrency: "",
      targetCurrency: "",
      list: [
        { id: 1, currency: "KHR", usd: 1, exchange: 1 },
        { id: 1, currency: "THB", usd: 1, exchange: 1 },
        { id: 1, currency: "CHY", usd: 1, exchange: 1 },
        { id: 1, currency: "JPY", usd: 1, exchange: 1 },
      ],
    };
  },
};
</script>
<style lang=""></style>
