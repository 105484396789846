<template>
  <v-card flat>
    <template v-slot:text>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-select
            v-if="(isAllCountry === 1) | (isAdmin === 1)"
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            clearable
            @update:modelValue="filterItems"
          >
          </v-select>
          <!-- NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
          <v-select
            v-else
            v-model="searchCountryId"
            :items="countries"
            :item-title="'name'"
            :item-value="'id'"
            label="Country"
            variant="outlined"
            prepend-inner-icon="mdi-flag-outline"
            required
            persistent-placeholder
            placeholder="Choose country..."
            return-object
            single-line
            disabled
            @update:modelValue="filterItems"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="search"
            label="Search bank type..."
            prepend-inner-icon="mdi-magnify"
            variant="outlined"
            hide-details
            single-line
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      location="top"
    >
      {{ snackText }}
    </v-snackbar>
    <v-data-table
      color="#b2d7ef"
      rounded="compact"
      divided
      striped
      hover
      class="elevation-1"
      fixed-header
      :search="search"
      :headers="headers"
      :items="bankTypes"
      :items-per-page="itemsPerPage"
      v-model:page="page"
      :sort-by="[
        { key: 'createdAt', order: 'desc' },
        { key: 'bankName', order: 'asc' },
      ]"
    >
      <template v-slot:top>
        <v-dialog
          v-model="dialog"
          persistent
          fullscreen
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <v-row>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-center mb-4"
              >
                <v-btn
                  class="mb-2 ml-4"
                  color="primary"
                  v-bind="props"
                  prepend-icon="mdi-plus"
                  variant="flat"
                >
                  <div class="text-none font-weight-regular">Add</div>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                md="6"
                lg="6"
                xl="6"
                class="text-right d-flex mb-6 flex-row-reverse"
              >
                <v-sheet class="ma-2 pa-2 text-center">
                  <v-select
                    v-model="itemsPerPage"
                    :items="[10, 25, 50, 100, 1000]"
                    hide-details
                    density="compact"
                    variant="outlined"
                    @update:model-value="itemsPerPage = parseInt($event, 10)"
                    style="width: 100px"
                  ></v-select>
                </v-sheet>
                <v-sheet class="my-6 text-subtitle-2">Items</v-sheet>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-toolbar>
              <v-toolbar-title prepend-icon="mdi-form-textbox">{{
                formTitle
              }}</v-toolbar-title>

              <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
            </v-toolbar>
            <v-divider class="mb-0"></v-divider>
            <v-divider class="mb-0"></v-divider>
            <v-form ref="form" enctype="multipart/form-data" lazy-validation>
              <v-card-text>
                <v-alert
                  border="end"
                  border-color="warning"
                  elevation="2"
                  color="error"
                  v-show="!visible"
                >
                  {{ msgError }}
                </v-alert>
                <v-row class="mt-1">
                  <v-col cols="12" md="12"> </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                        <v-card class="mx-auto">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-select
                                  v-if="(isAllCountry === 1) | (isAdmin === 1)"
                                  v-model="countryId"
                                  :items="countries"
                                  :item-title="'name'"
                                  :item-value="'id'"
                                  label="Country"
                                  variant="outlined"
                                  density="compact"
                                  prepend-inner-icon="mdi-flag-outline"
                                  required
                                  persistent-placeholder
                                  placeholder="Choose country..."
                                  return-object
                                  :disabled="disabled == 0"
                                  @update:modelValue="selectedChangeItems"
                                >
                                </v-select>
                                <!--NOTE: when we use @update.movelValue or :on-change the data return to json 
                                  but else the data return as value normally -->
                                <v-select
                                  v-else
                                  v-model="countryId"
                                  :items="countries"
                                  :item-title="'name'"
                                  :item-value="'id'"
                                  :rules="[
                                    () =>
                                      !!countryId || 'This field is required',
                                  ]"
                                  label="Country"
                                  variant="outlined"
                                  density="compact"
                                  prepend-inner-icon="mdi-flag-outline"
                                  required
                                  persistent-placeholder
                                  placeholder="Choose country..."
                                  return-object
                                  @update:modelValue="selectedChangeItems"
                                  disabled
                                >
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <input type="hidden" v-model="hiddenId" />
                                <input type="hidden" v-model="hdLogoBankType" />
                                <v-autocomplete
                                  ref="paymentMethod"
                                  v-model="paymentMethod"
                                  :items="bankTypePaymentMethod"
                                  :rules="[
                                    () =>
                                      !!paymentMethod ||
                                      'This field is required',
                                  ]"
                                  label="-- Choose payment method --"
                                  placeholder="-- Choose payment method --"
                                  variant="outlined"
                                  density="compact"
                                  prepend-inner-icon="mdi-filter-variant"
                                  required
                                  autocomplete="off"
                                  persistent-placeholder
                                  :disabled="disabled == 0"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                  v-model.trim="bankName"
                                  :rules="[
                                    () =>
                                      !!bankName || 'This field is required',
                                  ]"
                                  label="Bank name"
                                  placeholder="Bank name"
                                  variant="outlined"
                                  prepend-inner-icon="mdi-cash"
                                  required
                                  autocomplete="off"
                                  density="compact"
                                  clearable
                                  persistent-placeholder
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                  v-model.trim="countryName"
                                  :rules="[
                                    () =>
                                      !!countryName || 'This field is required',
                                  ]"
                                  label="Country name"
                                  placeholder="Country name"
                                  variant="outlined"
                                  prepend-inner-icon="mdi-cash"
                                  required
                                  autocomplete="off"
                                  density="compact"
                                  clearable
                                  persistent-placeholder
                                  :disabled="disabled == 0"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-switch
                                  v-model="bank_active"
                                  color="success"
                                  :label="isBankActive"
                                  hide-details
                                ></v-switch>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea
                                  clear-icon="mdi-close-circle"
                                  label="Remark"
                                  rows="3"
                                  v-model.trim="remark"
                                  clearable
                                  variant="outlined"
                                  density="compact"
                                  required
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-card
                          class="mx-auto"
                          subtitle="Bank Logo"
                          width="100%"
                          height="47vh"
                        >
                          <v-card-text class="text-center text-caption">
                            <v-row variant="dense">
                              <v-col cols="12">
                                <v-card
                                  variant="variant"
                                  class="mx-auto pt-4"
                                  elevation="16"
                                >
                                  <v-img
                                    :src="
                                      previewImage === null
                                        ? 'https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain'
                                        : previewImage
                                    "
                                    height="33vh"
                                    class="align-end"
                                    fixed
                                    @click="triggerFileInput"
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        align="center"
                                        class="fill-height ma-0"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          color="grey-lighten-5"
                                          indeterminate
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-card>

                                <v-file-input
                                  v-model.trim="flagImg"
                                  accept="image/png, image/jpeg, image/bmp"
                                  label="Avatar"
                                  placeholder="Pick an avatar"
                                  prepend-icon="mdi-camera"
                                  @change="uploadImage"
                                  hide-input
                                  class="mt-5"
                                  ref="fileInput"
                                  style="display: none"
                                ></v-file-input>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider class="mt-1"></v-divider>
            </v-form>

            <v-card-actions class="my-2 d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                class="text-none"
                rounded="xl"
                text="Cancel"
                @click="close"
                variant="flat"
                color="error"
              ></v-btn>

              <v-btn
                class="text-none"
                color="primary"
                rounded="xl"
                :text="buttonTitle"
                variant="flat"
                @click="save"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <input type="hidden" v-model="hiddenId" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- detect when error  -->
      <template v-slot:no-data>
        <v-alert
          v-if="items.length > 0"
          :value="true"
          color="error"
          icon="warning"
          class="text-left"
        >
          No data found.
        </v-alert>
      </template>
      <!-- end detect when error  -->
      <!-- Custom column table -->
      <template v-slot:[`item.image`]="{ item }">
        <div v-if="item.image" class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              :src="pathCountryFlag + item.image"
              cover
            ></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
        {{ item.name }}
      </template>
      <template v-slot:[`item.logoBank`]="{ item }">
        <div v-if="item.logoBank" class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              :src="pathBankTypeLogo + item.logoBank"
              cover
            ></v-img>
          </v-avatar>
        </div>
        <div v-else class="text-center">
          <v-avatar size="35" class="my-1">
            <v-img
              alt="Avatar"
              src="https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
              cover
            >
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular
                    color="grey-lighten-5"
                    indeterminate
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
        </div>
        {{ item.currency }}
      </template>
      <template v-slot:[`item.bank_active`]="{ item }">
        <div v-if="item.bank_active" class="text-center">
          <v-icon color="success" icon="mdi-checkbox-marked-outline"></v-icon>
        </div>
        <div v-else class="text-center">
          <v-icon color="error" icon="mdi-close-circle-outline"></v-icon>
        </div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{
          item.createdAt !== null
            ? new Date(item.createdAt).toLocaleString()
            : "N/A"
        }}
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{
          item.updatedAt !== null
            ? new Date(item.updatedAt).toLocaleString()
            : "N/A"
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="ma-2"
          size="x-small"
          color="red-lighten-2"
          @click="editItem(item)"
        >
          <v-icon icon="mdi-pencil" start></v-icon>
          Edit
        </v-btn>
        <!--
          <v-icon title="Edit" class="me-2" size="small" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon title="Delete" size="small" @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:bottom>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            rounded="circle"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </template>
      <!-- End custom column table -->
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import {
  Path_Bank_Type_Logo,
  URL_BANK_TYPE,
  URL_COUNTRY,
  URL_UPLOAD_LOGO_BANK_TYPE,
} from "@/utils/apiUrl";
import { TOKEN_HEADERS } from "@/utils/headerToken";
import { originalCode } from "@/utils/formatNumber";

export default {
  data: () => ({
    pathBankTypeLogo: Path_Bank_Type_Logo,
    snack: false,
    dialog: false,
    dialogDelete: false,
    visible: true,
    bank_active: true,

    items: [],
    bankTypes: [],
    countries: [{ id: 0, name: "-- All --" }],
    //bankTypePaymentMethod: ["Crypto currency", "Bank transfer"],
    bankTypePaymentMethod: [],
    disabled: null,
    search: null,
    searchCountryId: null,
    snackColor: null,
    snackText: null,
    msgError: null,
    countryId: null,
    previewImage: null,
    countryName: null,
    bankName: null,
    remark: null,
    paymentMethod: null,

    editedIndex: -1,
    hiddenId: 0,
    hdLogoBankType: null,

    page: 1,
    itemsPerPage: 10,

    isAdmin: originalCode(parseInt(localStorage.getItem("admin"))),
    isAllCountry: originalCode(parseInt(localStorage.getItem("allCountry"))),
    staffLoginId: originalCode(parseInt(localStorage.getItem("id"))),
    staffLoginCountryId: originalCode(
      parseInt(localStorage.getItem("countryId"))
    ),

    headers: [
      { title: "", key: "actions", sortable: false, align: "center" },
      { title: "Bank logo", key: "logoBank", align: "center" },
      { title: "Active?", key: "bank_active", align: "center" },
      { title: "Country", key: "country.name", align: "center" },
      { title: "Country name", key: "countryName", align: "center" },
      { title: "Bank name", key: "bankName", align: "center" },
      { title: "Payment method", key: "paymentMethod", align: "center" },
      { title: "Remark", key: "remark", align: "center" },
      { title: "Created at", key: "createdAt", align: "center" },
      { title: "Updated at", key: "updatedAt", align: "center" },
      { title: "Updated by", key: "Staff.fullName", align: "center" },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Bank Type" : "Update Bank Type";
    },
    buttonTitle() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
    isBankActive() {
      return this.bank_active.toString() === "true" ? "Active" : "Disactive";
    },
    pageCount() {
      return Math.ceil(this.bankTypes.length / this.itemsPerPage);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.PopulateCountries();
    this.filterItems();
  },
  methods: {
    clearContent() {
      this.hiddenId = 0;
      this.countryName = null;
      this.previewImage =
        "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain";
      this.bankName = null;
      this.remark = null;
      this.visible = true;
      this.bank_active = true;
      this.paymentMethod = null;
      this.bankTypePaymentMethod = [];
      this.countryId = this.staffLoginCountryId;

      this.disabled = 1;
    },

    isAdminOrAllCountryPermission() {
      return (this.isAdmin || this.isAllCountry) === 1 ? true : false;
    },

    async filterItems() {
      var searchCountryId = null;
      if (this.searchCountryId) {
        searchCountryId =
          this.searchCountryId.id === undefined
            ? null
            : this.searchCountryId.id;
      } else {
        if (this.searchCountryId === null) {
          searchCountryId = this.isAdminOrAllCountryPermission()
            ? null
            : this.staffLoginCountryId;
        } else {
          searchCountryId = this.staffLoginCountryId;
        }
      }

      this.PopulatebankTypesByCountry(searchCountryId);
    },

    async PopulatebankTypesByCountry(countryId) {
      axios.get(URL_BANK_TYPE, { headers: TOKEN_HEADERS }).then((res) => {
        //const searchCountryId = this.searchCountryId.id;
        this.bankTypes = res.data.filter((item) => {
          const itemCountry = item.countryId;
          const matchesCountry = !countryId || itemCountry === countryId;

          return matchesCountry;
        });

        this.items.push(this.bankTypes);
      });
    },

    PopulateCountries() {
      axios.get(URL_COUNTRY, { headers: TOKEN_HEADERS }).then((response) => {
        response.data.forEach((country) => {
          this.countries.push({
            name: country.name,
            id: country.id,
          });
        });

        this.countryId = this.staffLoginCountryId;
        this.searchCountryId = this.isAdminOrAllCountryPermission()
          ? 0
          : this.staffLoginCountryId;

        this.selectedChangeItems(this.staffLoginCountryId);
      });
    },

    editItem(item) {
      this.editedIndex = this.bankTypes.indexOf(item);
      this.hiddenId = item.id;
      //this.editedItem = Object.assign({}, item);
      this.disabled = 0;
      this.countryName = item.countryName;
      this.bankName = item.bankName;
      this.remark = item.remark;
      this.paymentMethod = item.paymentMethod;
      this.countryId = item.countryId;
      this.bank_active = item.bank_active.toString() === "1" ? true : false;
      this.previewImage =
        item.logoBank === null
          ? "https://th.bing.com/th/id/OIP.tcFOyXBrh18mT9xQUPgB8gHaHv?rs=1&pid=ImgDetMain"
          : this.pathBankTypeLogo + item.logoBank;

      this.hdLogoBankType = item.image;

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.bankTypes.indexOf(item);
      this.hiddenId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //delete from arraylist
      this.bankTypes.splice(this.editedIndex, 1);
      //delete from DB
      const id = this.hiddenId;
      const url = URL_BANK_TYPE + id;
      axios
        .delete(url, { headers: TOKEN_HEADERS })
        .then(function (response) {
          this.snakeMessage("error", response.data.message);
          this.closeDelete();
        })
        .catch((error) => console.error("record deleted was an error!", error));

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.clearContent();
        this.editedIndex = -1;
      });
    },

    getCountryIdWhenSelected() {
      if (this.countryId) {
        this.countryId = this.countryId.id;
      }
    },

    async save() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        //const staffLoginId = localStorage.getItem("id");
        const data = {
          bankName: this.bankName,
          logoBank: this.hdLogoBankType,
          remark: this.remark,
          user: this.staffLoginId.toString(),
          bank_active: this.bank_active === true ? 1 : 0,
        };

        if (this.editedIndex > -1) {
          // edit
          data.updatedAt = new Date();
          data.updated_by = this.staffLoginId;
          const id = this.hiddenId;
          const url = URL_BANK_TYPE + id;

          await axios
            .patch(url, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
                return false;
              }
              //console.error("record updated was an error!", error)
            );

          //this.close();
        } // create new
        else {
          data.countryName = this.countryName;
          data.paymentMethod = this.paymentMethod;
          data.countryId = parseInt(this.countryId);

          await axios
            .post(URL_BANK_TYPE, data, { headers: TOKEN_HEADERS })
            .then((response) => {
              this.snakeMessage("success", response.data.message);
              this.clearContent();
              this.dialog = false;
            })
            .catch(
              (error) => {
                this.visible = false;

                if (error.response.data.statusCode === 400) {
                  //this.snakeMessage("error", error.response.data.message);
                  this.msgError = error.response.data.message;
                } else if (error.request) {
                  // The request was made but no response was received
                  //this.snakeMessage("error", error.request);
                  this.msgError = error.request;
                  // Something happened in setting up the request that triggered an Error
                  //this.snakeMessage("error", error.message);
                  this.msgError = error.message;
                }

                this.dialog = true;
              }
              //console.error("record updated was an error!", error)
            );
        }

        //this.PopulatebankTypes();
        var countryId = this.isAdminOrAllCountryPermission()
          ? null
          : this.staffLoginCountryId;
        this.PopulatebankTypesByCountry(countryId);
      }
    },

    snakeMessage(color, message) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = message;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };

      let data = new FormData();
      data.append("file", e.target.files[0]);

      axios.post(URL_UPLOAD_LOGO_BANK_TYPE, data).then((response) => {
        this.hdLogoBankType = response.data.filename;
        console.log("Image has been uploaded.");
      });
    },

    selectedChangeItems(component) {
      // it get the data as json
      this.countryName = component.name;

      if (this.countryName === undefined) {
        this.countryId = this.staffLoginCountryId;
        this.bankTypePaymentMethod = ["Online bank transfer", "Bank transfer"];
      } else {
        this.countryId = this.countryId.id;
        if (this.countryName === "Crypto Currency") {
          this.paymentMethod = null;
          this.bankTypePaymentMethod = [];
          this.bankTypePaymentMethod = ["Crypto currency"];
        } else {
          this.paymentMethod = null;
          this.bankTypePaymentMethod = [];
          this.bankTypePaymentMethod = [
            "Online bank transfer",
            "Bank transfer",
          ];
        }
      }
    },
  },
};
</script>
