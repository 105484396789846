<template>
    <MainLayout>
        <v-row>
            <v-col cols="12" md="3">
                <div class="text-center bg-blue pt-6 font-weight-medium" style="height: 80px">
                    Top 10 Investors
                </div>
            </v-col>

            <v-col cols="12" md="3">
                <div class="text-center bg-purple-lighten-5 pt-6 text-indigo" style="height: 80px">
                   Top 10 Projects
                </div>
            </v-col>

            <v-col cols="12" md="3">
                <div class="text-center bg-indigo-darken-4 pt-6" style="height: 80px">
                    Total Amount Volume
                </div>
            </v-col>

            <v-col cols="12" md="3">
                <div class="text-center bg-blue-lighten-4 pt-6 text-indigo" style="height: 80px">
                    Total Users
                </div>
            </v-col>

        </v-row>
    </MainLayout>
</template>

<script>
    import MainLayout from "../../components/MainLayout";

    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Dashboard",
        components: {MainLayout}
    }
</script>

<style scoped>

</style>
