
<template>
  <FrontendLayout>
    <div class="bg-grey-lighten-4">

      <div class="margin-auto">
        <br />
        <div>
          <h2 class="text-center text-uppercase text-primary">Closing Soon </h2>
          <br />
          <h3 class="font-weight-regular text-justify">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit.
          </h3>
        </div>
        <br />
      </div>

      <VCard class="bg-primary" min-height="140">
        <div class="margin-auto">
          <VRow class="pa-10">
            <VCol cols="12" md="4">
              <v-autocomplete
                rounded="xl"
                placeholder="Project Status"
                density="comfortable"
                variant="solo"
                v-model="form.projectStatus"
                :items="projectStatus"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="2"></VCol>
            <VCol cols="12" md="3">
              <v-autocomplete
                rounded="xl"
                placeholder="Sector"
                density="comfortable"
                variant="solo"
                v-model="form.sector"
                :items="sectors"
                menu-icon="mdi-chevron-right"
              >
              </v-autocomplete>
            </VCol>
            <VCol cols="12" md="3">
              <VBtn
                rounded="xl"
                size="large"
                block
                variant="outlined"
                @click="onSearch()"
              >
                Search
              </VBtn>
            </VCol>
          </VRow>
        </div>
      </VCard>

      <CardNews :exploreMores="exploreMores" />

      <div class="d-flex justify-center">
        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          First
        </VBtn>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          Previous
        </VBtn>

        <v-pagination
          v-model="form.page"
          :length="6"
          rounded="circle"
          prev-icon=""
          next-icon=""
        ></v-pagination>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          Next
        </VBtn>

        <VBtn width="100" size="" rounded="xl" class="ma-2 text-none" variant="tonal" >
          Last
        </VBtn>
      </div>

      <br/>
      <br/>
    </div>
  </FrontendLayout>
</template>

<script>
import FrontendLayout from '../components/FrontendLayout';
import CardNews from '@/views/website/projects/components/CardNews.vue';

export default {
  name: 'ClosingSoonPage',
  components: {
    CardNews,
    FrontendLayout,
  },
  data() {
    return {
      projectStatus: [
        'Active',
        'Sold out',
        'Proposed',
        'Crowdfunding',
      ],
      sectors: [
        'Agribusiness',
        'Banking',
        'Capital Markets',
        'Chemicals',
        'Construction',
        'Education',
      ],
      form: {
        projectStatus: null,
        sector: null,
        page: 1,
      },
      exploreMores: [
        {
          id: 1,
          image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
        },
        {
          id: 2,
          image: 'https://inkstickmedia.com/wp-content/uploads/2021/11/jake-grella-Q0sAi6qdgUI-unsplash-scaled-e1636518588857.jpg',
        },
        {
          id: 3,
          image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
        },
        {
          id: 4,
          image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
        },
        {
          id: 5,
          image: 'https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk=',
        },
        {
          id: 6,
          image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
        },
        {
          id: 7,
          image: 'https://ansanmedical.com/wp-content/uploads/2018/11/mot.jpg',
        },
        {
          id: 8,
          image: 'https://media.istockphoto.com/id/122168561/photo/office-bulding.jpg?s=612x612&w=0&k=20&c=5UWZPgAKIG7fsUwKcNE5F-ME5DH4ITD4bVd6-ejgZWk=',
        },
        {
          id: 9,
          image: 'https://benoit-partners.com/wp-content/uploads/2024/03/BOI-Company-Registration-in-Thailand.jpg',
        },
      ],
    };
  },
  methods:{
    onSearch(){
      alert("onSearch");
    }
  }
};
</script>

<style scoped></style>
