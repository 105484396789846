<script>

export default {
  name: 'CardShareLink',
  data() {
    return {
      links: [
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/watch?v=q8Wmz1tyPaY',
        },
        {
          icon: 'mdi-send-circle',
          link: 'https://www.telegram.com/watch?v=q8Wmz1tyPaY',
        },
        {
          icon: 'mdi-twitter',
          link: 'https://www.twitter.com/watch?v=q8Wmz1tyPaY',
        },
        {
          icon: 'mdi-wechat',
          link: 'https://www.wechat.com/watch?v=q8Wmz1tyPaY',
        },
      ],
    };
  },
  methods: {
    copyLink(link) {
      if (link != null) {
        navigator.clipboard.writeText(link);
      }
    },
    getLocation() {
      return location.origin;
    },
  },
};
</script>

<template>
  <VCard class="my-5" min-height="180" variant="outlined" rounded="xl">
    <div class="text-center">
      <br />
      <h3 class="font-weight-regular">
        Share Links Via
        <template v-for="row in links" v-bind:key="row.icon">
          <v-btn @click="copyLink(row.link)" :icon="row.icon" size="small" variant="text" class="mx-1">
            <VIcon size="30" color="primary">{{ row.icon }}</VIcon>
          </v-btn>
        </template>
      </h3>
    </div>

    <br />

    <VCard elevation="0" class="mx-5 pa-1 d-flex justify-lg-space-between" rounded="xl">
      <VBtn variant="text" class="text-none" rounded="xl" elevation="0">
        <VIcon color="grey" class="mx-2">mdi-link-variant</VIcon>
      </VBtn>
      <span class="mt-1" style="text-overflow: ellipsis">
        <router-link to="/" class="text-decoration-none text-grey">
          {{ getLocation() }}
        </router-link>
      </span>
      <VBtn @click="copyLink(getLocation())" class="text-none" rounded="xl" width="120" color="primary">
        Copy
      </VBtn>
    </VCard>

  </VCard>
</template>

<style scoped>

</style>