<template>
  <FrontendLayout>
    <img
      src="../../../assets/contact-us.png"
      width="100%"
      alt=""
      height="30%"
    />

    <div class="bg-grey-lighten-4">
      <h3 class="text-uppercase text-center text-blue-darken-3 pa-5">
        contact us
      </h3>

      <h3 class="text-start text-grey-darken-1 pa-5" v-html="data.desc"></h3>
    </div>

    <div class="bg-blue-darken-3 py-10">
      <div class="margin-auto">
        <v-row class="py-10">
          <v-col md="6" sm="12" class="d-flex justify-center">
            <v-card height="480px" width="350px" class="pa-10" rounded="xl">
              <label> NAME </label>
              <v-text-field
                rounded="xl"
                label=""
                placeholder="Enter Name"
                variant="outlined"
                density="compact"
              ></v-text-field>

              <label> EMAIL </label>
              <v-text-field
                rounded="xl"
                label=""
                placeholder="Enter Email"
                variant="outlined"
                density="compact"
              ></v-text-field>

              <label> COMMENT </label>
              <v-textarea
                label="Write Something ..."
                row-height="25"
                rows="5"
                variant="outlined"
                auto-grow
                shaped
                rounded="xl"
                density="compact"
              ></v-textarea>

              <v-btn
                class="text-capitalize"
                color="blue-darken-3"
                rounded="xl"
                size="large"
                block
              >
                Calculate
              </v-btn>
            </v-card>
          </v-col>

          <v-col md="6" sm="12" class="">
            <div class="my-3">
              <h3>Our Office</h3>
              <p class="my-2" v-html="data.head_office"></p>
              <p class="my-2" v-html="data.address"></p>
            </div>

            <div class="my-6">
              <h3 class="my-3">Email</h3>
              <p class="my-2" v-html="data.email"></p>
            </div>

            <div class="my-6">
              <h3 class="my-2">Phone No</h3>
              <p class="my-2" v-html="data.phone"></p>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </FrontendLayout>
</template>

<script>
import { URL_CONTACT_US } from "@/utils/apiUrl";
import FrontendLayout from "../components/FrontendLayout";
import axios from "axios";
export default {
  name: "ContactUs",
  components: { FrontendLayout },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(URL_CONTACT_US)
        .then((response) => {
          this.data = response.data[0];
        })
        .catch((error) => {
          console.error("Failed to", error);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
